import { useEffect, useMemo } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import {
  add,
  differenceInHours,
  differenceInMinutes,
  startOfHour,
} from "date-fns";
import { RescheduledTime } from "./ResultCard";

export default function ResultTimeSelector({
  freeTimes,
  startTime,
  returnTime,
  requestedStartTime,
  onChange,
  suggestedStartTime,
  suggestedReturnTime,
  useVehicleTimes,
}) {
  const startTimes = useMemo(() => {
    const times = [];
    if (useVehicleTimes) {
      const timesInMs = freeTimes.map((time) => time.firstStartTime.getTime());
      const timesInMsNoDups = [...new Set(timesInMs)];
      const dateTimes = timesInMsNoDups.map((ms) => new Date(ms));
      for (const date of dateTimes) {
        times.push(date);
      }
    } else {
      freeTimes.forEach((freeTime) => {
        const earliest =
          freeTime.firstStartTime.getMinutes() === 0
            ? freeTime.firstStartTime
            : startOfHour(add(freeTime.firstStartTime, { hours: 1 }));

        const length = differenceInHours(freeTime.lastStartTime, earliest);

        for (let i = 0; i < length + 1; i++) {
          times.push(add(earliest, { hours: i }));
        }
      });
    }

    if (
      !times.find((time) => time.getTime() === suggestedStartTime.getTime())
    ) {
      times.unshift(suggestedStartTime);
    }

    return times.sort();
  }, [freeTimes, suggestedStartTime, useVehicleTimes]);

  const endTimes = useMemo(() => {
    const times = [];

    if (useVehicleTimes) {
      for (const time of freeTimes) {
        if (startTime.getTime() === time.firstStartTime.getTime()) {
          times.push(time.lastReturnTime);
        }
      }
    } else {
      const freeTime = freeTimes.find(
        (freeTime) =>
          freeTime.firstStartTime <= startTime &&
          freeTime.lastReturnTime >= startTime
      );

      if (!freeTime) return times;

      const suggestedLength = differenceInHours(
        suggestedReturnTime,
        suggestedStartTime
      );
      const length = differenceInHours(freeTime.lastReturnTime, startTime);
      const from = Math.max(0, suggestedLength - 5);
      const to = Math.min(length, suggestedLength + 6);

      for (let i = from; i < to; i++) {
        times.push(add(startTime, { hours: i + 1 }));
        // add a :30 to all but last
        if (i !== length - 1) {
          times.push(add(startTime, { hours: i + 1, minutes: 30 }));
        }
      }
    }

    return times;
  }, [freeTimes, startTime, suggestedStartTime, suggestedReturnTime]);

  useEffect(() => {
    if (
      !endTimes.find((endTime) => endTime.getTime() === returnTime.getTime())
    ) {
      onChange(null, endTimes[endTimes.length - 1]);
    }
  }, [endTimes, returnTime, onChange]);

  const minuteDiff = useMemo(
    () => differenceInMinutes(returnTime, startTime),
    [returnTime, startTime]
  );

  // const diffString = useMemo(() => {
  //   const h = Math.floor(minuteDiff / 60);
  //   const m = Math.floor(minuteDiff - h * 60);

  //   if (m === 0) {
  //     return h + " h";
  //   }
  //   return `${h}:${m}h`;
  // }, [minuteDiff]);

  return (
    <Grid
      container
      justify="space-around"
      spacing={1}
      alignItems="center"
      style={{
        background: "#fdfdfd",
        margin: "8px -16px 0",
        width: "calc(100% + 32px)",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderLeft: 0,
        borderRight: 0,
        padding: 8,
      }}
    >
      <Grid item>
        <FormControl variant="outlined">
          <InputLabel>Nouto</InputLabel>
          <Select
            label="Nouto"
            placeholder=""
            value={startTime.getTime()}
            onChange={(e) => {
              if (useVehicleTimes) {
                const date = new Date(e.target.value);
                onChange(date, null);
              } else {
                const date = new Date(e.target.value);
                onChange(date, add(date, { minutes: minuteDiff }));
              }
            }}
            displayEmpty
            variant="outlined"
            margin="dense"
          >
            {startTimes.map((startTime, idx) => (
              <MenuItem value={startTime.getTime()} key={idx}>
                <RescheduledTime
                  date={startTime}
                  requestedDate={requestedStartTime}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl variant="outlined">
          <InputLabel>Palautus</InputLabel>
          <Select
            label="Palautus"
            placeholder=""
            value={returnTime.getTime()}
            onChange={(e) => {
              const date = new Date(e.target.value);
              onChange(null, date);
            }}
            displayEmpty
            variant="outlined"
            margin="dense"
          >
            {endTimes.map((endTime, idx) => (
              <MenuItem value={endTime.getTime()} key={idx}>
                <RescheduledTime
                  date={endTime}
                  requestedDate={requestedStartTime}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
