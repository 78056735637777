import { useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  areIntervalsOverlapping,
  eachDayOfInterval,
  format,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import { fi } from "date-fns/locale";

const Day = ({ date, status }) => {
  // TODO: Possible indicators for days that reservation can be started from,
  // different styling for start and end days after those statuses have been added
  let backgroundColor = "#b6b6b6";
  if (status === "FREE") {
    backgroundColor = "#528d36";
  }
  return (
    <div
      style={{
        padding: 4,
        marginRight: 5,
        margin: 2,
        backgroundColor: backgroundColor,
        color: "#ffffff",
        width: 17,
        height: 17,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3,
        fontFamily: "sans-serif",
      }}
    >
      {date.getDate()}
    </div>
  );
};

export default function MonthlyReservations({
  reservation,
  requestedStartTime,
}) {
  const capitalize = (str) => {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  };
  const currentMonthString = capitalize(
    format(requestedStartTime, "LLLL", { locale: fi })
  );

  const daysOfMonth = useMemo(() => {
    return eachDayOfInterval({
      start: startOfMonth(requestedStartTime),
      end: endOfMonth(requestedStartTime),
    });
  }, [requestedStartTime]);

  // TODO: Add statuses for beginning and ending.
  const reservationStatuses = useMemo(() => {
    return daysOfMonth.map((d) => {
      const dayInterval = { start: startOfDay(d), end: endOfDay(d) };
      for (const reserved of reservation.reservedTimes) {
        const reservedInterval = {
          start: new Date(reserved.start),
          end: new Date(reserved.end),
        };
        if (areIntervalsOverlapping(dayInterval, reservedInterval)) {
          return "RESERVED";
        }
      }
      return "FREE";
    });
  }, [daysOfMonth, reservation.reservedTimes]);
  // Check if the current day is in reservations
  const getStatus = (day) => {
    return reservationStatuses[day];
  };
  const checkStartingDay = (day) => {
    return reservationStatuses[day].isStartDay;
  };
  const checkReturnDay = (day) => {
    return reservationStatuses[day].isReturnDay;
  };

  return (
    <Grid container item justify="center">
      <Grid item>
        <Typography variant="h5">{currentMonthString}</Typography>
      </Grid>
      <Grid container item direction="row">
        {daysOfMonth.map((date, index) => {
          return (
            <Day
              key={index}
              date={date}
              status={getStatus(date.getDate() - 1)}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
