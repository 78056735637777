import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    margin: "1em 0px",
  },
}));

export default function AlreadyReservedDialog() {
  const classes = useStyles();
  const history = useHistory();

  function P({ children }) {
    return (
      <Typography className={classes.paragraph} variant="body1" component="p">
        {children}
      </Typography>
    );
  }

  return (
    <Dialog open={true}>
      <DialogTitle>
        <ErrorOutlineIcon
          fontSize="large"
          style={{ verticalAlign: "middle" }}
        />
        {" Varaus epäonnistui"}
      </DialogTitle>
      <DialogContent>
        <P>Voi rähmä, joku muu kerkesi ensin.</P>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => history.goBack()}
          color="primary"
          size="large"
          variant="contained"
          fullWidth
        >
          Palaa takaisin
        </Button>
      </DialogActions>
    </Dialog>
  );
}
