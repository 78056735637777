import { format } from "date-fns";
import { fi } from "date-fns/locale";

export function FormattedDate({ date }) {
  //Ke 12.12.2020
  const str = format(date, "eeeeee dd.MM.yyyy", {
    locale: fi,
  });
  return str[0].toUpperCase() + str.slice(1);
}

export function FormattedTime({ date }) {
  //Klo. 16.30
  return (
    <>
      Klo.{" "}
      {format(date, "HH.mm", {
        locale: fi,
      })}
    </>
  );
}
