/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReservations = /* GraphQL */ `
  query GetReservations(
    $startTime: AWSDateTime!
    $returnTime: AWSDateTime
    $endTime: AWSDateTime
    $group: String!
    $queryMode: String!
  ) {
    getReservations(
      startTime: $startTime
      returnTime: $returnTime
      endTime: $endTime
      group: $group
      queryMode: $queryMode
    ) {
      id
      group
      companyId
      reservationVehicleId
      reservationVehicles {
        id
        price
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      ssn
      internalNotes
      externalNotes
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        deductibleValue
        groupFields
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      isCompany
      companyName
      companyBusinessId
      companyBillingRef
      companyBillingAddress
      paymentToken
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
      }
      signStatus
      signDocumentId
      signerName
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
      }
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      paymentEvents {
        items {
          id
          reservationId
          group
          msg
          returnCode
          incidentId
          parameters
          apiPath
          paymentStatus
          orderNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getReservationOffers = /* GraphQL */ `
  query GetReservationOffers(
    $companyIds: [ID!]!
    $startTime: AWSDateTime!
    $returnTime: AWSDateTime!
    $timeFlexibility: Int!
  ) {
    getReservationOffers(
      companyIds: $companyIds
      startTime: $startTime
      returnTime: $returnTime
      timeFlexibility: $timeFlexibility
    ) {
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      startTime
      returnTime
      vehicleOfferPrice
      additionalServiceOffers {
        deductibleValue
        key
        offerPrice
      }
      freeTimes {
        firstStartTime
        lastStartTime
        lastReturnTime
      }
      reservedTimes {
        start
        end
      }
      availableInCategory
      useVehicleTimes
    }
  }
`;
export const getReservationsMonthly = /* GraphQL */ `
  query GetReservationsMonthly($companyId: ID!, $dateInMonth: AWSDateTime!) {
    getReservationsMonthly(companyId: $companyId, dateInMonth: $dateInMonth) {
      reservedTimes {
        start
        end
      }
      useVehicleTimes
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const getPriceByVehicleIds = /* GraphQL */ `
  query GetPriceByVehicleIds(
    $vehicleIds: [ID!]!
    $startTime: AWSDateTime!
    $returnTime: AWSDateTime!
    $companyId: ID!
  ) {
    getPriceByVehicleIds(
      vehicleIds: $vehicleIds
      startTime: $startTime
      returnTime: $returnTime
      companyId: $companyId
    ) {
      vehicleOfferPrices {
        id
        price
      }
      additionalServiceOffers {
        deductibleValue
        key
        offerPrice
      }
    }
  }
`;
export const getPriceByVehicleId = /* GraphQL */ `
  query GetPriceByVehicleId(
    $vehicleId: ID!
    $startTime: AWSDateTime!
    $returnTime: AWSDateTime!
  ) {
    getPriceByVehicleId(
      vehicleId: $vehicleId
      startTime: $startTime
      returnTime: $returnTime
    ) {
      vehicleOfferPrice
      additionalServiceOffers {
        deductibleValue
        key
        offerPrice
      }
    }
  }
`;
export const validateVoucher = /* GraphQL */ `
  query ValidateVoucher($code: String!, $vehicleId: ID!) {
    validateVoucher(code: $code, vehicleId: $vehicleId) {
      id
      name
      group
      code
      amount
      _removed
      createdAt
      updatedAt
    }
  }
`;
export const getPaymentMethods = /* GraphQL */ `
  query GetPaymentMethods {
    getPaymentMethods {
      name
      selected_value
      group
      min_amount
      max_amount
      img
      img_timestamp
      currency
    }
  }
`;
export const authPayment = /* GraphQL */ `
  query AuthPayment(
    $reservationId: ID!
    $paymentMethod: PaymentMethodInput
    $origin: String
    $originPath: String
  ) {
    authPayment(
      reservationId: $reservationId
      paymentMethod: $paymentMethod
      origin: $origin
      originPath: $originPath
    ) {
      result
      token
      type
    }
  }
`;
export const getReservationInfo = /* GraphQL */ `
  query GetReservationInfo($reservationId: ID!) {
    getReservationInfo(reservationId: $reservationId) {
      mustSign
      ssn
      group
      companyTermsUrl
      companyId
      externalNotes
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      driverSSN
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      isCompany
      companyName
      companyBusinessId
      companyBillingRef
      companyBillingAddress
      authStatus
      startTime
      returnTime
      paymentPending
      paymentStatus
      signStatus
      enableSign
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
      }
      askSsn
      name
      address
      postalCode
      city
      phone
      email
      vehicles {
        id
        group
        companyId
        name
        registrationPlate
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        orderNumber
        price
      }
      services {
        key
        quantity
        description
        price
      }
    }
  }
`;
export const companyByGroup = /* GraphQL */ `
  query CompanyByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        weeklyBusinessHours {
          openAt
          closeAt
        }
        signConfirmationEmail
        nameInEmail
        confirmationMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationDeposit {
          amount
          reminderBefore
          paymentBefore
        }
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        limitReservationOncePerDay
        hidden
        receiptInfo {
          enabled
          name
          address
          city
          postalCode
        }
        createdAt
        updatedAt
        merchantDetails {
          merchant_id
          cp
        }
        businessHoursExceptions {
          nextToken
        }
        vehicles {
          nextToken
        }
        categories {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      group
      name
      displayName
      email
      address
      postalCode
      city
      orderNumber
      orderNumberDealer
      phone
      businessId
      termsUrl
      timezone
      weeklyBusinessHours {
        openAt
        closeAt
      }
      signConfirmationEmail
      nameInEmail
      confirmationMsg
      statusLinkMsg
      livionDeviceId
      livionDeviceType
      enableReservationDeposit
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
      }
      disableOffHourReturn
      disableMultiday
      disableSameDay
      disableStartDaysFromNow
      enableCart
      enableMonthlyView
      enableVehicleStartReturnTimes
      enableMustSign
      enablePaymentOnlineOnly
      enableGTM
      limitReservationOncePerDay
      hidden
      receiptInfo {
        enabled
        name
        address
        city
        postalCode
      }
      createdAt
      updatedAt
      merchantDetails {
        merchant_id
        cp
      }
      businessHoursExceptions {
        items {
          group
          date
          name
          companyId
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        weeklyBusinessHours {
          openAt
          closeAt
        }
        signConfirmationEmail
        nameInEmail
        confirmationMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationDeposit {
          amount
          reminderBefore
          paymentBefore
        }
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        limitReservationOncePerDay
        hidden
        receiptInfo {
          enabled
          name
          address
          city
          postalCode
        }
        createdAt
        updatedAt
        merchantDetails {
          merchant_id
          cp
        }
        businessHoursExceptions {
          nextToken
        }
        vehicles {
          nextToken
        }
        categories {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBusinessHoursException = /* GraphQL */ `
  query GetBusinessHoursException($companyId: ID!, $date: AWSDate!) {
    getBusinessHoursException(companyId: $companyId, date: $date) {
      group
      date
      name
      businessHours {
        openAt
        closeAt
      }
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessHoursExceptions = /* GraphQL */ `
  query ListBusinessHoursExceptions(
    $companyId: ID
    $date: ModelStringKeyConditionInput
    $filter: ModelBusinessHoursExceptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBusinessHoursExceptions(
      companyId: $companyId
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group
        date
        name
        businessHours {
          openAt
          closeAt
        }
        companyId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      group
      companyId
      name
      registrationPlate
      damages
      additionalFields {
        key
        value
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      startReturnInterval {
        start
        end
      }
      startReturnTimes {
        start
        return
      }
      hideExport
      extraInfo
      livionKeyId
      createdAt
      updatedAt
      category {
        id
        group
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        typeId
        _removed
        createdAt
        updatedAt
        additionalServices {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      additionalServices {
        items {
          id
          group
          key
          vehicleId
          categoryId
          pricingId
          deductibleValue
          createdAt
          updatedAt
        }
        nextToken
      }
      reservations {
        items {
          id
          group
          companyId
          reservationVehicleId
          createdAt
          updatedAt
          startTime
          returnTime
          endTime
          name
          address
          postalCode
          city
          phone
          email
          ssn
          internalNotes
          externalNotes
          vehiclePrice
          driverSSN
          differentDriver
          driverName
          driverAddress
          driverPostalCode
          driverCity
          driverPhone
          driverEmail
          additionalDriver
          additionalDriverName
          additionalDriverPhone
          additionalDriverSSN
          isCompany
          companyName
          companyBusinessId
          companyBillingRef
          companyBillingAddress
          paymentToken
          paymentStatus
          signStatus
          signDocumentId
          signerName
          authStatus
          authTime
          completeBefore
          mustSign
        }
        nextToken
      }
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleByCompany = /* GraphQL */ `
  query VehicleByCompany(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleByCompany(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleByGroup = /* GraphQL */ `
  query VehicleByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const vehicleByCategory = /* GraphQL */ `
  query VehicleByCategory(
    $vehicleCategoryId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleByCategory(
      vehicleCategoryId: $vehicleCategoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      group
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      typeId
      _removed
      createdAt
      updatedAt
      additionalServices {
        items {
          id
          group
          key
          vehicleId
          categoryId
          pricingId
          deductibleValue
          createdAt
          updatedAt
        }
        nextToken
      }
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        typeId
        _removed
        createdAt
        updatedAt
        additionalServices {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAdditionalServicePricing = /* GraphQL */ `
  query GetAdditionalServicePricing($id: ID!) {
    getAdditionalServicePricing(id: $id) {
      id
      group
      key
      vehicleId
      categoryId
      pricingId
      deductibleValue
      createdAt
      updatedAt
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAdditionalServicePricings = /* GraphQL */ `
  query ListAdditionalServicePricings(
    $filter: ModelAdditionalServicePricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalServicePricings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        key
        vehicleId
        categoryId
        pricingId
        deductibleValue
        createdAt
        updatedAt
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      group
      companyId
      reservationVehicleId
      reservationVehicles {
        id
        price
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      ssn
      internalNotes
      externalNotes
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        deductibleValue
        groupFields
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      isCompany
      companyName
      companyBusinessId
      companyBillingRef
      companyBillingAddress
      paymentToken
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
      }
      signStatus
      signDocumentId
      signerName
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
      }
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      paymentEvents {
        items {
          id
          reservationId
          group
          msg
          returnCode
          incidentId
          parameters
          apiPath
          paymentStatus
          orderNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listReservationsByGroup = /* GraphQL */ `
  query ListReservationsByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservationsByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listReservationsByGroupByEndTime = /* GraphQL */ `
  query ListReservationsByGroupByEndTime(
    $group: String
    $endTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservationsByGroupByEndTime(
      group: $group
      endTime: $endTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listReservationsByPaymentStatus = /* GraphQL */ `
  query ListReservationsByPaymentStatus(
    $paymentStatus: PaymentStatus
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservationsByPaymentStatus(
      paymentStatus: $paymentStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listReservationsBySignStatus = /* GraphQL */ `
  query ListReservationsBySignStatus(
    $signStatus: SignStatus
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservationsBySignStatus(
      signStatus: $signStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listReservationsByAuthStatus = /* GraphQL */ `
  query ListReservationsByAuthStatus(
    $authStatus: AuthStatus
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservationsByAuthStatus(
      authStatus: $authStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchReservations = /* GraphQL */ `
  query SearchReservations(
    $filter: SearchableReservationFilterInput
    $sort: SearchableReservationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReservations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getSignEvent = /* GraphQL */ `
  query GetSignEvent($id: ID!) {
    getSignEvent(id: $id) {
      id
      reservationId
      company
      group
      status
      documentId
      createdAt
      updatedAt
    }
  }
`;
export const listSignEvents = /* GraphQL */ `
  query ListSignEvents(
    $filter: ModelSignEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reservationId
        company
        group
        status
        documentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentEvent = /* GraphQL */ `
  query GetPaymentEvent($id: ID!) {
    getPaymentEvent(id: $id) {
      id
      reservationId
      group
      msg
      returnCode
      incidentId
      parameters
      apiPath
      paymentStatus
      orderNumber
      createdAt
      updatedAt
      reservation {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
    }
  }
`;
export const listPaymentEvents = /* GraphQL */ `
  query ListPaymentEvents(
    $filter: ModelPaymentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reservationId
        group
        msg
        returnCode
        incidentId
        parameters
        apiPath
        paymentStatus
        orderNumber
        createdAt
        updatedAt
        reservation {
          id
          group
          companyId
          reservationVehicleId
          createdAt
          updatedAt
          startTime
          returnTime
          endTime
          name
          address
          postalCode
          city
          phone
          email
          ssn
          internalNotes
          externalNotes
          vehiclePrice
          driverSSN
          differentDriver
          driverName
          driverAddress
          driverPostalCode
          driverCity
          driverPhone
          driverEmail
          additionalDriver
          additionalDriverName
          additionalDriverPhone
          additionalDriverSSN
          isCompany
          companyName
          companyBusinessId
          companyBillingRef
          companyBillingAddress
          paymentToken
          paymentStatus
          signStatus
          signDocumentId
          signerName
          authStatus
          authTime
          completeBefore
          mustSign
        }
      }
      nextToken
    }
  }
`;
export const getAdditionalService = /* GraphQL */ `
  query GetAdditionalService($id: ID!) {
    getAdditionalService(id: $id) {
      id
      group
      description
      description2
      category
      enableTextInput
      enableQuantity
      maxQuantity
      inputLabel
      _removed
      isGroupParent
      groupFields
      groupParentId
      groupOrderNumber
      createdAt
      updatedAt
    }
  }
`;
export const listAdditionalServices = /* GraphQL */ `
  query ListAdditionalServices(
    $filter: ModelAdditionalServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        description
        description2
        category
        enableTextInput
        enableQuantity
        maxQuantity
        inputLabel
        _removed
        isGroupParent
        groupFields
        groupParentId
        groupOrderNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const additionalServiceByGroup = /* GraphQL */ `
  query AdditionalServiceByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelAdditionalServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    additionalServiceByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        description
        description2
        category
        enableTextInput
        enableQuantity
        maxQuantity
        inputLabel
        _removed
        isGroupParent
        groupFields
        groupParentId
        groupOrderNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPricing = /* GraphQL */ `
  query GetPricing($id: ID!) {
    getPricing(id: $id) {
      id
      name
      group
      elements {
        duration
        price
      }
      useNextDayPrice
      _removed
      createdAt
      updatedAt
    }
  }
`;
export const listPricings = /* GraphQL */ `
  query ListPricings(
    $filter: ModelPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pricingByGroup = /* GraphQL */ `
  query PricingByGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricingByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPricingException = /* GraphQL */ `
  query GetPricingException($id: ID!) {
    getPricingException(id: $id) {
      id
      name
      group
      type
      amountType
      weeklyAmounts
      monthlyAmountPerDay
      daysBeforeMonthChange
      daysAfterMonthChange
      startDate
      endDate
      pricingIds
      createdAt
      updatedAt
    }
  }
`;
export const listPricingExceptions = /* GraphQL */ `
  query ListPricingExceptions(
    $filter: ModelPricingExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricingExceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        group
        type
        amountType
        weeklyAmounts
        monthlyAmountPerDay
        daysBeforeMonthChange
        daysAfterMonthChange
        startDate
        endDate
        pricingIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoucher = /* GraphQL */ `
  query GetVoucher($id: ID!) {
    getVoucher(id: $id) {
      id
      name
      group
      code
      amount
      _removed
      createdAt
      updatedAt
    }
  }
`;
export const listVouchers = /* GraphQL */ `
  query ListVouchers(
    $filter: ModelVoucherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVouchers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        group
        code
        amount
        _removed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      status
      group
      reservationId
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        status
        group
        reservationId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNotificationsByGroupByStatus = /* GraphQL */ `
  query ListNotificationsByGroupByStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByGroupByStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        status
        group
        reservationId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNotificationsByGroupByCreatedAt = /* GraphQL */ `
  query ListNotificationsByGroupByCreatedAt(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByGroupByCreatedAt(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        status
        group
        reservationId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
