/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const upsertReservationAdmin = /* GraphQL */ `
  mutation UpsertReservationAdmin(
    $reservation: UpsertReservationInputAdmin!
    $sendStatusToCustomer: Boolean
  ) {
    upsertReservationAdmin(
      reservation: $reservation
      sendStatusToCustomer: $sendStatusToCustomer
    ) {
      reservationId
      emailConfirmationSuccess
      code
      success
      destinationEmail
    }
  }
`;
export const resetAndInitSign = /* GraphQL */ `
  mutation ResetAndInitSign($reservationId: ID!) {
    resetAndInitSign(reservationId: $reservationId) {
      success
      message
    }
  }
`;
export const sendEmailVerification = /* GraphQL */ `
  mutation SendEmailVerification($reservationId: ID!) {
    sendEmailVerification(reservationId: $reservationId)
  }
`;
export const createLivionContract = /* GraphQL */ `
  mutation CreateLivionContract($reservationId: ID!) {
    createLivionContract(reservationId: $reservationId) {
      success
      pincode
      registrationPlate
    }
  }
`;
export const migrateReservations = /* GraphQL */ `
  mutation MigrateReservations($ids: [ID!]!) {
    migrateReservations(ids: $ids) {
      id
      group
      companyId
      reservationVehicleId
      reservationVehicles {
        id
        price
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      ssn
      internalNotes
      externalNotes
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        deductibleValue
        groupFields
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      isCompany
      companyName
      companyBusinessId
      companyBillingRef
      companyBillingAddress
      paymentToken
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
      }
      signStatus
      signDocumentId
      signerName
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
      }
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      paymentEvents {
        items {
          id
          reservationId
          group
          msg
          returnCode
          incidentId
          parameters
          apiPath
          paymentStatus
          orderNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const upsertReservationCustomer = /* GraphQL */ `
  mutation UpsertReservationCustomer(
    $reservation: UpsertReservationInputCustomer!
    $paymentMode: String
  ) {
    upsertReservationCustomer(
      reservation: $reservation
      paymentMode: $paymentMode
    ) {
      reservationId
      emailConfirmationSuccess
      code
      success
      destinationEmail
    }
  }
`;
export const setReservationInfo = /* GraphQL */ `
  mutation SetReservationInfo($reservation: ReservationInfoInput!) {
    setReservationInfo(reservation: $reservation) {
      mustSign
      ssn
      group
      companyTermsUrl
      companyId
      externalNotes
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      driverSSN
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      isCompany
      companyName
      companyBusinessId
      companyBillingRef
      companyBillingAddress
      authStatus
      startTime
      returnTime
      paymentPending
      paymentStatus
      signStatus
      enableSign
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
      }
      askSsn
      name
      address
      postalCode
      city
      phone
      email
      vehicles {
        id
        group
        companyId
        name
        registrationPlate
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        orderNumber
        price
      }
      services {
        key
        quantity
        description
        price
      }
    }
  }
`;
export const setReservationPaymentStatus = /* GraphQL */ `
  mutation SetReservationPaymentStatus($reservationId: ID!) {
    setReservationPaymentStatus(reservationId: $reservationId) {
      reservationId
      emailConfirmationSuccess
      code
      success
      destinationEmail
    }
  }
`;
export const cancelReservation = /* GraphQL */ `
  mutation CancelReservation($reservationId: ID!) {
    cancelReservation(reservationId: $reservationId)
  }
`;
export const initVismaSign = /* GraphQL */ `
  mutation InitVismaSign($reservationId: ID!) {
    initVismaSign(reservationId: $reservationId) {
      success
      message
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      group
      name
      displayName
      email
      address
      postalCode
      city
      orderNumber
      orderNumberDealer
      phone
      businessId
      termsUrl
      timezone
      weeklyBusinessHours {
        openAt
        closeAt
      }
      signConfirmationEmail
      nameInEmail
      confirmationMsg
      statusLinkMsg
      livionDeviceId
      livionDeviceType
      enableReservationDeposit
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
      }
      disableOffHourReturn
      disableMultiday
      disableSameDay
      disableStartDaysFromNow
      enableCart
      enableMonthlyView
      enableVehicleStartReturnTimes
      enableMustSign
      enablePaymentOnlineOnly
      enableGTM
      limitReservationOncePerDay
      hidden
      receiptInfo {
        enabled
        name
        address
        city
        postalCode
      }
      createdAt
      updatedAt
      merchantDetails {
        merchant_id
        cp
      }
      businessHoursExceptions {
        items {
          group
          date
          name
          companyId
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      group
      name
      displayName
      email
      address
      postalCode
      city
      orderNumber
      orderNumberDealer
      phone
      businessId
      termsUrl
      timezone
      weeklyBusinessHours {
        openAt
        closeAt
      }
      signConfirmationEmail
      nameInEmail
      confirmationMsg
      statusLinkMsg
      livionDeviceId
      livionDeviceType
      enableReservationDeposit
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
      }
      disableOffHourReturn
      disableMultiday
      disableSameDay
      disableStartDaysFromNow
      enableCart
      enableMonthlyView
      enableVehicleStartReturnTimes
      enableMustSign
      enablePaymentOnlineOnly
      enableGTM
      limitReservationOncePerDay
      hidden
      receiptInfo {
        enabled
        name
        address
        city
        postalCode
      }
      createdAt
      updatedAt
      merchantDetails {
        merchant_id
        cp
      }
      businessHoursExceptions {
        items {
          group
          date
          name
          companyId
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      group
      name
      displayName
      email
      address
      postalCode
      city
      orderNumber
      orderNumberDealer
      phone
      businessId
      termsUrl
      timezone
      weeklyBusinessHours {
        openAt
        closeAt
      }
      signConfirmationEmail
      nameInEmail
      confirmationMsg
      statusLinkMsg
      livionDeviceId
      livionDeviceType
      enableReservationDeposit
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
      }
      disableOffHourReturn
      disableMultiday
      disableSameDay
      disableStartDaysFromNow
      enableCart
      enableMonthlyView
      enableVehicleStartReturnTimes
      enableMustSign
      enablePaymentOnlineOnly
      enableGTM
      limitReservationOncePerDay
      hidden
      receiptInfo {
        enabled
        name
        address
        city
        postalCode
      }
      createdAt
      updatedAt
      merchantDetails {
        merchant_id
        cp
      }
      businessHoursExceptions {
        items {
          group
          date
          name
          companyId
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBusinessHoursException = /* GraphQL */ `
  mutation CreateBusinessHoursException(
    $input: CreateBusinessHoursExceptionInput!
    $condition: ModelBusinessHoursExceptionConditionInput
  ) {
    createBusinessHoursException(input: $input, condition: $condition) {
      group
      date
      name
      businessHours {
        openAt
        closeAt
      }
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessHoursException = /* GraphQL */ `
  mutation UpdateBusinessHoursException(
    $input: UpdateBusinessHoursExceptionInput!
    $condition: ModelBusinessHoursExceptionConditionInput
  ) {
    updateBusinessHoursException(input: $input, condition: $condition) {
      group
      date
      name
      businessHours {
        openAt
        closeAt
      }
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessHoursException = /* GraphQL */ `
  mutation DeleteBusinessHoursException(
    $input: DeleteBusinessHoursExceptionInput!
    $condition: ModelBusinessHoursExceptionConditionInput
  ) {
    deleteBusinessHoursException(input: $input, condition: $condition) {
      group
      date
      name
      businessHours {
        openAt
        closeAt
      }
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle(
    $input: CreateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    createVehicle(input: $input, condition: $condition) {
      id
      group
      companyId
      name
      registrationPlate
      damages
      additionalFields {
        key
        value
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      startReturnInterval {
        start
        end
      }
      startReturnTimes {
        start
        return
      }
      hideExport
      extraInfo
      livionKeyId
      createdAt
      updatedAt
      category {
        id
        group
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        typeId
        _removed
        createdAt
        updatedAt
        additionalServices {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      additionalServices {
        items {
          id
          group
          key
          vehicleId
          categoryId
          pricingId
          deductibleValue
          createdAt
          updatedAt
        }
        nextToken
      }
      reservations {
        items {
          id
          group
          companyId
          reservationVehicleId
          createdAt
          updatedAt
          startTime
          returnTime
          endTime
          name
          address
          postalCode
          city
          phone
          email
          ssn
          internalNotes
          externalNotes
          vehiclePrice
          driverSSN
          differentDriver
          driverName
          driverAddress
          driverPostalCode
          driverCity
          driverPhone
          driverEmail
          additionalDriver
          additionalDriverName
          additionalDriverPhone
          additionalDriverSSN
          isCompany
          companyName
          companyBusinessId
          companyBillingRef
          companyBillingAddress
          paymentToken
          paymentStatus
          signStatus
          signDocumentId
          signerName
          authStatus
          authTime
          completeBefore
          mustSign
        }
        nextToken
      }
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle(
    $input: UpdateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    updateVehicle(input: $input, condition: $condition) {
      id
      group
      companyId
      name
      registrationPlate
      damages
      additionalFields {
        key
        value
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      startReturnInterval {
        start
        end
      }
      startReturnTimes {
        start
        return
      }
      hideExport
      extraInfo
      livionKeyId
      createdAt
      updatedAt
      category {
        id
        group
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        typeId
        _removed
        createdAt
        updatedAt
        additionalServices {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      additionalServices {
        items {
          id
          group
          key
          vehicleId
          categoryId
          pricingId
          deductibleValue
          createdAt
          updatedAt
        }
        nextToken
      }
      reservations {
        items {
          id
          group
          companyId
          reservationVehicleId
          createdAt
          updatedAt
          startTime
          returnTime
          endTime
          name
          address
          postalCode
          city
          phone
          email
          ssn
          internalNotes
          externalNotes
          vehiclePrice
          driverSSN
          differentDriver
          driverName
          driverAddress
          driverPostalCode
          driverCity
          driverPhone
          driverEmail
          additionalDriver
          additionalDriverName
          additionalDriverPhone
          additionalDriverSSN
          isCompany
          companyName
          companyBusinessId
          companyBillingRef
          companyBillingAddress
          paymentToken
          paymentStatus
          signStatus
          signDocumentId
          signerName
          authStatus
          authTime
          completeBefore
          mustSign
        }
        nextToken
      }
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteVehicle = /* GraphQL */ `
  mutation DeleteVehicle(
    $input: DeleteVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    deleteVehicle(input: $input, condition: $condition) {
      id
      group
      companyId
      name
      registrationPlate
      damages
      additionalFields {
        key
        value
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      startReturnInterval {
        start
        end
      }
      startReturnTimes {
        start
        return
      }
      hideExport
      extraInfo
      livionKeyId
      createdAt
      updatedAt
      category {
        id
        group
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        typeId
        _removed
        createdAt
        updatedAt
        additionalServices {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      additionalServices {
        items {
          id
          group
          key
          vehicleId
          categoryId
          pricingId
          deductibleValue
          createdAt
          updatedAt
        }
        nextToken
      }
      reservations {
        items {
          id
          group
          companyId
          reservationVehicleId
          createdAt
          updatedAt
          startTime
          returnTime
          endTime
          name
          address
          postalCode
          city
          phone
          email
          ssn
          internalNotes
          externalNotes
          vehiclePrice
          driverSSN
          differentDriver
          driverName
          driverAddress
          driverPostalCode
          driverCity
          driverPhone
          driverEmail
          additionalDriver
          additionalDriverName
          additionalDriverPhone
          additionalDriverSSN
          isCompany
          companyName
          companyBusinessId
          companyBillingRef
          companyBillingAddress
          paymentToken
          paymentStatus
          signStatus
          signDocumentId
          signerName
          authStatus
          authTime
          completeBefore
          mustSign
        }
        nextToken
      }
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      group
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      typeId
      _removed
      createdAt
      updatedAt
      additionalServices {
        items {
          id
          group
          key
          vehicleId
          categoryId
          pricingId
          deductibleValue
          createdAt
          updatedAt
        }
        nextToken
      }
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      group
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      typeId
      _removed
      createdAt
      updatedAt
      additionalServices {
        items {
          id
          group
          key
          vehicleId
          categoryId
          pricingId
          deductibleValue
          createdAt
          updatedAt
        }
        nextToken
      }
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      group
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      typeId
      _removed
      createdAt
      updatedAt
      additionalServices {
        items {
          id
          group
          key
          vehicleId
          categoryId
          pricingId
          deductibleValue
          createdAt
          updatedAt
        }
        nextToken
      }
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAdditionalServicePricing = /* GraphQL */ `
  mutation CreateAdditionalServicePricing(
    $input: CreateAdditionalServicePricingInput!
    $condition: ModelAdditionalServicePricingConditionInput
  ) {
    createAdditionalServicePricing(input: $input, condition: $condition) {
      id
      group
      key
      vehicleId
      categoryId
      pricingId
      deductibleValue
      createdAt
      updatedAt
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAdditionalServicePricing = /* GraphQL */ `
  mutation UpdateAdditionalServicePricing(
    $input: UpdateAdditionalServicePricingInput!
    $condition: ModelAdditionalServicePricingConditionInput
  ) {
    updateAdditionalServicePricing(input: $input, condition: $condition) {
      id
      group
      key
      vehicleId
      categoryId
      pricingId
      deductibleValue
      createdAt
      updatedAt
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAdditionalServicePricing = /* GraphQL */ `
  mutation DeleteAdditionalServicePricing(
    $input: DeleteAdditionalServicePricingInput!
    $condition: ModelAdditionalServicePricingConditionInput
  ) {
    deleteAdditionalServicePricing(input: $input, condition: $condition) {
      id
      group
      key
      vehicleId
      categoryId
      pricingId
      deductibleValue
      createdAt
      updatedAt
      pricing {
        id
        name
        group
        elements {
          duration
          price
        }
        useNextDayPrice
        _removed
        createdAt
        updatedAt
      }
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      id
      group
      companyId
      reservationVehicleId
      reservationVehicles {
        id
        price
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      ssn
      internalNotes
      externalNotes
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        deductibleValue
        groupFields
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      isCompany
      companyName
      companyBusinessId
      companyBillingRef
      companyBillingAddress
      paymentToken
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
      }
      signStatus
      signDocumentId
      signerName
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
      }
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      paymentEvents {
        items {
          id
          reservationId
          group
          msg
          returnCode
          incidentId
          parameters
          apiPath
          paymentStatus
          orderNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      group
      companyId
      reservationVehicleId
      reservationVehicles {
        id
        price
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      ssn
      internalNotes
      externalNotes
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        deductibleValue
        groupFields
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      isCompany
      companyName
      companyBusinessId
      companyBillingRef
      companyBillingAddress
      paymentToken
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
      }
      signStatus
      signDocumentId
      signerName
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
      }
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      paymentEvents {
        items {
          id
          reservationId
          group
          msg
          returnCode
          incidentId
          parameters
          apiPath
          paymentStatus
          orderNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      group
      companyId
      reservationVehicleId
      reservationVehicles {
        id
        price
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      ssn
      internalNotes
      externalNotes
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        deductibleValue
        groupFields
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      isCompany
      companyName
      companyBusinessId
      companyBillingRef
      companyBillingAddress
      paymentToken
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
      }
      signStatus
      signDocumentId
      signerName
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
      }
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        damages
        additionalFields {
          key
          value
        }
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        startReturnInterval {
          start
          end
        }
        startReturnTimes {
          start
          return
        }
        hideExport
        extraInfo
        livionKeyId
        createdAt
        updatedAt
        category {
          id
          group
          name
          image
          orderNumber
          backgroundColor
          minuteBuffer
          typeId
          _removed
          createdAt
          updatedAt
        }
        additionalServices {
          nextToken
        }
        reservations {
          nextToken
        }
        pricing {
          id
          name
          group
          useNextDayPrice
          _removed
          createdAt
          updatedAt
        }
      }
      paymentEvents {
        items {
          id
          reservationId
          group
          msg
          returnCode
          incidentId
          parameters
          apiPath
          paymentStatus
          orderNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSignEvent = /* GraphQL */ `
  mutation CreateSignEvent(
    $input: CreateSignEventInput!
    $condition: ModelSignEventConditionInput
  ) {
    createSignEvent(input: $input, condition: $condition) {
      id
      reservationId
      company
      group
      status
      documentId
      createdAt
      updatedAt
    }
  }
`;
export const updateSignEvent = /* GraphQL */ `
  mutation UpdateSignEvent(
    $input: UpdateSignEventInput!
    $condition: ModelSignEventConditionInput
  ) {
    updateSignEvent(input: $input, condition: $condition) {
      id
      reservationId
      company
      group
      status
      documentId
      createdAt
      updatedAt
    }
  }
`;
export const deleteSignEvent = /* GraphQL */ `
  mutation DeleteSignEvent(
    $input: DeleteSignEventInput!
    $condition: ModelSignEventConditionInput
  ) {
    deleteSignEvent(input: $input, condition: $condition) {
      id
      reservationId
      company
      group
      status
      documentId
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentEvent = /* GraphQL */ `
  mutation CreatePaymentEvent(
    $input: CreatePaymentEventInput!
    $condition: ModelPaymentEventConditionInput
  ) {
    createPaymentEvent(input: $input, condition: $condition) {
      id
      reservationId
      group
      msg
      returnCode
      incidentId
      parameters
      apiPath
      paymentStatus
      orderNumber
      createdAt
      updatedAt
      reservation {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
    }
  }
`;
export const updatePaymentEvent = /* GraphQL */ `
  mutation UpdatePaymentEvent(
    $input: UpdatePaymentEventInput!
    $condition: ModelPaymentEventConditionInput
  ) {
    updatePaymentEvent(input: $input, condition: $condition) {
      id
      reservationId
      group
      msg
      returnCode
      incidentId
      parameters
      apiPath
      paymentStatus
      orderNumber
      createdAt
      updatedAt
      reservation {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
    }
  }
`;
export const deletePaymentEvent = /* GraphQL */ `
  mutation DeletePaymentEvent(
    $input: DeletePaymentEventInput!
    $condition: ModelPaymentEventConditionInput
  ) {
    deletePaymentEvent(input: $input, condition: $condition) {
      id
      reservationId
      group
      msg
      returnCode
      incidentId
      parameters
      apiPath
      paymentStatus
      orderNumber
      createdAt
      updatedAt
      reservation {
        id
        group
        companyId
        reservationVehicleId
        reservationVehicles {
          id
          price
        }
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        ssn
        internalNotes
        externalNotes
        vehiclePrice
        additionalServices {
          key
          price
          details
          quantity
          deductibleValue
          groupFields
        }
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        isCompany
        companyName
        companyBusinessId
        companyBillingRef
        companyBillingAddress
        paymentToken
        paymentStatus
        paymentData {
          paymentTotal
          paymentTodo
          depositPending
          deposit
        }
        signStatus
        signDocumentId
        signerName
        authStatus
        authTime
        completeBefore
        mustSign
        livionData {
          id
          pincode
          keyId
          name
          registrationPlate
        }
        vehicle {
          id
          group
          companyId
          name
          registrationPlate
          damages
          image
          powerType
          deductible
          includedDistance
          mileage
          orderNumber
          vehicleCategoryId
          useCategoryDefaults
          useStartReturnTimes
          hideExport
          extraInfo
          livionKeyId
          createdAt
          updatedAt
        }
        paymentEvents {
          nextToken
        }
      }
    }
  }
`;
export const createAdditionalService = /* GraphQL */ `
  mutation CreateAdditionalService(
    $input: CreateAdditionalServiceInput!
    $condition: ModelAdditionalServiceConditionInput
  ) {
    createAdditionalService(input: $input, condition: $condition) {
      id
      group
      description
      description2
      category
      enableTextInput
      enableQuantity
      maxQuantity
      inputLabel
      _removed
      isGroupParent
      groupFields
      groupParentId
      groupOrderNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateAdditionalService = /* GraphQL */ `
  mutation UpdateAdditionalService(
    $input: UpdateAdditionalServiceInput!
    $condition: ModelAdditionalServiceConditionInput
  ) {
    updateAdditionalService(input: $input, condition: $condition) {
      id
      group
      description
      description2
      category
      enableTextInput
      enableQuantity
      maxQuantity
      inputLabel
      _removed
      isGroupParent
      groupFields
      groupParentId
      groupOrderNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdditionalService = /* GraphQL */ `
  mutation DeleteAdditionalService(
    $input: DeleteAdditionalServiceInput!
    $condition: ModelAdditionalServiceConditionInput
  ) {
    deleteAdditionalService(input: $input, condition: $condition) {
      id
      group
      description
      description2
      category
      enableTextInput
      enableQuantity
      maxQuantity
      inputLabel
      _removed
      isGroupParent
      groupFields
      groupParentId
      groupOrderNumber
      createdAt
      updatedAt
    }
  }
`;
export const createPricing = /* GraphQL */ `
  mutation CreatePricing(
    $input: CreatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    createPricing(input: $input, condition: $condition) {
      id
      name
      group
      elements {
        duration
        price
      }
      useNextDayPrice
      _removed
      createdAt
      updatedAt
    }
  }
`;
export const updatePricing = /* GraphQL */ `
  mutation UpdatePricing(
    $input: UpdatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    updatePricing(input: $input, condition: $condition) {
      id
      name
      group
      elements {
        duration
        price
      }
      useNextDayPrice
      _removed
      createdAt
      updatedAt
    }
  }
`;
export const deletePricing = /* GraphQL */ `
  mutation DeletePricing(
    $input: DeletePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    deletePricing(input: $input, condition: $condition) {
      id
      name
      group
      elements {
        duration
        price
      }
      useNextDayPrice
      _removed
      createdAt
      updatedAt
    }
  }
`;
export const createPricingException = /* GraphQL */ `
  mutation CreatePricingException(
    $input: CreatePricingExceptionInput!
    $condition: ModelPricingExceptionConditionInput
  ) {
    createPricingException(input: $input, condition: $condition) {
      id
      name
      group
      type
      amountType
      weeklyAmounts
      monthlyAmountPerDay
      daysBeforeMonthChange
      daysAfterMonthChange
      startDate
      endDate
      pricingIds
      createdAt
      updatedAt
    }
  }
`;
export const updatePricingException = /* GraphQL */ `
  mutation UpdatePricingException(
    $input: UpdatePricingExceptionInput!
    $condition: ModelPricingExceptionConditionInput
  ) {
    updatePricingException(input: $input, condition: $condition) {
      id
      name
      group
      type
      amountType
      weeklyAmounts
      monthlyAmountPerDay
      daysBeforeMonthChange
      daysAfterMonthChange
      startDate
      endDate
      pricingIds
      createdAt
      updatedAt
    }
  }
`;
export const deletePricingException = /* GraphQL */ `
  mutation DeletePricingException(
    $input: DeletePricingExceptionInput!
    $condition: ModelPricingExceptionConditionInput
  ) {
    deletePricingException(input: $input, condition: $condition) {
      id
      name
      group
      type
      amountType
      weeklyAmounts
      monthlyAmountPerDay
      daysBeforeMonthChange
      daysAfterMonthChange
      startDate
      endDate
      pricingIds
      createdAt
      updatedAt
    }
  }
`;
export const createVoucher = /* GraphQL */ `
  mutation CreateVoucher(
    $input: CreateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    createVoucher(input: $input, condition: $condition) {
      id
      name
      group
      code
      amount
      _removed
      createdAt
      updatedAt
    }
  }
`;
export const updateVoucher = /* GraphQL */ `
  mutation UpdateVoucher(
    $input: UpdateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    updateVoucher(input: $input, condition: $condition) {
      id
      name
      group
      code
      amount
      _removed
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoucher = /* GraphQL */ `
  mutation DeleteVoucher(
    $input: DeleteVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    deleteVoucher(input: $input, condition: $condition) {
      id
      name
      group
      code
      amount
      _removed
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      status
      group
      reservationId
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      status
      group
      reservationId
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      status
      group
      reservationId
      createdAt
      updatedAt
    }
  }
`;
