exports.parsePricingElements = (pricingElements) => {
  const hourlyRates = Array(24)
    .fill(null)
    .map((empty, index) => {
      const durationStr = "h" + index;
      const element = pricingElements.find(
        (element) => element.duration === durationStr
      );
      return element ? element.price : null;
    });

  const dailyRates = Array(30)
    .fill(null)
    .map((empty, index) => {
      const durationStr = "d" + index;
      const element = pricingElements.find(
        (element) => element.duration === durationStr
      );
      return element ? element.price : null;
    });

  return {
    hourlyRates,
    dailyRates,
  };
};

exports.calculateEffectivePrices = (prices, defaultStartPrice) => {
  const result = [];

  prices.reduce(
    (state, currentRate) => {
      if (currentRate == null) {
        currentRate = state.currentEffectiveRate;
      }

      const newState = {
        currentEffectiveRate: currentRate,
        total: state.total + currentRate,
      };

      result.push(newState);

      return newState;
    },
    {
      currentEffectiveRate: defaultStartPrice || 0,
      total: 0,
    }
  );

  return result;
};

exports.calculateDiscountedPrice = (listPrice, discountPercent) => {
  const discount = Math.round((discountPercent / 100) * listPrice);
  return listPrice - discount;
};

exports.calculateDynamicPrice = (listPrice, dynamicPercent) => {
  const extra = Math.round((dynamicPercent / 100) * listPrice);
  return listPrice + extra;
};

function differenceInHours(to, from) {
  const MILLISECONDS_IN_HOUR = 3600000;
  return Math.floor((to.getTime() - from.getTime()) / MILLISECONDS_IN_HOUR);
}

exports.pricingDuration = (_from, _to) => {
  const to = new Date(_to);
  const from = new Date(_from);
  const exclusiveTo = new Date(to.getTime() - 1000);
  const diffTotalHours = differenceInHours(exclusiveTo, from) + 1;

  const diffDays = Math.floor(diffTotalHours / 24);
  const diffHours = diffTotalHours % 24;

  return {
    days: diffDays,
    hours: diffHours,
  };
};
