import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const additionalFields = {
  passengers: {
    label: "Matkustajien lukumäärä",
    unit: "henkeä",
  },
  requiredLicense: {
    label: "Vaadittu ajo-oikeus",
    unit: "-kortti",
  },
  doors: {
    label: "Ovet",
    unit: "ovinen",
  },
  fuelConsumption: {
    label: "Kulutus",
    unit: "L / 100km",
  },
  outerSize: {
    label: "Ulkomitat",
    unit: "p. x l. x k.",
  },
  innerSize: {
    label: "Tavaratilan sisämitat",
    unit: "p. x l. x k.",
  },
  volume: {
    label: "Tilavuus",
    unit: "m³",
  },
  equipments: {
    label: "Varusteet",
  },
  other: {
    label: "Muut tiedot",
  },
};

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: "text-bottom",
  },
}));

export default function AdditionalFieldsDialog({ onClose, fields }) {
  const classes = useStyles();

  return (
    <Dialog open={true} onClose={onClose} fullWidth className={classes.dialog}>
      <DialogTitle>
        <InfoOutlinedIcon className={classes.icon} />
        &nbsp;Lisätietoja
      </DialogTitle>
      <DialogContent>
        <List>
          {fields.map((field) => {
            const fieldInfo = additionalFields[field.key];
            return fieldInfo ? (
              <ListItem key={field.key}>
                <ListItemText
                  primary={fieldInfo.label}
                  secondary={
                    field.value + (fieldInfo.unit ? " " + fieldInfo.unit : "")
                  }
                />
              </ListItem>
            ) : null;
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}
