import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { pink } from "@material-ui/core/colors";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import fiLocale from "date-fns/locale/fi";
import root from "react-shadow";
import { jssPreset, StylesProvider } from "@material-ui/styles";
import { create } from "jss";
import React, { useState } from "react";
import datePickerStyles from "./const/datePickerStyles.js";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV || "unknown",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
  beforeSend: (event, hint) => {
    console.error(hint.originalException || hint.syntheticException);
    if (process.env.REACT_APP_ENV === "local") {
      return null;
    } else {
      return event;
    }
  },
});
Sentry.setTag("app", "customer");

const WrappedJssComponent = ({ children }) => {
  const [jss, setJss] = useState(null);

  function setRefAndCreateJss(headRef) {
    if (headRef && !jss) {
      const createdJssWithRef = create({
        ...jssPreset(),
        insertionPoint: headRef,
      });
      setJss(createdJssWithRef);
    }
  }

  return (
    <root.div>
      <style>{`:host { all: initial; }`}</style>
      <style>{datePickerStyles}</style>
      <style ref={setRefAndCreateJss}></style>
      <div id="app-container">
        {jss && <StylesProvider jss={jss}>{children}</StylesProvider>}
      </div>
    </root.div>
  );
};

let portalContainer;

const getContainer = () => {
  if (portalContainer == null) {
    portalContainer = document
      .querySelector("#urent-app-root > div")
      .shadowRoot.getElementById("app-container")
      .appendChild(document.createElement("div"));
  }
  return portalContainer;
};

let color = { main: "#012659" };

if (window.URENT_APP_COLOR) {
  color = {
    main: window.URENT_APP_COLOR,
  };
}

const theme = createMuiTheme({
  palette: {
    primary: color,
    secondary: color,
  },
  props: {
    MuiPopover: {
      container: getContainer,
    },
    MuiDialog: {
      container: getContainer,
    },
  },
});

ReactDOM.render(
  <WrappedJssComponent>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
        <App />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </WrappedJssComponent>,
  document.getElementById("urent-app-root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
