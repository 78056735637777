export const paymentEnabled = (env, group) => {
  if (env === "local" || env === "dev" || env === "staging") return true;
  try {
    const featureGroups = process.env.REACT_APP_FEATURE_PAYMENT.split(" ");
    const enabled = featureGroups.includes(group);
    return enabled;
  } catch (e) {
    return false;
  }
};

export const askSsn = (group) => {
  const featureGroups = [
    "stadinpakut",
    "pakunvuokraus",
    "prgroup",
    "vuokraapaku",
    "THERent",
    "nlp-group",
  ];
  const enabled = featureGroups.includes(group);
  return enabled;
};

export const disableCreditcheck = (group) => {
  const featureGroups = [
    "khautovuokraus",
    "polareyllas",
    "joensuunkauhojat",
    "ebikerental",
    "jyrkila",
  ];
  const enabled = featureGroups.includes(group);
  return enabled;
};

export const disableCreditcheckStatusPage = (group) => {
  const featureGroups = [
    "khautovuokraus",
    "polareyllas",
    "joensuunkauhojat",
    "ebikerental",
    "jyrkila",
    "gnista",
  ];
  const enabled = featureGroups.includes(group);
  return enabled;
};

export const showPortInstruction = (companyId) => {
  // ID of group rsteam company vesijetit
  return companyId === "f2b7b12a-b755-4751-8802-934e63fcd8db" ? true : false;
};

export const enableVismaSign = (env, group) => {
  if (env === "local" || env === "staging") {
    return true;
  }
  try {
    const featureGroups = process.env.REACT_APP_FEATURE_SIGN.split(" ");
    const enabled = featureGroups.includes(group);
    return enabled;
  } catch (e) {
    console.error(e);
    return false;
  }
};

// Higher limit of Visma Pay in cents
export const vismaPayMaxPayment = (group) => {
  // Visma pay maximum payment is higher for prgroup
  if (group === "prgroup") {
    return 600000;
  }
  return 200000;
};

// TODO: Obviously this works only as long as there is just one group on the embedd
export const enableCustomProviderLabel = () => {
  const group = window.URENT_APP_GROUPS?.[0];
  if (group) {
    const featureGroups = ["rsteam", "THERent"];
    const enabled = featureGroups.includes(group);
    return enabled;
  }
  return false;
};

export const hideZeroPrices = (env, company) => {
  if (company.id === "6aec4644-93bb-4bb3-bdc0-26f28a4d8cdf") return true;
  return false;
};

export const replaceWorkIcon = (env, company) => {
  if (company.id === "6aec4644-93bb-4bb3-bdc0-26f28a4d8cdf") return true;
  return false;
};

export const replaceVolumeIcon = (env, company) => {
  if (company.group === "ebikerental") return true;
  return false;
};
// Don't show and require all the input fields
export const enableMinimalCustomerInput = (env, group) => {
  if (group === "joensuunkauhojat") return true;
  return false;
};

export const isDev = (env, group) => {
  if (env === "local") return true;
  return false;
};

export const enablePrivacyPolicy = (env, group) => {
  if (group === "joensuunkauhojat") return true;
  return false;
};

// Be careful with this setting, as it will not behave correctly if all Companys
// within the group use the same settings.
// If enabled, return a Company with wanted settings. (businessHours, feature flags..)
export const selectAllCompanies = (companies) => {
  const groups = window.URENT_APP_GROUPS;
  if (!groups || groups.length > 1) return false;
  const group = groups[0];
  // debug
  //if (group === "ville") {
  //const companySettings = companies.find(
  //(c) => c.id === "e861d76e-cd62-4415-8b80-1eacd63249de"
  //);
  //return {
  //...companySettings,
  //id: "all",
  //};
  //}
  // Vuokraapaku
  if (group === "vuokraapaku") {
    // Get a first 24/7 company (for vuokraapaku only Sörnäinen with no 24/7 has disableOffHourReturn)
    const companySettings = companies.find((c) => !c.disableOffHourReturn);
    return {
      ...companySettings,
      id: "all",
    };
  }
  return false;
};

// Return array of companyIds that should be fetched
export const selectAllCompanyIds = (companies) => {
  const groups = window.URENT_APP_GROUPS;
  if (!groups || groups.length > 1) return false;
  const group = groups[0];
  // debug
  //if (group === "ville") {
  //const filteredCompanies = companies.filter(
  //(c) =>
  //c.id === "e861d76e-cd62-4415-8b80-1eacd63249de" ||
  //c.id === "d920231b-d33e-43a4-abe6-08919d61c10f"
  //);
  //const companyIds = filteredCompanies.map((c) => c.id);
  //return companyIds;
  //}
  if (group === "vuokraapaku") {
    // Only non-24/7 have disableOffHourReturn. Get all 24/7, and others may have hard-coded stuff at frontend
    const filteredCompanies = companies.filter((c) => !c.disableOffHourReturn);
    const companyIds = filteredCompanies.map((c) => c.id);
    return companyIds;
  }
  return false;
};
