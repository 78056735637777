export function getPaymentMethods(env) {
  if (env === "master") {
    return [
      {
        name: "Nordea",
        selected_value: "nordea",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/nordea.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Handelsbanken",
        selected_value: "handelsbanken",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img:
          "https://www.vismapay.com/e-payments/method_logos/handelsbanken.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Osuuspankki",
        selected_value: "osuuspankki",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/osuuspankki.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Danskebank",
        selected_value: "danskebank",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/danskebank.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Paikallisosuuspankki",
        selected_value: "paikallisosuuspankki",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img:
          "https://www.vismapay.com/e-payments/method_logos/paikallisosuuspankki.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Säästöpankki",
        selected_value: "saastopankki",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img:
          "https://www.vismapay.com/e-payments/method_logos/saastopankki.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Oma Säästöpankki",
        selected_value: "omasaastopankki",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img:
          "https://www.vismapay.com/e-payments/method_logos/omasaastopankki.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Aktia",
        selected_value: "aktia",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/aktia.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Ålandsbanken",
        selected_value: "alandsbanken",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img:
          "https://www.vismapay.com/e-payments/method_logos/alandsbanken.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "S-Pankki",
        selected_value: "spankki",
        group: "banks",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/spankki.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Visa",
        selected_value: "creditcards",
        group: "creditcards",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/visa.png",
        img_timestamp: 1629195202,
        currency: [
          "AED",
          "AFN",
          "ALL",
          "AMD",
          "ANG",
          "ARS",
          "AUD",
          "AWG",
          "AZN",
          "BAM",
          "BBD",
          "BDT",
          "BGN",
          "BMD",
          "BND",
          "BOB",
          "BRL",
          "BSD",
          "BWP",
          "BZD",
          "CAD",
          "CDF",
          "CHF",
          "CNY",
          "COP",
          "CRC",
          "CUP",
          "CVE",
          "CZK",
          "DKK",
          "DOP",
          "DZD",
          "EGP",
          "ERN",
          "ETB",
          "EUR",
          "FJD",
          "GBP",
          "GEL",
          "GHS",
          "GIP",
          "GMD",
          "GTQ",
          "GYD",
          "HKD",
          "HNL",
          "HRK",
          "HTG",
          "HUF",
          "IDR",
          "ILS",
          "INR",
          "IRR",
          "ISK",
          "JMD",
          "KES",
          "KHR",
          "KZT",
          "LAK",
          "LBP",
          "LKR",
          "LRD",
          "MAD",
          "MDL",
          "MGA",
          "MKD",
          "MNT",
          "MOP",
          "MRO",
          "MUR",
          "MVR",
          "MWK",
          "MXN",
          "MYR",
          "MZN",
          "NAD",
          "NGN",
          "NIO",
          "NOK",
          "NPR",
          "NZD",
          "PAB",
          "PEN",
          "PGK",
          "PHP",
          "PKR",
          "PLN",
          "QAR",
          "RON",
          "RSD",
          "RUB",
          "SAR",
          "SBD",
          "SCR",
          "SEK",
          "SGD",
          "SLL",
          "SOS",
          "STD",
          "SVC",
          "SZL",
          "THB",
          "TOP",
          "TRY",
          "TTD",
          "TWD",
          "TZS",
          "UAH",
          "USD",
          "UYU",
          "UZS",
          "WST",
          "XCD",
          "YER",
          "ZAR",
          "ZMW",
        ],
      },
      {
        name: "Mastercard",
        selected_value: "creditcards",
        group: "creditcards",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/mastercard.png",
        img_timestamp: 1629195202,
        currency: [
          "AED",
          "AFN",
          "ALL",
          "AMD",
          "ANG",
          "ARS",
          "AUD",
          "AWG",
          "AZN",
          "BAM",
          "BBD",
          "BDT",
          "BGN",
          "BMD",
          "BND",
          "BOB",
          "BRL",
          "BSD",
          "BWP",
          "BZD",
          "CAD",
          "CDF",
          "CHF",
          "CNY",
          "COP",
          "CRC",
          "CUP",
          "CVE",
          "CZK",
          "DKK",
          "DOP",
          "DZD",
          "EGP",
          "ERN",
          "ETB",
          "EUR",
          "FJD",
          "GBP",
          "GEL",
          "GHS",
          "GIP",
          "GMD",
          "GTQ",
          "GYD",
          "HKD",
          "HNL",
          "HRK",
          "HTG",
          "HUF",
          "IDR",
          "ILS",
          "INR",
          "IRR",
          "ISK",
          "JMD",
          "KES",
          "KHR",
          "KZT",
          "LAK",
          "LBP",
          "LKR",
          "LRD",
          "MAD",
          "MDL",
          "MGA",
          "MKD",
          "MNT",
          "MOP",
          "MRO",
          "MUR",
          "MVR",
          "MWK",
          "MXN",
          "MYR",
          "MZN",
          "NAD",
          "NGN",
          "NIO",
          "NOK",
          "NPR",
          "NZD",
          "PAB",
          "PEN",
          "PGK",
          "PHP",
          "PKR",
          "PLN",
          "QAR",
          "RON",
          "RSD",
          "RUB",
          "SAR",
          "SBD",
          "SCR",
          "SEK",
          "SGD",
          "SLL",
          "SOS",
          "STD",
          "SVC",
          "SZL",
          "THB",
          "TOP",
          "TRY",
          "TTD",
          "TWD",
          "TZS",
          "UAH",
          "USD",
          "UYU",
          "UZS",
          "WST",
          "XCD",
          "YER",
          "ZAR",
          "ZMW",
        ],
      },
      {
        name: "Jousto",
        selected_value: "joustoraha",
        group: "creditinvoices",
        min_amount: 2000,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/joustoraha.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Enterpay-yrityslasku",
        selected_value: "laskuyritykselle",
        group: "creditinvoices",
        min_amount: 1,
        max_amount: 200000,
        img:
          "https://www.vismapay.com/e-payments/method_logos/laskuyritykselle.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Mobilepay",
        selected_value: "mobilepay",
        group: "wallets",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/mobilepay.png",
        img_timestamp: 1629195202,
        currency: ["EUR", "DKK", "SEK", "NOK"],
      },
      {
        name: "Masterpass",
        selected_value: "masterpass",
        group: "wallets",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/masterpass.png",
        img_timestamp: 1629195202,
        currency: [
          "AED",
          "AFN",
          "ALL",
          "AMD",
          "ANG",
          "ARS",
          "AUD",
          "AWG",
          "AZN",
          "BAM",
          "BBD",
          "BDT",
          "BGN",
          "BMD",
          "BND",
          "BOB",
          "BRL",
          "BSD",
          "BWP",
          "BZD",
          "CAD",
          "CDF",
          "CHF",
          "CNY",
          "COP",
          "CRC",
          "CUP",
          "CVE",
          "CZK",
          "DKK",
          "DOP",
          "DZD",
          "EGP",
          "ERN",
          "ETB",
          "EUR",
          "FJD",
          "GBP",
          "GEL",
          "GHS",
          "GIP",
          "GMD",
          "GTQ",
          "GYD",
          "HKD",
          "HNL",
          "HRK",
          "HTG",
          "HUF",
          "IDR",
          "ILS",
          "INR",
          "IRR",
          "ISK",
          "JMD",
          "KES",
          "KHR",
          "KZT",
          "LAK",
          "LBP",
          "LKR",
          "LRD",
          "MAD",
          "MDL",
          "MGA",
          "MKD",
          "MNT",
          "MOP",
          "MRO",
          "MUR",
          "MVR",
          "MWK",
          "MXN",
          "MYR",
          "MZN",
          "NAD",
          "NGN",
          "NIO",
          "NOK",
          "NPR",
          "NZD",
          "PAB",
          "PEN",
          "PGK",
          "PHP",
          "PKR",
          "PLN",
          "QAR",
          "RON",
          "RSD",
          "RUB",
          "SAR",
          "SBD",
          "SCR",
          "SEK",
          "SGD",
          "SLL",
          "SOS",
          "STD",
          "SVC",
          "SZL",
          "THB",
          "TOP",
          "TRY",
          "TTD",
          "TWD",
          "TZS",
          "UAH",
          "USD",
          "UYU",
          "UZS",
          "WST",
          "XCD",
          "YER",
          "ZAR",
          "ZMW",
        ],
      },
      {
        name: "Pivo",
        selected_value: "pivo",
        group: "wallets",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/pivo.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Siirto",
        selected_value: "siirto",
        group: "wallets",
        min_amount: 1,
        max_amount: 200000,
        img: "https://www.vismapay.com/e-payments/method_logos/siirto.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
    ];
  } else {
    return [
      {
        name: "Nordea",
        selected_value: "nordea",
        group: "banks",
        min_amount: 1,
        max_amount: 20000000,
        img: "https://www.vismapay.com/e-payments/method_logos/nordea.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Osuuspankki",
        selected_value: "osuuspankki",
        group: "banks",
        min_amount: 1,
        max_amount: 20000000,
        img: "https://www.vismapay.com/e-payments/method_logos/osuuspankki.png",
        img_timestamp: 1629195202,
        currency: ["EUR"],
      },
      {
        name: "Visa",
        selected_value: "creditcards",
        group: "creditcards",
        min_amount: 1,
        max_amount: 20000000,
        img: "https://www.vismapay.com/e-payments/method_logos/visa.png",
        img_timestamp: 1629195202,
        currency: [
          "AED",
          "AFN",
          "ALL",
          "AMD",
          "ANG",
          "ARS",
          "AUD",
          "AWG",
          "AZN",
          "BAM",
          "BBD",
          "BDT",
          "BGN",
          "BMD",
          "BND",
          "BOB",
          "BRL",
          "BSD",
          "BWP",
          "BZD",
          "CAD",
          "CDF",
          "CHF",
          "CNY",
          "COP",
          "CRC",
          "CUP",
          "CVE",
          "CZK",
          "DKK",
          "DOP",
          "DZD",
          "EGP",
          "ERN",
          "ETB",
          "EUR",
          "FJD",
          "GBP",
          "GEL",
          "GHS",
          "GIP",
          "GMD",
          "GTQ",
          "GYD",
          "HKD",
          "HNL",
          "HRK",
          "HTG",
          "HUF",
          "IDR",
          "ILS",
          "INR",
          "IRR",
          "ISK",
          "JMD",
          "KES",
          "KHR",
          "KZT",
          "LAK",
          "LBP",
          "LKR",
          "LRD",
          "MAD",
          "MDL",
          "MGA",
          "MKD",
          "MNT",
          "MOP",
          "MRO",
          "MUR",
          "MVR",
          "MWK",
          "MXN",
          "MYR",
          "MZN",
          "NAD",
          "NGN",
          "NIO",
          "NOK",
          "NPR",
          "NZD",
          "PAB",
          "PEN",
          "PGK",
          "PHP",
          "PKR",
          "PLN",
          "QAR",
          "RON",
          "RSD",
          "RUB",
          "SAR",
          "SBD",
          "SCR",
          "SEK",
          "SGD",
          "SLL",
          "SOS",
          "STD",
          "SVC",
          "SZL",
          "THB",
          "TOP",
          "TRY",
          "TTD",
          "TWD",
          "TZS",
          "UAH",
          "USD",
          "UYU",
          "UZS",
          "WST",
          "XCD",
          "YER",
          "ZAR",
          "ZMW",
        ],
      },
      {
        name: "Mastercard",
        selected_value: "creditcards",
        group: "creditcards",
        min_amount: 1,
        max_amount: 20000000,
        img: "https://www.vismapay.com/e-payments/method_logos/mastercard.png",
        img_timestamp: 1629195202,
        currency: [
          "AED",
          "AFN",
          "ALL",
          "AMD",
          "ANG",
          "ARS",
          "AUD",
          "AWG",
          "AZN",
          "BAM",
          "BBD",
          "BDT",
          "BGN",
          "BMD",
          "BND",
          "BOB",
          "BRL",
          "BSD",
          "BWP",
          "BZD",
          "CAD",
          "CDF",
          "CHF",
          "CNY",
          "COP",
          "CRC",
          "CUP",
          "CVE",
          "CZK",
          "DKK",
          "DOP",
          "DZD",
          "EGP",
          "ERN",
          "ETB",
          "EUR",
          "FJD",
          "GBP",
          "GEL",
          "GHS",
          "GIP",
          "GMD",
          "GTQ",
          "GYD",
          "HKD",
          "HNL",
          "HRK",
          "HTG",
          "HUF",
          "IDR",
          "ILS",
          "INR",
          "IRR",
          "ISK",
          "JMD",
          "KES",
          "KHR",
          "KZT",
          "LAK",
          "LBP",
          "LKR",
          "LRD",
          "MAD",
          "MDL",
          "MGA",
          "MKD",
          "MNT",
          "MOP",
          "MRO",
          "MUR",
          "MVR",
          "MWK",
          "MXN",
          "MYR",
          "MZN",
          "NAD",
          "NGN",
          "NIO",
          "NOK",
          "NPR",
          "NZD",
          "PAB",
          "PEN",
          "PGK",
          "PHP",
          "PKR",
          "PLN",
          "QAR",
          "RON",
          "RSD",
          "RUB",
          "SAR",
          "SBD",
          "SCR",
          "SEK",
          "SGD",
          "SLL",
          "SOS",
          "STD",
          "SVC",
          "SZL",
          "THB",
          "TOP",
          "TRY",
          "TTD",
          "TWD",
          "TZS",
          "UAH",
          "USD",
          "UYU",
          "UZS",
          "WST",
          "XCD",
          "YER",
          "ZAR",
          "ZMW",
        ],
      },
    ];
  }
}
