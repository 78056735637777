import { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Divider,
} from "@material-ui/core";

import { eachDayOfInterval, getDate, startOfMonth, endOfMonth } from "date-fns";
import TableComponent from "./TableComponent";
export default function ProductSummaryTable({ requestedStartTime, results }) {
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedReturnDate, setSelectedReturnDate] = useState();
  const [listOfProducts, setListofProducts] = useState([]);
  const [deleteItem, setDeleteItem] = useState();

  const daysOfMonth = useMemo(() => {
    return eachDayOfInterval({
      start: startOfMonth(requestedStartTime),
      end: endOfMonth(requestedStartTime),
    });
  }, [requestedStartTime]);

  useEffect(() => {
    setListofProducts(results);
  }, [results]);

  useEffect(() => {
    if (deleteItem) {
      let filteredList = listOfProducts
        .filter((item) => item.vehicle.id !== deleteItem)
        .map((r) => {
          return r;
        });
      setListofProducts([]);
      setListofProducts(filteredList);
    }
    setDeleteItem();
  }, [deleteItem]);

  return (
    <TableContainer>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell />
            {daysOfMonth.map((date, index) => {
              return (
                <TableCell style={{ padding: 4 }} align="center" key={index}>
                  <Typography variant="body1" style={{ fontWeight: "bolder" }}>
                    {getDate(date)}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {listOfProducts.map((r, index) => {
            return (
              <TableRow
                key={r.vehicle.id + index}
                style={{
                  borderWidth: 0,
                }}
              >
                <TableComponent
                  reservation={r}
                  requestedStartTime={requestedStartTime}
                  setSelectedStartDate={setSelectedStartDate}
                  selectedStartDate={selectedStartDate}
                  selectedReturnDate={selectedReturnDate}
                  setSelectedReturnDate={setSelectedReturnDate}
                  setDeleteItem={setDeleteItem}
                />
              </TableRow>
            );
          })}

          <TableRow />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
