import { useMemo, useEffect, useState } from "react";
import { IconButton, Typography, TableCell } from "@material-ui/core";
import {
  areIntervalsOverlapping,
  eachDayOfInterval,
  format,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  isSameDay,
  getDay,
  isAfter,
  getHours,
  getMinutes,
} from "date-fns";
import { fi } from "date-fns/locale";
import { API, graphqlOperation, Storage } from "aws-amplify";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import DeleteIcon from "@material-ui/icons/Delete";
import { Delete } from "@material-ui/icons";

const Day = ({
  date,
  status,
  vehicleTimes,
  selectedStartDate,
  selectedReturnDate,
  reservation,
}) => {
  // TODO: Possible indicators for days that reservation can be started from,
  // different styling for start and end days after those statuses have been added
  let backgroundColor = "#528d36";
  let borderColor = "transparent";
  let fontColor = "black";
  let startTime = null;
  let showVehicleTimes = false;
  const [startDay, setstartDay] = useState(false);
  const [returnDay, setReturnDay] = useState(false);

  useEffect(() => {
    if (!selectedStartDate) {
      setstartDay(false);
    } else {
      if (isSameDay(selectedStartDate.date, date)) {
        if (reservation.vehicle.id === selectedStartDate.id) {
          setstartDay(true);
        }
      }
    }
    if (!selectedReturnDate) {
      setReturnDay(false);
    }
    if (selectedReturnDate) {
      if (isSameDay(selectedReturnDate.date, date)) {
        if (selectedStartDate.id === selectedReturnDate.id) {
          if (reservation.vehicle.id === selectedReturnDate.id) {
            setReturnDay(true);
          }
        } else {
          setReturnDay(false);
        }
      }
    }
  }, [selectedStartDate, selectedReturnDate]);

  if (status.status === "RESERVED") {
    backgroundColor = "darkred";
    if (status.isReturnDay && status.vehicleTimes.length) {
      // See if it is possible to start a new reservation on the end day of existing reservation
      if (status.isReturnDay) {
        const endDate = status.interval.end;
        const vehicleStartTimes = vehicleTimes[getDay(endDate)].start;
        if (vehicleStartTimes[0]) {
          for (let time of vehicleStartTimes) {
            if (time) {
              const pieces = time.split(":");
              const vehicleStartDate = new Date(endDate.getTime());
              vehicleStartDate.setHours(pieces[0], pieces[1]);
              if (vehicleStartDate > endDate) {
                // For now just use slighty different shade. Might just as well be the same as other FREE days
                backgroundColor = "#3b6825";
              }
            }
          }
        }
      }
    }
    // See if it is possible to end a reservation on the start day of existing reservation
    if (status.isStartDay && status.vehicleTimes.length) {
      const startDate = status.interval.start;
      const vehicleEndTimes = vehicleTimes[getDay(startDate)].return;
      if (vehicleEndTimes[0]) {
        for (let time of vehicleEndTimes) {
          if (time) {
            const pieces = time.split(":");
            const vehicleEndDate = new Date(startDate.getTime());
            vehicleEndDate.setHours(pieces[0], pieces[1]);
            if (vehicleEndDate < startDate) {
              // For now just use slighty different shade. Might just as well be the same as other FREE days
              backgroundColor = "#3b6825";
            }
          }
        }
      }
    }

    fontColor = "white";
  }
  if (status.status === "FREE") {
  }

  if (vehicleTimes.length > 0) {
    showVehicleTimes = true;
    vehicleTimes.map((day, index) => {
      if (index === getDay(new Date(date))) {
        startTime = day.start[0];
      }
    });
  }

  if (status.status === "FREE" && startTime == false) {
    fontColor = "#b8b8b8";
  }

  if (startDay) {
    backgroundColor = "#182a58";
  }
  if (returnDay) {
    backgroundColor = "#182a58";
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        minHeight: 70,
        marginRight: 1,
      }}
    >
      <div
        style={{
          backgroundColor: backgroundColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minWidth: 31,
          height: 25,
          borderRadius: 3,
          color: "white",
          boxShadow: "1px 1px 1px 0px rgba(0,0,0,0.2)",
          WebkitBoxShadow: "1px 1px 1px 0px rgba(0,0,0,0.2)",
        }}
      />
    </div>
  );
};

export default function TableComponent({
  reservation,
  requestedStartTime,
  setSelectedStartDate,
  setSelectedReturnDate,
  selectedStartDate,
  selectedReturnDate,
  setDeleteItem,
}) {
  const capitalize = (str) => {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  };
  const currentMonthString = capitalize(
    format(requestedStartTime, "LLLL", { locale: fi })
  );

  const daysOfMonth = useMemo(() => {
    return eachDayOfInterval({
      start: startOfMonth(requestedStartTime),
      end: endOfMonth(requestedStartTime),
    });
  }, [requestedStartTime]);

  // TODO: Add statuses for beginning and ending.
  const reservationStatuses = useMemo(() => {
    let isStartDay = false;
    let isReturnDay = false;
    let vehicleTimes = [];

    if (reservation.useVehicleTimes === true) {
      vehicleTimes = reservation.vehicle.startReturnTimes;
    }
    return daysOfMonth.map((d) => {
      const dayInterval = { start: startOfDay(d), end: endOfDay(d) };

      for (const reserved of reservation.reservedTimes) {
        const reservedInterval = {
          start: new Date(reserved.start),
          end: new Date(reserved.end),
        };

        isStartDay = isSameDay(reservedInterval.start, dayInterval.start);
        isReturnDay = isSameDay(reservedInterval.end, dayInterval.end);

        if (areIntervalsOverlapping(dayInterval, reservedInterval)) {
          return {
            status: "RESERVED",
            interval: reservedInterval,
            isStartDay: isStartDay,
            isReturnDay: isReturnDay,
            vehicleTimes: vehicleTimes,
          };
        }
      }

      return {
        status: "FREE",
        isStartDay: isStartDay,
        isReturnDay: isReturnDay,
        vehicleTimes: vehicleTimes,
      };
    });
  }, [daysOfMonth, reservation.reservedTimes]);

  // Check if the current day is in reservations
  const getStatus = (day) => {
    return reservationStatuses[day];
  };
  const checkStartingDay = (day) => {
    return reservationStatuses[day].isStartDay;
  };
  const checkReturnDay = (day) => {
    return reservationStatuses[day].isReturnDay;
  };

  const checkVehicleTimes = (d) => {
    if (d[0].vehicleTimes) {
      return d[0].vehicleTimes;
    }
  };

  const [signedUrl, setSignedUrl] = useState(null);
  const getSignedUrl = async (value) => {
    if (value) {
      const [identityId, key] = value.split("/");
      setSignedUrl(await Storage.get(key, { identityId, level: "protected" }));
    }
  };
  useEffect(() => {
    getSignedUrl(reservation.vehicle.image);
  }, [reservation]);

  const handleCellClick = (date) => {
    const reservationStatus = getStatus(date.getDate() - 1);

    if (selectedStartDate) {
      if (
        isAfter(date, selectedStartDate.date) &&
        reservationStatus === "FREE"
      ) {
        setSelectedReturnDate({ date: date, id: reservation.vehicle.id });
      }
    }
    if (!selectedStartDate && reservationStatus === "FREE") {
      setSelectedStartDate({ date: date, id: reservation.vehicle.id });
      setSelectedReturnDate();
    } else {
      if (selectedStartDate && isSameDay(date, selectedStartDate.date)) {
        setSelectedStartDate();
        setSelectedReturnDate();
      }
      if (selectedReturnDate) {
        setSelectedStartDate();
        setSelectedReturnDate();
      }
    }
  };
  const handleDeleteItem = (id) => {
    setDeleteItem(id);
  };

  return (
    <>
      <TableCell
        style={{
          position: "sticky",
          left: 0,
          padding: 0,
          paddingLeft: 10,

          borderWidth: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            position: "sticky",
            width: 65,
            flexDirection: "column",
            position: "relative",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            left: 0,
            marginBottom: 2,
            textAlign: "start",
          }}
        >
          <img
            style={{
              width: "60px",
              height: "60px",
              padding: 2,
              objectFit: "contain",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#c4c4c4",
              borderRadius: "10%",
              backgroundColor: "white",
            }}
            src={signedUrl}
            alt=""
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
              overflow: "visible",
              left: 70,
              bottom: 22,
              width: 700,
            }}
          >
            {reservation.vehicle.registrationPlate && (
              <Typography
                variant="body1"
                style={{
                  fontWeight: 600,
                  letterSpacing: "0.05em",
                  marginRight: 5,
                }}
              >
                {reservation.vehicle.registrationPlate}
              </Typography>
            )}
            <Typography variant="body1">{reservation.vehicle.name}</Typography>
          </div>
        </div>
      </TableCell>
      {daysOfMonth.map((date, index) => {
        return (
          <TableCell
            key={index}
            align="center"
            style={{
              padding: 1,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "white",
              borderRadius: 2,
            }}
          >
            <Day
              key={index}
              date={date}
              status={getStatus(date.getDate() - 1)}
              isStartDay={checkStartingDay(date.getDate() - 1)}
              isReturnDay={checkReturnDay(date.getDate() - 1)}
              vehicleTimes={checkVehicleTimes(reservationStatuses)}
              selectedStartDate={selectedStartDate}
              selectedReturnDate={selectedReturnDate}
              reservation={reservation}
            />
          </TableCell>
        );
      })}
    </>
  );
}
