import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  makeStyles,
  CircularProgress,
  Badge,
  useTheme,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useHistory } from "react-router-dom";
import { CreditCard, Work } from "@material-ui/icons";
import { centsToLocalString } from "../shared/money";
import AdditionalFieldsDialog from "./AdditionalFieldsDialog";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { Alert, Skeleton } from "@material-ui/lab";
import { differenceInMinutes, isSameDay } from "date-fns";
import { FormattedDate, FormattedTime } from "./common";
import { getPriceByVehicleIds } from "../graphql/queries";
import ResultTimeSelector from "./ResultTimeSelector";
import { captureError } from "../utilities";
import MonthlyReservations from "./MonthlyReservations";

const useStyles = makeStyles((theme) => ({
  resultCard: {
    boxShadow: theme.shadows[3],
  },
  infoContainer: {
    textAlign: "center",
  },
  additionalFieldsLabel: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

function InfoLabel(props) {
  return <Typography variant="caption" {...props} />;
}

export function RescheduledTime({ date, requestedDate }) {
  return (
    <>
      <FormattedDate date={date} /> <FormattedTime date={date} />
    </>
  );
}

export default function ResultCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [signedUrl, setSignedUrl] = useState(null);
  const [startTime, setStartTime] = useState(props.reservation.startTime);
  const [returnTime, setReturnTime] = useState(props.reservation.returnTime);
  const [additionalServices, setAdditionalServices] = useState(
    props.reservation.additionalServiceOffers
  );
  const [offerPrice, setOfferPrice] = useState(
    props.reservation.vehicleOfferPrice
  ); // offerPrice can change if !timeMatches
  const [priceLoading, setPriceLoading] = useState(false);
  const [inCart, setInCart] = useState(false);
  // Don't allow reserving a Vehicle if group or company doesn't match with items in cart
  const [companyNotEqualToCart, setCompanyNotEqualToCart] = useState(false);

  const additionalFields = props.reservation.vehicle.additionalFields;
  const knownFields = {
    passengers: additionalFields.find((f) => f.key === "passengers"),
    requiredLicense: additionalFields.find((f) => f.key === "requiredLicense"),
    volume: additionalFields.find((f) => f.key === "volume"),
  };
  // knownFields are being displayed on the result card
  const _knownFieldNames = Object.keys(knownFields);
  const customFields = additionalFields.filter(
    (f) => !_knownFieldNames.includes(f.key)
  );

  const getSignedUrl = async (value) => {
    if (value) {
      const [identityId, key] = value.split("/");
      setSignedUrl(await Storage.get(key, { identityId, level: "protected" }));
    }
  };
  const timeMatches = useMemo(() => {
    return (
      props.reservation.startTime.getTime() ===
      props.requestedStartTime.getTime()
    );
  }, [props.reservation, props.requestedStartTime]);

  const showReserveButton = useMemo(() => {
    return (
      !props.enableCart ||
      (props.enableCart && inCart) ||
      (!timeMatches && props.cart.length === 0)
    );
  }, [props.enableCart, inCart]);

  const diffString = useMemo(() => {
    const diff = differenceInMinutes(
      props.reservation.returnTime,
      props.reservation.startTime
    );
    const h = Math.floor(diff / 60);
    const m = Math.floor(diff - h * 60);

    let str = h + "h";

    if (m) {
      str += " " + m + "min";
    }
    return str;
  }, [props.reservation]);

  const fetchPrice = useCallback(
    async (start, end) => {
      if (start >= end) return;
      setPriceLoading(true);
      try {
        const response = await API.graphql(
          graphqlOperation(getPriceByVehicleIds, {
            vehicleIds: [props.reservation.vehicle.id],
            startTime: start.toJSON(),
            returnTime: end.toJSON(),
            companyId: props.reservation.vehicle.companyId,
          })
        );
        const result = response.data.getPriceByVehicleIds;

        setOfferPrice(result.vehicleOfferPrices[0].price);
        setAdditionalServices(result.additionalServiceOffers);
      } catch (e) {
        captureError("Get reservation price failed", "GET_PRICE_FAILED", e);
      }

      setPriceLoading(false);
    },
    [props.reservation.vehicle]
  );

  const onTimeChange = useCallback(
    (start, end) => {
      if (start) {
        setStartTime(start);
      }
      if (end) {
        setReturnTime(end);
      }

      fetchPrice(start || startTime, end || returnTime);
    },
    [fetchPrice, startTime, returnTime]
  );

  useEffect(() => {
    getSignedUrl(props.reservation.vehicle.image);
  }, [props.reservation.vehicle.image]);

  useEffect(() => {
    setInCart(
      props.cart?.find((r) => r.vehicle.id === props.reservation?.vehicle?.id)
    );
    setCompanyNotEqualToCart(
      props.cart?.some(
        (r) => r.vehicle.companyId !== props.reservation?.vehicle?.companyId
      )
    );
  }, [props.cart, props.reservation?.vehicle]);

  const goToReservation = (reservation) => {
    // Sync cart and given reservation, if not yet in cart
    let cart = props.cart;
    if (!inCart) {
      cart = [...cart, { ...reservation, quantity: 1 }];
    }
    let allServices = [];
    const vehicles = cart.map((r) => {
      // Put each unique service on vehicles in cart  into allServices
      for (let offerService of r.additionalServiceOffers) {
        const service = allServices.find((s) => s.key === offerService.key);
        if (!service) {
          allServices.push(offerService);
          continue;
        }
        // If many prices for same service, use cheapest one
        if (offerService.offerPrice < service.offerPrice) {
          allServices = allServices.map((s) =>
            s.key === offerService.key ? offerService : s
          );
        }
      }
      const newServices = r.additionalServiceOffers.filter(
        (s1) => !allServices.some((s2) => s2.key === s1.key)
      );
      allServices.push.apply(allServices, newServices);
      // Map individual vehicles
      return {
        quantity: r.quantity,
        offerPrice: r.vehicleOfferPrice,
        ...r.vehicle,
      };
    });

    // All items in cart share same attributes for reservations, so we can use attributes of first in cart
    // (besides vehicle and freeTimes, freeTimes are not used on /reservation)
    const r = {
      reservation: {
        ...cart[0],
        reservationVehicles: vehicles,
        additionalServiceOffers: allServices,
        group: vehicles[0].group,
      },
    };
    history.push("/reservation", r);
  };
  // TODO: Remove when disableOffHourReturn feature fully done, and resultCard supports its fully
  if (!timeMatches && props.disableOffHourReturn) {
    return null;
  }
  return (
    <Card className={classes.resultCard}>
      {!timeMatches ? (
        <Alert severity="info">
          Tämän tuotteen lähin aloitusaika{" "}
          <strong>{props.reservation.useVehicleTimes ? "" : diffString}</strong>{" "}
          varaukselle:{" "}
          <strong>
            <RescheduledTime
              date={props.reservation.startTime}
              requestedDate={props.requestedStartTime}
            />
          </strong>
        </Alert>
      ) : null}

      <CardMedia
        component={() => (
          <div>
            <img
              style={{
                height: "150px",
                width: "100%",
                objectFit: "contain",
              }}
              src={signedUrl}
              alt=""
            />
          </div>
        )}
        height="140"
      />

      <CardContent>
        <Grid container justify="center" className={classes.infoContainer}>
          {props.reservation.availableInCategory && (
            <Grid item xs={12}>
              <Typography>
                Vapaana {props.reservation.availableInCategory} kappaletta
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography>{props.reservation.vehicle.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" component="span" noWrap>
              {priceLoading ? (
                <CircularProgress size="1em" />
              ) : (
                <span>{centsToLocalString(offerPrice)} €</span>
              )}
            </Typography>
          </Grid>

          {knownFields.passengers ? (
            <Grid item xs={4}>
              <InfoLabel>
                <PersonIcon style={{ verticalAlign: "middle" }} />{" "}
                {knownFields.passengers.value} henkeä
              </InfoLabel>
            </Grid>
          ) : null}

          {knownFields.requiredLicense ? (
            <Grid item xs={4}>
              <InfoLabel>
                <CreditCard style={{ verticalAlign: "middle" }} />{" "}
                {knownFields.requiredLicense.value}-kortti
              </InfoLabel>
            </Grid>
          ) : null}

          {knownFields.volume ? (
            <Grid item xs={4}>
              <InfoLabel>
                <Work style={{ verticalAlign: "middle" }} /> n.{" "}
                {knownFields.volume.value} m<sup>3</sup>
              </InfoLabel>
            </Grid>
          ) : null}
          {!timeMatches && (
            <ResultTimeSelector
              freeTimes={props.reservation.freeTimes}
              startTime={startTime}
              returnTime={returnTime}
              requestedStartTime={props.requestedStartTime}
              suggestedStartTime={props.reservation.startTime}
              suggestedReturnTime={props.reservation.returnTime}
              onChange={onTimeChange}
              useVehicleTimes={props.reservation.useVehicleTimes}
            />
          )}

          {showAdditionalFields ? (
            <AdditionalFieldsDialog
              onClose={() => setShowAdditionalFields(false)}
              fields={customFields}
            />
          ) : null}
        </Grid>
        {props.enableMonthlyView && (
          <Grid container justify="center" style={{ marginTop: 5 }}>
            <MonthlyReservations
              reservation={props.reservation}
              requestedStartTime={props.requestedStartTime}
            />
          </Grid>
        )}
      </CardContent>
      <CardActions>
        <Grid container justify="space-between">
          <Grid item>
            {customFields.length > 0 ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowAdditionalFields(true)}
              >
                <InfoOutlinedIcon />
                <span className={classes.additionalFieldsLabel}>
                  &nbsp;Lisätietoja
                </span>
              </Button>
            ) : null}
          </Grid>
          <Grid item style={{ display: "flex" }}>
            {timeMatches && props.enableCart && (
              <CartHandler
                disabled={priceLoading || companyNotEqualToCart}
                reservation={props.reservation}
                setCart={props.setCart}
                cart={props.cart}
                inCart={inCart}
              />
            )}
            {showReserveButton && (
              <Button
                onClick={() => {
                  const reservation = {
                    ...props.reservation,
                    startTime,
                    returnTime,
                    additionalServiceOffers: additionalServices,
                    vehicleOfferPrice: offerPrice, // if !timeMatches price changes on duration change are reflected to offerPrice
                  };
                  goToReservation(reservation);
                }}
                disabled={
                  priceLoading ||
                  companyNotEqualToCart ||
                  (props.cart.length > 0 && !timeMatches)
                }
                variant="contained"
                color="primary"
              >
                Varaamaan
              </Button>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

function RemoveFromCart(props) {
  const currentCart = useMemo(() => {
    return props.cart.find(
      (r) => r.vehicle.id === props.reservation.vehicle.id
    );
  }, [props]);
  const enableQuantity =
    props.inCart && props.reservation.availableInCategory > 1;
  const removeFromCart = () => {
    props.setCart((prevCart) =>
      prevCart.filter((x) => x.vehicle.id !== props.reservation.vehicle.id)
    );
  };
  const changeQuantity = (changeBy) => {
    const newQuantity = currentCart.quantity + changeBy;
    if (newQuantity < 1) {
      removeFromCart();
    } else if (newQuantity > currentCart.availableInCategory) {
      return;
    } else {
      props.setCart((prevCart) =>
        prevCart.map((r) => {
          return r.vehicle.id === props.reservation.vehicle.id
            ? { ...r, quantity: newQuantity }
            : r;
        })
      );
    }
  };
  return (
    <>
      {enableQuantity && (
        <RemoveCircleIcon onClick={() => changeQuantity(-1)} color="primary" />
      )}
      <Button
        onClick={() => {
          removeFromCart(props.reservation.vehicle.id);
        }}
        disabled={props.disabled}
        variant="outlined"
        color="inherit"
      >
        <Badge badgeContent={currentCart?.quantity} color="primary">
          <RemoveShoppingCartIcon />
        </Badge>
      </Button>
      {enableQuantity && (
        <AddCircleIcon onClick={() => changeQuantity(1)} color="primary" />
      )}
    </>
  );
}

function AddToCart(props) {
  return (
    <Button
      onClick={() => {
        props.setCart((prevCart) => [
          ...prevCart,
          { ...props.reservation, quantity: 1 },
        ]);
      }}
      disabled={props.disabled}
      variant="contained"
      color="primary"
      className={props.classes.cartBtn}
    >
      <ShoppingCartIcon />
    </Button>
  );
}

function CartHandler(props) {
  const theme = useTheme();
  window.theme = theme;
  const useStyles = makeStyles((theme) => ({
    cartBtn: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(1),
      "& button": {
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5),
      },
      "& svg": {
        cursor: "pointer",
      },
    },
    removeFromCartBtn: {
      color: theme.palette.primary.dark,
    },
  }));
  const classes = useStyles();

  if (props.inCart) {
    return (
      <div className={classes.cartBtn + " " + classes.removeFromCartBtn}>
        <RemoveFromCart {...props} />
      </div>
    );
  }
  return <AddToCart classes={classes} {...props} />;
}

export function ResultSkeleton() {
  return (
    <Card style={{ textAlign: "center" }}>
      <Skeleton height={150} style={{ transform: "none" }} />
      <CardContent>
        <Grid container justify="center" alignItems="center">
          <Grid container item xs={6} justify="center">
            <Typography variant="h5" component="span">
              <Skeleton width={160} />
            </Typography>
          </Grid>
          <Grid container item xs={6} justify="center">
            <Typography variant="h4" component="span">
              <Skeleton width={60} />
            </Typography>
          </Grid>
          <Grid container item xs={6} justify="center">
            <Typography variant="h6" component="span">
              <Skeleton width={140} />
            </Typography>
          </Grid>
          <Grid container item xs={6} justify="center">
            <Typography variant="h6" component="span">
              <Skeleton width={140} />
            </Typography>
          </Grid>
          <Grid container item xs={6} justify="center">
            <Typography variant="h6" component="span">
              <Skeleton width={140} />
            </Typography>
          </Grid>
          <Grid container item xs={6} justify="center">
            <Typography variant="h6" component="span">
              <Skeleton width={140} />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
