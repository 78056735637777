import {
  Button,
  Grid,
  Divider,
  FormControlLabel,
  Switch,
  Checkbox,
  TextField,
  Typography,
  makeStyles,
  Container,
  FormHelperText,
  FormControl,
  Card,
  CardContent,
  Snackbar,
  Link,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useEffect, useRef, useState, useMemo, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { validateVoucher, additionalServiceByGroup } from "../graphql/queries";
import { getCompany } from "../graphql/client-queries";
import { upsertReservationCustomer } from "../graphql/mutations";
import RiskSlider from "../components/RiskSlider";
import AlreadyReservedDialog from "../components/AlreadyReservedDialog";
import defaultAdditionalServices from "../shared/additional-services";
import { centsToLocalString } from "../shared/money";
import { calculateDiscountedPrice } from "../shared/pricing-utilities";
import { FormattedDate, FormattedTime } from "../components/common";
import { captureError } from "../utilities";
import {
  askSsn,
  disableCreditcheck,
  paymentEnabled,
  showPortInstruction,
  vismaPayMaxPayment,
} from "../features";
import * as Sentry from "@sentry/react";
import PaymentMethods from "../components/PaymentMethods";
import { authPayment } from "../graphql/client-queries";

// Also used at PaymentMethods
const PaymentModeEnum = Object.freeze({
  now: "PENDING",
  now_deposit: "PENDING_DEPOSIT",
  onPickup: "",
});

const INITIAL_FORM_DATA = {
  name: "",
  address: "",
  postalCode: "",
  city: "",
  phone: "",
  email: "",
  ssn: "",
  externalNotes: "",
  acceptTerms: false,
  acceptPortTerms: false,
  acceptSignTerms: false,
  acceptCreditCheck: false,
  differentDriver: false,
  driverName: "",
  driverAddress: "",
  driverPostalCode: "",
  driverCity: "",
  driverPhone: "",
  driverEmail: "",
  additionalDriver: "",
  additionalDriverName: "",
  additionalDriverPhone: "",
  isCompany: false,
  companyName: "",
  companyBusinessId: "",
  companyBillingRef: "",
  companyBillingAddress: "",
  paymentMethod: "",
  paymentMode: PaymentModeEnum.onPickup,
};

// Prefill fields in development
if (process.env.REACT_APP_ENV === "local") {
  INITIAL_FORM_DATA.name = "Erkki Esimerkki";
  INITIAL_FORM_DATA.address = "Testikatu 123";
  INITIAL_FORM_DATA.postalCode = "53850";
  INITIAL_FORM_DATA.city = "Lappeenranta";
  INITIAL_FORM_DATA.phone = "04412345123";
}

const doUpsertReservation = async (reservation, paymentMode) => {
  const response = await API.graphql(
    graphqlOperation(upsertReservationCustomer, {
      reservation: reservation,
      paymentMode: paymentMode,
    })
  );

  return response.data.upsertReservationCustomer;
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {},
  serviceContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    "&:not(:last-child)": {
      borderBottom: "1px solid lightgray",
    },
  },
  quantityInput: {
    maxWidth: "30px",
    height: "25px",
    marginTop: "3px",
    marginBottom: "3px",
    textAlign: "center",
  },
  qtyAdjust: {
    cursor: "pointer",
  },
  servicePriceContainer: {
    minWidth: "80px",
    display: "flex",
    textAlign: "right",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  vehicleInfoRoot: {
    boxShadow: theme.shadows[2],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > div": {
      padding: "5px",
    },
  },
  vehicleRow: {
    marginBottom: "5px",
  },
  imageContainer: {
    height: "90px",
  },
  vehicleInfoContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));

function AdditionalService({ formik, service }) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={formik.values[service.id] || false}
          id={service.id}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      }
      label={service.description}
    />
  );
}

function QuantitySelector({ service, formik, classes }) {
  const changeQuantity = (changeBy) => {
    const qty = formik.values[service.id + "Quantity"];
    const prevValue = qty ? qty : 1;
    formik.setFieldValue(service.id + "Quantity", prevValue + changeBy);
  };
  return (
    <>
      <RemoveCircleIcon
        className={classes.qtyAdjust}
        onClick={() => changeQuantity(-1)}
        color="primary"
      />
      <input
        className={classes.quantityInput}
        name={service.id + "Quantity"}
        label="Lukumäärä"
        size="small"
        autoFocus={true}
        value={formik.values[service.id + "Quantity"] || 1}
        disabled
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />
      <AddCircleIcon
        className={classes.qtyAdjust}
        onClick={() => changeQuantity(1)}
        color="primary"
      />
    </>
  );
}

// List alphabetically sorted services filtered by categories
function AdditionalServiceList({
  formik,
  categories,
  additionalServiceOffers,
  getQuantity,
}) {
  const classes = useStyles();
  return (
    <Grid item container>
      {additionalServiceOffers
        .filter((s) => categories.includes(s.category))
        .sort((s1, s2) => {
          if (categories.includes("distance")) {
            try {
              const r = /\d+/g;
              const num1 = parseInt(s1.description.match(r)[0]);
              const num2 = parseInt(s2.description.match(r)[0]);
              return num1 - num2;
            } catch (e) {
              // Handle non numerical eg. "Rajattomat kilometrit"
              return -1;
            }
          } else {
            return s1.description.localeCompare(s2.description);
          }
        })
        .map((service) => {
          return (
            <Grid
              item
              xs={12}
              key={service.id}
              className={classes.serviceContainer}
            >
              <div className={classes.serviceNameContainer}>
                <AdditionalService formik={formik} service={service} />
              </div>
              <div className={classes.servicePriceContainer}>
                {service.enableTextInput && formik.values[service.id] && (
                  <>
                    <TextField
                      name={service.id + "Details"}
                      label={service.inputLabel}
                      size="small"
                      autoFocus={true}
                      value={formik.values[service.id + "Details"] || ""}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[service.id + "Details"] &&
                        !!formik.errors[service.id + "Details"]
                      }
                      helperText={
                        formik.touched[service.id + "Details"] &&
                        formik.errors[service.id + "Details"]
                      }
                      required
                    />
                  </>
                )}
                {service.enableQuantity && formik.values[service.id] && (
                  <>
                    <QuantitySelector
                      service={service}
                      formik={formik}
                      classes={classes}
                    />
                  </>
                )}
                <Typography
                  variant="h6"
                  component="span"
                  style={{ marginLeft: "5px", minWidth: "80px" }}
                  noWrap
                >
                  {centsToLocalString(
                    //service.offerPrice
                    service.offerPrice * getQuantity(service)
                  )}{" "}
                  €
                </Typography>
              </div>
            </Grid>
          );
        })}
    </Grid>
  );
}
function VehicleInfoRow({ vehicle, signedUrl, selectedServices }) {
  const classes = useStyles();
  return (
    <Grid className={classes.vehicleRow}>
      <Card className={classes.vehicleInfoRoot}>
        <div className={classes.imageContainer}>
          <img
            style={{
              height: "100%",
              objectFit: "contain",
            }}
            src={signedUrl}
            alt={""}
          />
        </div>
        <div>
          <CardContent className={classes.vehicleInfoContent}>
            <Typography variant="body1" component="span">
              {vehicle.quantity > 1 && vehicle.quantity + "x "}
              {vehicle.name}
            </Typography>
            {vehicle?.includedDistance && (
              <>
                <Typography variant="body2" component="span">
                  {"Sisältyvät kilometrit: "}
                  <Typography variant="body2" noWrap component="span">
                    {selectedServices.findIndex(
                      (serviceOffer) => serviceOffer.category === "distance"
                    ) >= 0
                      ? selectedServices.find(
                          (serviceOffer) => serviceOffer.category === "distance"
                        ).description
                      : vehicle?.includedDistance}
                  </Typography>
                </Typography>
              </>
            )}
            <Typography variant="body1" component="span">
              {vehicle.quantity > 1 && (
                <Typography
                  style={{ marginRight: "10px" }}
                  variant="caption"
                  component="span"
                >
                  {vehicle.quantity +
                    "x " +
                    centsToLocalString(vehicle.offerPrice) +
                    "€"}
                </Typography>
              )}
              {centsToLocalString(vehicle.offerPrice * vehicle.quantity)} €
            </Typography>
          </CardContent>
        </div>
      </Card>
    </Grid>
  );
}

// Show basic info of selected reservation offer
function ReservationInfo({
  history,
  signedUrls,
  reservation,
  selectedServices,
}) {
  const classes = useStyles();

  return (
    <>
      <Grid container item xs={12}>
        <Button color="primary" onClick={() => history.goBack()}>
          Takaisin
        </Button>
      </Grid>
      <Grid container className={classes.infoContainer} spacing={2}>
        <Grid container item direction={"column"}>
          {reservation.reservationVehicles.map((v) => {
            return (
              <VehicleInfoRow
                key={v.id}
                vehicle={v}
                signedUrl={signedUrls[v.id] ?? ""}
                selectedServices={selectedServices}
              />
            );
          })}
        </Grid>

        <Grid container item justify="center">
          <Grid item style={{ textAlign: "left" }}>
            <Typography>
              Noutoaika
              <br />
              <FormattedDate date={reservation.startTime} />
              <br />
              <FormattedTime date={reservation.startTime} />
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ alignSelf: "center", margin: 16 }}>
            <Divider />
          </Grid>
          <Grid item style={{ textAlign: "right" }}>
            <Typography>
              Palautusaika
              <br />
              <FormattedDate date={reservation.returnTime} />
              <br />
              <FormattedTime date={reservation.returnTime} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default function Reservation() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const container = useRef();

  const [reservationFailed, setReservationFailed] = useState(false);
  const [company, setCompany] = useState(null);
  const [signedUrls, setSignedUrls] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [risk, setRisk] = useState({
    id: 0,
    key: "fullDeductible",
    offerPrice: 0,
  });
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [voucher, setVoucher] = useState("");
  const [appliedVoucher, setAppliedVoucher] = useState();
  const [validatingVoucher, setValidatingVoucher] = useState(false);
  const [additionalServiceOffers, setAdditionalServiceOffers] = useState([]);
  const [additionalDistanceServiceOffers, setAdditionalDistanceServiceOffers] =
    useState([]);
  const [hasNormalServices, setHasNormalServices] = useState(false);
  const [hasDistanceServices, setHasDistanceServices] = useState(false);
  const [hasDeductibleServices, setHasDeductibleServices] = useState(false);
  const [paymentMode, setPaymentMode] = useState(PaymentModeEnum.now);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const reservation = useMemo(() => {
    return location.state?.reservation;
  }, [location.state?.reservation]);
  // Set feature flags
  const FEATURE_ENABLE_PAYMENT = useMemo(() => {
    return paymentEnabled(process.env.REACT_APP_ENV, reservation?.group);
  }, [reservation]);

  // paymentMode must be onPickup when online payment not enabled
  useEffect(() => {
    if (!FEATURE_ENABLE_PAYMENT) {
      setPaymentMode(PaymentModeEnum.onPickup);
    }
  }, [FEATURE_ENABLE_PAYMENT]);

  const FEATURE_RESERVATION_DEPOSIT = useMemo(() => {
    return !!company?.enableReservationDeposit;
  }, [company]);

  const FEATURE_PAYMENT_ONLINE_ONLY = useMemo(() => {
    // Should probably move below check into paymentOnlineOnly function
    if (!FEATURE_ENABLE_PAYMENT) return false;
    return !!company?.enablePaymentOnlineOnly;
  }, [company, FEATURE_ENABLE_PAYMENT]);

  const FEATURE_ASK_SSN = useMemo(() => {
    return askSsn(reservation.group);
  }, [reservation]);

  // Temporary solution for PR Group
  const FEATURE_PAYMENT_ON_PICKUP_LABEL = useMemo(() => {
    if (company?.id === "8efea994-0c00-4220-8285-7ab37285dc34") {
      return "Maksa laskulla (300 euron varausmaksun suorittamiseksi asiakas saa sähköpostiinsa maksulinkin pian varauksen vahvistamisen jälkeen. Varausmaksu erääntyy varauspäivänä.)";
    }
    return "Maksa noudettaessa";
  }, [company]);

  const FEATURE_CREDITCHECK_LABEL = useMemo(() => {
    if (reservation.group === "nestesorsasalo") {
      return "Luottotietoni saa tarkistaa";
    }
    if (reservation.group === "pakunvuokraus") {
      return "Luottotietoni saa tarkistaa. Mikäli luottotietoni eivät ole kunnossa, olen valmis maksamaan 500 € vakuusmaksun joka palautetaan vuokrauksen jälkeen.";
    }
    if (reservation.group === "prgroup") {
      return "Luottotietoni saa tarkistaa. Mikäli luottotietoni eivät ole kunnossa, olen valmis maksamaan 1200 € vakuusmaksun joka palautetaan vuokrauksen jälkeen.";
    }
    return "Luottotietoni saa tarkistaa. Mikäli luottotietoni eivät ole kunnossa, olen valmis maksamaan 600 € vakuusmaksun joka palautetaan vuokrauksen jälkeen.";
  }, [reservation]);

  const FEATURE_DISABLE_CREDITCHECK = useMemo(() => {
    return disableCreditcheck(reservation.group);
  }, [reservation.group]);

  const FEATURE_SHOW_PORT_INSTRUCTION = useMemo(() => {
    return showPortInstruction(company?.id);
  }, [company]);

  const FEATURE_MUST_SIGN = useMemo(() => {
    return company?.enableMustSign;
  }, [company]);

  const VISMA_PAY_MAX = useMemo(() => {
    return vismaPayMaxPayment(reservation.group);
  }, [reservation.group]);

  const depositPossible = useMemo(() => {
    if (!FEATURE_RESERVATION_DEPOSIT) return false;
    if (!company?.reservationDeposit?.paymentBefore) return false;
    const MILLISECONDS_IN_DAY = 86400000;
    const paymentBeforeMs =
      MILLISECONDS_IN_DAY * company.reservationDeposit.paymentBefore;
    const startTimeMs = new Date(reservation.startTime).getTime();
    const nowMs = new Date().getTime();
    // Can't use deposit if startTime is closer than paymentBefore deadline
    if (startTimeMs - nowMs < paymentBeforeMs) {
      return false;
    }
    return true;
  }, [company, reservation, FEATURE_RESERVATION_DEPOSIT]);

  const validate = (values) => {
    const errors = {};
    const GENERAL_ERROR_MSG = "Täytä tämä kenttä";

    if (!values.name) {
      errors.name = "Täytä tämä kenttä";
    }

    if (!values.address) {
      errors.address = "Täytä tämä kenttä";
    }

    if (!values.postalCode) {
      errors.postalCode = "Täytä tämä kenttä";
    }

    if (!values.city) {
      errors.city = "Täytä tämä kenttä";
    }

    if (!values.phone) {
      errors.phone = "Täytä tämä kenttä";
    }

    if (!values.email) {
      errors.email = "Täytä tämä kenttä";
    }

    if (
      values.email &&
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        values.email
      )
    ) {
      errors.email = "Tarkista sähköposti";
    }

    if (FEATURE_ASK_SSN && !values.ssn) {
      errors.ssn = "Täytä tämä kenttä";
    }

    if (!values.acceptTerms) {
      errors.acceptTerms = "Hyväksy varausehdot";
    }

    if (FEATURE_SHOW_PORT_INSTRUCTION && !values.acceptPortTerms) {
      errors.acceptPortTerms = "Hyväksy satamaohje";
    }

    if (FEATURE_MUST_SIGN && !values.acceptSignTerms) {
      errors.acceptSignTerms = "Hyväksy allekirjoittamiseen sitoutuminen";
    }

    if (!values.acceptCreditCheck && !FEATURE_DISABLE_CREDITCHECK) {
      errors.acceptCreditCheck = "Hyväksy luottotietojen tarkistus";
    }

    if (
      additionalDistanceServiceOffers.filter((s) => values[s.id]).length > 1
    ) {
      errors.distanceServices = "Valitse korkeintaan yksi";
    }
    if (values.exportLicense && !values.exportLicenseDetails) {
      errors.exportLicenseDetails = GENERAL_ERROR_MSG;
    }

    if (values.isCompany) {
      if (!values.companyName) {
        errors.companyName = GENERAL_ERROR_MSG;
      }
    }

    if (values.differentDriver) {
      if (!values.driverName) {
        errors.driverName = GENERAL_ERROR_MSG;
      }

      if (!values.driverAddress) {
        errors.driverAddress = GENERAL_ERROR_MSG;
      }

      if (!values.driverPostalCode) {
        errors.driverPostalCode = GENERAL_ERROR_MSG;
      }

      if (!values.driverCity) {
        errors.driverCity = GENERAL_ERROR_MSG;
      }

      if (!values.driverPhone) {
        errors.driverPhone = GENERAL_ERROR_MSG;
      }

      if (!values.driverEmail) {
        errors.driverEmail = GENERAL_ERROR_MSG;
      }

      if (values.additionalDriver) {
        if (!values.additionalDriverName) {
          errors.additionalDriverName = GENERAL_ERROR_MSG;
        }

        if (!values.additionalDriverPhone) {
          errors.additionalDriverPhone = GENERAL_ERROR_MSG;
        }
      }
    }
    if (
      FEATURE_ENABLE_PAYMENT &&
      (paymentMode === PaymentModeEnum.now ||
        paymentMode === PaymentModeEnum.now_deposit) &&
      !values.paymentMethod
    ) {
      errors.paymentMethod = "Valitse maksutapa";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validate,
    onSubmit,
  });

  const getSignedUrls = async (vehicles) => {
    const urls = {};
    if (vehicles) {
      for (let vehicle of vehicles) {
        if (vehicle.image) {
          const [identityId, key] = vehicle.image.split("/");
          const url = await Storage.get(key, {
            identityId,
            level: "protected",
          });
          urls[vehicle.id] = url;
        } else {
          //urls[vehicle.id] =
        }
      }
      setSignedUrls(urls);
    }
  };

  const fetchCompany = async (companyId) => {
    const response = await API.graphql(
      graphqlOperation(getCompany, {
        id: companyId,
      })
    );
    setCompany(response.data.getCompany);
  };

  const fetchAdditionalServices = async (group) => {
    const response = await API.graphql(
      graphqlOperation(additionalServiceByGroup, {
        group: group,
      })
    );
    // Merge default and user defined additional services
    const additionalServicesCombined = [
      ...defaultAdditionalServices,
      ...response.data.additionalServiceByGroup.items,
    ];

    const additionalServiceRawOffers =
      reservation?.additionalServiceOffers ?? [];
    const services = additionalServicesCombined
      .map((service) => {
        const rawOffer = additionalServiceRawOffers.find(
          (p) => p.key === service.id
        );
        if (rawOffer != null) {
          return {
            ...service,
            offerPrice: rawOffer.offerPrice,
          };
        }
        return null;
      })
      .filter((x) => x != null)
      .filter((x) => !x.isGroupParent && !x.groupParentId); // Not supported in this UI
    const distanceServices = services.filter((s) => s.category === "distance");

    setAdditionalServiceOffers(services);
    setAdditionalDistanceServiceOffers(distanceServices);
    setHasNormalServices(services.some((s) => s.category === "normal"));
    setHasDistanceServices(services.some((s) => s.category === "distance"));
    setHasDeductibleServices(services.some((s) => s.category === "deductible"));
  };

  const fetchValidatedVoucher = async (code) => {
    const response = await API.graphql(
      // Vehicle is used to get the group, and it should always be the same on vehicles on one reservation
      graphqlOperation(validateVoucher, {
        code: code,
        vehicleId: reservation.reservationVehicles[0].id,
      })
    );

    return response.data.validateVoucher;
  };

  const applyVoucher = async () => {
    setValidatingVoucher(true);
    try {
      const voucherData = await fetchValidatedVoucher(voucher);

      if (voucherData) {
        setAppliedVoucher(voucherData);
      } else {
        setSnackbarMessage("Virheellinen koodi");
      }
    } catch (e) {
      captureError("Validating voucher", "VOUCHER_VALIDATION_FAILED", e);
      setSnackbarMessage("Jokin meni vikaan");
    }
    setValidatingVoucher(false);
  };

  const hasMultipleDeductibles = Boolean(
    additionalServiceOffers.find((s) => s.key === "halvedDeductible") &&
      additionalServiceOffers.find((s) => s.key === "noDeductible")
  );

  useEffect(() => {
    fetchAdditionalServices(reservation.group);
  }, [reservation?.group]);

  useEffect(() => {
    getSignedUrls(reservation.reservationVehicles);
  }, [reservation]);

  // All Vehicles in cart should have the same companyId
  useEffect(() => {
    fetchCompany(reservation.reservationVehicles[0].companyId);
  }, [reservation.reservationVehicles]);

  if (location.state == null || location.state.reservation == null) {
    history.replace("/");
    return null;
  }

  const selectedServices = additionalServiceOffers.filter(
    (serviceOffer) => formik.values[serviceOffer.id]
  );

  // Price of distance and deductible services is by default multiplied by the amount of vehicles
  const getPriceMultiplier = (service) => {
    if (formik.values[service?.id + "Quantity"]) {
      return formik.values[service.id + "Quantity"];
    }
    if (["deductible", "distance"].includes(service?.category)) {
      return reservation.reservationVehicles.reduce(
        (t, vehicle) => t + vehicle.quantity,
        0
      );
    }
    return 1;
  };

  const selectedAdditionalServiceTotalPrice = selectedServices.reduce(
    (a, serviceOffer) =>
      a + serviceOffer.offerPrice * getPriceMultiplier(serviceOffer),
    0
  );

  const totalVehiclesPrice = useMemo(() => {
    return Object.values(reservation.reservationVehicles).reduce(
      (t, { offerPrice, quantity }) => t + offerPrice * quantity,
      0
    );
  }, [reservation.reservationVehicles]);

  const totalPrice = useMemo(
    () =>
      calculateDiscountedPrice(
        totalVehiclesPrice +
          selectedAdditionalServiceTotalPrice +
          risk.offerPrice * getPriceMultiplier(risk),
        appliedVoucher?.amount || 0
      ),
    [
      totalVehiclesPrice,
      selectedAdditionalServiceTotalPrice,
      risk.offerPrice,
      appliedVoucher?.amount,
    ]
  );

  // Visma Pay doesn't allow 0€ charges, and there is also a upper limit per customer
  useEffect(() => {
    if (FEATURE_ENABLE_PAYMENT) {
      if (totalPrice === 0) {
        setPaymentMode(PaymentModeEnum.onPickup);
      }
      if (
        totalPrice > 0 &&
        totalPrice < VISMA_PAY_MAX &&
        FEATURE_PAYMENT_ONLINE_ONLY
      ) {
        setPaymentMode(PaymentModeEnum.now);
      }
      if (totalPrice > VISMA_PAY_MAX) {
        setPaymentMode(PaymentModeEnum.onPickup);
        setSnackbarMessage(
          `Verkkomaksaminen ei ole käytössä yli ${centsToLocalString(
            VISMA_PAY_MAX
          )}€ varauksilla`
        );
      }
    }
  }, [
    appliedVoucher,
    FEATURE_PAYMENT_ONLINE_ONLY,
    FEATURE_ENABLE_PAYMENT,
    totalPrice,
  ]);

  const doAuthPayment = async (reservationId, values) => {
    const paymentMethod = paymentMethods.find((method) => {
      return method.name === values.paymentMethod;
    });
    try {
      const response = await API.graphql(
        graphqlOperation(authPayment, {
          reservationId: reservationId,
          paymentMethod: paymentMethod,
          origin: window.location.origin + window.location.pathname,
          originPath: "vahvistus",
        })
      );
      return response.data.authPayment;
    } catch (e) {
      captureError("Auth payment failed", "AUTH_PAYMENT_FAILED", e);
      history.push("/confirmation", {
        ORDER_NUMBER: reservationId,
        group: reservation.group,
      });
    }
  };

  async function onSubmit(values) {
    setSubmitting(true);

    const services = additionalServiceOffers
      .filter((serviceOffer) => values[serviceOffer.id])
      .map((serviceOffer) => ({
        key: serviceOffer.id,
        offerPrice: calculateDiscountedPrice(
          serviceOffer.offerPrice * getPriceMultiplier(serviceOffer),
          appliedVoucher?.amount || 0
        ),
        details: values[serviceOffer.id + "Details"] || "",
        quantity: values[serviceOffer.id + "Quantity"] || 1,
      }));

    if (risk.id !== 0) {
      services.push({
        key: risk.key,
        offerPrice: calculateDiscountedPrice(
          risk.offerPrice * getPriceMultiplier(risk),
          appliedVoucher?.amount || 0
        ),
      });
    }

    try {
      const payNow =
        paymentMode === PaymentModeEnum.now ||
        paymentMode === PaymentModeEnum.now_deposit;
      const payment = FEATURE_ENABLE_PAYMENT && payNow;
      const vehicles = reservation.reservationVehicles.map((v) => {
        return {
          id: v.id,
          quantity: v.quantity,
          offerPrice: calculateDiscountedPrice(
            v.offerPrice,
            appliedVoucher?.amount || 0
          ),
        };
      });
      const result = await doUpsertReservation(
        {
          group: reservation.group,
          reservationVehicles: vehicles,
          companyId: reservation.reservationVehicles[0].companyId,
          startTime: reservation.startTime,
          returnTime: reservation.returnTime,
          name: values.name,
          address: values.address,
          postalCode: values.postalCode,
          city: values.city,
          phone: values.phone,
          email: values.email,
          externalNotes: values.externalNotes,
          ssn: values.ssn,
          additionalServiceOffers: services,
          voucherCode: appliedVoucher?.code,
          differentDriver: values.differentDriver,
          driverName: values.driverName,
          driverAddress: values.driverAddress,
          driverPostalCode: values.driverPostalCode,
          driverCity: values.driverCity,
          driverPhone: values.driverPhone,
          driverEmail: values.driverEmail,
          additionalDriver: values.additionalDriver,
          additionalDriverName: values.additionalDriverName,
          additionalDriverPhone: values.additionalDriverPhone,
          isCompany: values.isCompany,
          companyName: values.companyName,
          companyBusinessId: values.companyBusinessId,
          companyBillingRef: values.companyBillingRef,
          companyBillingAddress: values.companyBillingAddress,
        },
        paymentMode
      );

      if (result.code != null) {
        Sentry.captureMessage("Reservation failed", {
          tags: {
            code: result.code,
          },
        });
        if (result.code === "VEHICLE_NOT_AVAILABLE") {
          setReservationFailed(true);
        } else {
          setSnackbarMessage("Jokin meni vikaan");
        }
      } else {
        // emailConfirmationSuccess false at this stage is expected if customer is paying online
        if (
          (!result.emailConfirmationSuccess && !FEATURE_ENABLE_PAYMENT) ||
          (!result.emailConfirmationSuccess &&
            FEATURE_ENABLE_PAYMENT &&
            paymentMode === PaymentModeEnum.onPickup)
        ) {
          Sentry.captureMessage("Send confirmation email failed", {
            tags: {
              code: "CONFIRMATION_EMAIL_FAILED",
              destinationEmail: values.email,
            },
          });
        }

        if (
          FEATURE_ENABLE_PAYMENT &&
          (paymentMode === PaymentModeEnum.now ||
            paymentMode === PaymentModeEnum.now_deposit)
        ) {
          const authPaymentResult = await doAuthPayment(
            result.reservationId,
            values
          );
          if (authPaymentResult.result === 0) {
            window.location.replace(
              "https://www.vismapay.com/pbwapi/token/" + authPaymentResult.token
            );
          } else {
            history.push("/confirmation", {
              ORDER_NUMBER: result.reservationId,
            });
          }
          return;
        }

        // Success
        history.push("/confirmation", {
          emailConfirmationSuccess: result.emailConfirmationSuccess,
          reservationId: result.reservationId,
          group: reservation.group,
        });
        return;
      }
    } catch (e) {
      console.log("catch", e);
      captureError(
        "Creatin reservation failed",
        "CREATE_RESERVATION_FAILED",
        e
      );
      setSnackbarMessage("Jokin meni vikaan");
    }
    setSubmitting(false);
  }

  // Visma Pay doesn't allow transactions over certain treshold.
  // If only online payment activated, tell user to contact dealer
  if (FEATURE_PAYMENT_ONLINE_ONLY && totalPrice > VISMA_PAY_MAX) {
    return (
      <Container
        maxWidth="sm"
        style={{ padding: "16px 0 10vh" }}
        ref={container}
      >
        <Card>
          <CardContent>
            <ReservationInfo
              history={history}
              signedUrls={signedUrls}
              reservation={reservation}
              selectedServices={selectedServices}
            />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {`Ota yhteys vuokraamoon, verkkomaksaminen ei ole käytössä yli ${centsToLocalString(
                    VISMA_PAY_MAX
                  )}€ varauksissa`}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} justify="space-between">
              <Button color="primary" onClick={() => history.goBack()}>
                Takaisin
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" style={{ padding: "16px 0 10vh" }} ref={container}>
      <Card>
        <CardContent>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container className={classes.infoContainer} spacing={2}>
              <ReservationInfo
                history={history}
                signedUrls={signedUrls}
                reservation={reservation}
                selectedServices={selectedServices}
              />
              <Grid item xs={12}>
                <Typography variant="h6">Vuokraajan tiedot:</Typography>
              </Grid>
              <Grid item container xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isCompany"
                      color="primary"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label={<>Yritys</>}
                />
              </Grid>
              {formik.values.isCompany && (
                <>
                  <Grid item container spacing={2}>
                    {FEATURE_ENABLE_PAYMENT && (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                          <Typography variant="body2">
                            Mikäli haluat maksaa varauksesi laskulla, valitse
                            alapuolelta maksutavaksi yrityslasku
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={7}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        name="companyName"
                        required
                        label="Yrityksen nimi"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.companyName &&
                          !!formik.errors.companyName
                        }
                        helperText={
                          formik.touched.companyName &&
                          formik.errors.companyName
                        }
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        name="companyBusinessId"
                        label="Y-tunnus"
                        value={formik.values.companyBusinessId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.companyBusinessId &&
                          !!formik.errors.companyBusinessId
                        }
                        helperText={
                          formik.touched.companyBusinessId &&
                          formik.errors.companyBusinessId
                        }
                        size="small"
                      />
                    </Grid>
                    {!FEATURE_ENABLE_PAYMENT && (
                      <>
                        <Grid item xs={12} sm={7}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            name="companyBillingAddress"
                            label="Laskutusosoite"
                            value={formik.values.companyBillingAddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.companyBillingAddress &&
                              !!formik.errors.companyBillingAddress
                            }
                            helperText={
                              formik.touched.companyBillingAddress &&
                              formik.errors.companyBillingAddress
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            name="companyBillingRef"
                            label="Viitetieto"
                            value={formik.values.companyBillingRef}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.companyBillingRef &&
                              !!formik.errors.companyBillingRef
                            }
                            helperText={
                              formik.touched.companyBillingRef &&
                              formik.errors.companyBillingRef
                            }
                            size="small"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  autoFocus
                  name="name"
                  required
                  label="Nimi"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                  autoComplete="name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="address"
                  required
                  label="Osoite"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.address && !!formik.errors.address}
                  helperText={formik.touched.address && formik.errors.address}
                  autoComplete="address-line1"
                />
              </Grid>
              <Grid item sm={7} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="city"
                  required
                  label="Kaupunki"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && !!formik.errors.city}
                  helperText={formik.touched.city && formik.errors.city}
                  autoComplete="address-level2"
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="postalCode"
                  required
                  label="Postinumero"
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.postalCode && !!formik.errors.postalCode
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                  autoComplete="postal-code"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Sähköposti"
                  name="email"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Puhelin"
                  name="phone"
                  required
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                  autoComplete="tel"
                />
              </Grid>
              {FEATURE_ASK_SSN && (
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Henkilötunnus"
                    name="ssn"
                    required
                    value={formik.values.ssn}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.ssn && !!formik.errors.ssn}
                    helperText={formik.touched.ssn && formik.errors.ssn}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Terveisiä vuokraamolle"
                  name="externalNotes"
                  value={formik.values.externalNotes}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiline
                />
              </Grid>
              <Grid item container xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="differentDriver"
                      color="primary"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label={<>Kuljettaja eri kuin vuokraaja</>}
                />
              </Grid>
              {formik.values.differentDriver && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      value={formik.values.driverName ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="driverName"
                      label="Kuljettajan nimi"
                      variant="outlined"
                      error={
                        formik.touched.driverName && !!formik.errors.driverName
                      }
                      helperText={
                        formik.touched.driverName && formik.errors.driverName
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={formik.values.driverAddress ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="driverAddress"
                      label="Kuljettajan osoite"
                      variant="outlined"
                      error={
                        formik.touched.driverAddress &&
                        !!formik.errors.driverAddress
                      }
                      helperText={
                        formik.touched.driverAddress &&
                        formik.errors.driverAddress
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    <TextField
                      value={formik.values.driverCity ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="driverCity"
                      label="Kuljettajan kaupunki"
                      variant="outlined"
                      error={
                        formik.touched.driverCity && !!formik.errors.driverName
                      }
                      helperText={
                        formik.touched.driverCity && formik.errors.driverName
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <TextField
                      value={formik.values.driverPostalCode ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="driverPostalCode"
                      label="Kuljettajan postinumero"
                      variant="outlined"
                      error={
                        formik.touched.driverPostalCode &&
                        !!formik.errors.driverPostalCode
                      }
                      helperText={
                        formik.touched.driverPostalCode &&
                        formik.errors.driverPostalCode
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={formik.values.driverPhone ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="driverPhone"
                      label="Kuljettajan puhelin"
                      variant="outlined"
                      error={
                        formik.touched.driverPhone &&
                        !!formik.errors.driverPhone
                      }
                      helperText={
                        formik.touched.driverPhone && formik.errors.driverPhone
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={formik.values.driverEmail ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="driverEmail"
                      label="Kuljettajan sähköposti"
                      variant="outlined"
                      error={
                        formik.touched.driverEmail &&
                        !!formik.errors.driverEmail
                      }
                      helperText={
                        formik.touched.driverEmail && formik.errors.driverEmail
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="additionalDriver"
                          color="primary"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      }
                      label={<>Lisäkuljettaja</>}
                    />
                  </Grid>
                  {formik.values.additionalDriver && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          value={formik.values.additionalDriverName ?? ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="additionalDriverName"
                          label="Nimi"
                          variant="outlined"
                          error={
                            formik.touched.additionalDriverName &&
                            !!formik.errors.additionalDriverName
                          }
                          helperText={
                            formik.touched.additionalDriverName &&
                            formik.errors.additionalDriverName
                          }
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={formik.values.additionalDriverPhone ?? ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="additionalDriverPhone"
                          label="Puhelin"
                          variant="outlined"
                          error={
                            formik.touched.additionalDriverPhone &&
                            !!formik.errors.additionalDriverPhone
                          }
                          helperText={
                            formik.touched.additionalDriverPhone &&
                            formik.errors.additionalDriverPhone
                          }
                          fullWidth
                          required
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}

              {hasNormalServices && (
                <>
                  <Typography variant="h6">Lisäpalvelut</Typography>
                  <AdditionalServiceList
                    formik={formik}
                    categories="['normal']"
                    additionalServiceOffers={additionalServiceOffers}
                    getQuantity={getPriceMultiplier}
                  />
                </>
              )}
              {hasDeductibleServices && (
                <>
                  <Typography variant="h6">Omavastuu</Typography>
                  <>
                    {hasMultipleDeductibles && (
                      <Grid item xs={12}>
                        <RiskSlider
                          selected={risk}
                          vehicleQuantity={getPriceMultiplier({
                            category: "deductible",
                          })}
                          onChange={(value) => setRisk(value)}
                          values={[
                            {
                              key: "fullDeductible",
                              description: "Täysi omavastuu",
                              offerPrice: 0,
                            },
                            ...additionalServiceOffers
                              .filter((s) =>
                                ["halvedDeductible", "noDeductible"].includes(
                                  s.key
                                )
                              )
                              .sort((v) => v.offerPrice),
                          ].map((s, idx) => ({ ...s, id: idx }))}
                        />
                      </Grid>
                    )}
                    {!hasMultipleDeductibles && (
                      <AdditionalServiceList
                        formik={formik}
                        categories="['deductible']"
                        additionalServiceOffers={additionalServiceOffers}
                        getQuantity={getPriceMultiplier}
                      />
                    )}
                  </>
                </>
              )}
              {hasDistanceServices && (
                <>
                  <FormControl
                    style={{ flexBasis: "100%" }}
                    error={!!formik.errors.distanceServices}
                  >
                    <Typography variant="h6">Kilometrit</Typography>
                    <AdditionalServiceList
                      formik={formik}
                      categories="['distance']"
                      additionalServiceOffers={additionalServiceOffers}
                      getQuantity={getPriceMultiplier}
                    />
                    <FormHelperText>
                      {formik.errors.distanceServices}
                    </FormHelperText>
                  </FormControl>
                </>
              )}

              <Grid
                item
                container
                xs={12}
                spacing={2}
                style={{ marginTop: 16 }}
                alignItems="center"
              >
                <Grid item>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Alekoodi"
                    name="voucher"
                    value={voucher}
                    onChange={(e) => setVoucher(e.target.value.toUpperCase())}
                    disabled={!!appliedVoucher}
                  />
                </Grid>
                <Grid item>
                  {appliedVoucher ? (
                    <Typography variant="h6">
                      -{appliedVoucher.amount}%
                    </Typography>
                  ) : (
                    <Button
                      color="primary"
                      onClick={applyVoucher}
                      disabled={validatingVoucher}
                    >
                      Aseta
                    </Button>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center"
                style={{ marginTop: "16px" }}
              >
                {(reservation.vehicle?.includedDistance ||
                  selectedServices.findIndex(
                    (serviceOffer) => serviceOffer.category === "distance"
                  ) >= 0) && (
                  <>
                    <Grid item>
                      <Typography variant="h6">
                        Sisältyvät kilometrit
                      </Typography>
                    </Grid>
                    <Grid item style={{ textAlign: "right" }}>
                      <Typography variant="h5" noWrap>
                        {selectedServices.findIndex(
                          (serviceOffer) => serviceOffer.category === "distance"
                        ) >= 0
                          ? selectedServices.find(
                              (serviceOffer) =>
                                serviceOffer.category === "distance"
                            ).description
                          : reservation.vehicle?.includedDistance}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6">Yhteensä</Typography>
                </Grid>
                <Grid item style={{ textAlign: "right" }}>
                  <Typography variant="h4" noWrap>
                    {centsToLocalString(totalPrice)}
                    {" €"}
                  </Typography>
                  <Typography variant="caption">sis. ALV</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  error={
                    formik.touched.acceptTerms && !!formik.errors.acceptTerms
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="acceptTerms"
                        color="primary"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                      />
                    }
                    label={
                      company?.termsUrl ? (
                        <>
                          Hyväksyn{" "}
                          <Link
                            href={company?.termsUrl}
                            target="_blank"
                            rel="noopener"
                          >
                            varausehdot
                          </Link>
                        </>
                      ) : (
                        <>Hyväksyn varausehdot</>
                      )
                    }
                  />
                  {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <FormHelperText>{formik.errors.acceptTerms}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {FEATURE_SHOW_PORT_INSTRUCTION && (
                <Grid item xs={12}>
                  <FormControl
                    required
                    error={
                      formik.touched.acceptPortTerms &&
                      !!formik.errors.acceptPortTerms
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="acceptPortTerms"
                          color="primary"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        />
                      }
                      label={
                        <>
                          Hyväksyn{" "}
                          <Link
                            href="https://www.rsteam.fi/vuokraamosta-lahto-paluu-ohjeistus/"
                            target="_blank"
                            rel="noopener"
                          >
                            satamaohjeen
                          </Link>
                        </>
                      }
                    />
                    {formik.touched.acceptPortTerms &&
                      formik.errors.acceptPortTerms && (
                        <FormHelperText>
                          {formik.errors.acceptPortTerms}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              )}
              {FEATURE_MUST_SIGN && (
                <Grid item xs={12}>
                  <FormControl
                    required
                    error={
                      formik.touched.acceptSignTerms &&
                      !!formik.errors.acceptSignTerms
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="acceptSignTerms"
                          color="primary"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        />
                      }
                      label={
                        <>
                          Suoritan sähköisen allekirjoittamisen maksun jälkeen,
                          jotta varaus hyväksytään.
                        </>
                      }
                    />
                    {formik.touched.acceptSignTerms &&
                      formik.errors.acceptSignTerms && (
                        <FormHelperText>
                          {formik.errors.acceptSignTerms}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              )}
              {!FEATURE_DISABLE_CREDITCHECK && (
                <Grid item xs={12}>
                  <FormControl
                    required
                    error={
                      formik.touched.acceptCreditCheck &&
                      !!formik.errors.acceptCreditCheck
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="acceptCreditCheck"
                          color="primary"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        />
                      }
                      label={FEATURE_CREDITCHECK_LABEL}
                    />
                    {formik.touched.acceptCreditCheck &&
                      formik.errors.acceptCreditCheck && (
                        <FormHelperText>
                          {formik.errors.acceptCreditCheck}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              )}
              {FEATURE_ENABLE_PAYMENT && totalPrice > 0 && (
                <>
                  <RadioGroup
                    aria-label="Maksu nyt vai noutaessa"
                    name="paymentMode"
                    value={paymentMode}
                    onChange={(event) => setPaymentMode(event.target.value)}
                  >
                    <FormControlLabel
                      disabled={totalPrice > VISMA_PAY_MAX}
                      value={PaymentModeEnum.now}
                      control={<Radio />}
                      label={
                        totalPrice > VISMA_PAY_MAX
                          ? `Maksa heti (Ei käytössä yli ${centsToLocalString(
                              VISMA_PAY_MAX
                            )}€ varauksiin)`
                          : "Maksa heti (Visma Pay)"
                      }
                    />
                    {FEATURE_RESERVATION_DEPOSIT && (
                      <FormControlLabel
                        value={PaymentModeEnum.now_deposit}
                        control={<Radio />}
                        disabled={!depositPossible}
                        label={`Maksa ${centsToLocalString(
                          company.reservationDeposit.amount
                        )}€ varausmaksu nyt, loput viimeistään ${
                          company.reservationDeposit.paymentBefore
                        } päivää ennen varauksen alkua (Visma Pay)`}
                      />
                    )}
                    {!FEATURE_PAYMENT_ONLINE_ONLY && (
                      <FormControlLabel
                        value={PaymentModeEnum.onPickup}
                        control={<Radio />}
                        label={FEATURE_PAYMENT_ON_PICKUP_LABEL}
                      />
                    )}
                  </RadioGroup>
                  <Grid item xs={12}>
                    <PaymentMethods
                      formik={formik}
                      paymentMode={paymentMode}
                      paymentMethods={paymentMethods}
                      setPaymentMethods={setPaymentMethods}
                    />
                  </Grid>
                </>
              )}
              <Grid container item xs={12} justify="space-between">
                <Button color="primary" onClick={() => history.goBack()}>
                  Takaisin
                </Button>
                <Button
                  id="reserveBtn"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {FEATURE_ENABLE_PAYMENT &&
                  (paymentMode === PaymentModeEnum.now ||
                    paymentMode === PaymentModeEnum.now_deposit)
                    ? "Siirry maksamaan"
                    : "Varaa"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Snackbar
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
        autoHideDuration={10000}
        message={snackbarMessage}
      />
      {reservationFailed ? <AlreadyReservedDialog /> : null}
    </Container>
  );
}
