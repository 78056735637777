import {
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Slider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Schedule, Today, Close } from "@material-ui/icons";

//Fork used for unmount issue:
//https://github.com/Kiarash-Z/react-modern-calendar-datepicker/issues/204#issuecomment-757520714
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "@hassanmojab/react-modern-calendar-datepicker";

import { useState } from "react";
import locale from "../const/datePickerLocale";
import { TimePicker } from "@material-ui/pickers";
import {
  add,
  differenceInMinutes,
  format,
  formatISO,
  isSameDay,
  set,
  startOfDay,
  getDay,
  addDays,
} from "date-fns";
import { fi } from "date-fns/locale";
import { useEffect, useMemo } from "react";
import { enableCustomProviderLabel } from "../features";

const throttle = (callback, limit) => {
  let wait = false;
  return (...args) => {
    if (!wait) {
      wait = true;
      setTimeout(function () {
        wait = false;
      }, limit);
      return callback(...args);
    }
  };
};

const SLIDER_STEP = 30;

const useStyles = makeStyles((theme) => ({
  calendar: { boxShadow: "none", fontFamily: "Roboto", margin: "0 auto" },
  middleRangeDate: { color: "#fff !important" },
  submit: {
    margin: theme.spacing(1),
  },
  inputField: {
    "& .MuiInputBase-root, input": {
      cursor: "pointer",
    },
  },
}));

const getRangeText = (startDate, endDate) => {
  let endFormat = "dd.MM. HH:mm";
  if (isSameDay(startDate, endDate)) {
    endFormat = "HH:mm";
  }
  return `${format(startDate, "dd.MM. HH:mm")} - ${format(endDate, endFormat)}`;
};

const dayStart = startOfDay(new Date());

function TimeErrorText({ endTimeOk }) {
  if (endTimeOk.hasError) {
    return <FormHelperText error>{endTimeOk.message}</FormHelperText>;
  }
  return <FormHelperText error>Tarkista ajat</FormHelperText>;
}

export default function DateRange({
  startDate,
  endDate,
  onChange,
  inputClassName,
  showIcon,
  companies,
  setDateHasError,
}) {
  const [dateRange, setDateRange] = useState({ from: null, to: null }); // Used if multiday reservation enabled (default)
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const FEATURE_SELECT_CUSTOM_LABEL = enableCustomProviderLabel();

  const DEPENDENCY_MISSING_LABEL = useMemo(() => {
    if (FEATURE_SELECT_CUSTOM_LABEL) return "Valitse ensin tuote";
    return "Valitse ensin toimipiste";
  }, [FEATURE_SELECT_CUSTOM_LABEL]);

  const useSpecificPickupReturnCompany = useMemo(() => {
    // Specific feature for this one Company, hence hardcoded for now.
    const company = companies?.find(
      (c) => c.id === "00ef9a27-c9ea-4947-9115-436433a83a35"
    );
    if (company) {
      const hardCodedTimes = {
        pickupAt: ["10:30", "16:30"],
        returnAt: ["10:00", "16:00"],
      };
      // Sunday indexed as 0
      const hardCodedWeekEntries = [
        hardCodedTimes,
        null,
        null,
        null,
        null,
        null,
        hardCodedTimes,
      ];
      const weeklyPickupReturnTimes = hardCodedWeekEntries;
      return { ...company, weeklyPickupReturnTimes };
    }
  }, [companies]);
  const disableOffHourReturnCompany = useMemo(() => {
    return companies?.find((c) => c.disableOffHourReturn);
  }, [companies]);
  const disableMultiday = useMemo(() => {
    return companies?.find((c) => c.disableMultiday);
  }, [companies]);
  const disableSameDay = useMemo(() => {
    return companies?.find((c) => c.disableSameDay);
  }, [companies]);
  const enableVehicleStartReturnTimes = useMemo(() => {
    return companies?.some((c) => c.enableVehicleStartReturnTimes);
  }, [companies]);

  // Format of value given to Calendar determines if it uses multiDay or not
  const dateGetter = () => {
    if (disableMultiday) {
      return dateRange.to;
    }
    return dateRange;
  };

  // Format of date/dates depends on if it uses multiDay mode or not
  const dateSetter = (dateFromCalendar) => {
    if (disableMultiday) {
      const range = { from: dateFromCalendar, to: dateFromCalendar };
      setDateRange(range);
    } else {
      setDateRange(dateFromCalendar);
    }
  };

  const fromDate = useMemo(() => {
    if (!dateRange.from) return null;
    return new Date(
      dateRange.from.year,
      dateRange.from.month - 1,
      dateRange.from.day
    );
  }, [dateRange]);

  const toDate = useMemo(() => {
    return dateRange.to
      ? new Date(dateRange.to.year, dateRange.to.month - 1, dateRange.to.day)
      : fromDate;
  }, [dateRange]);

  const getSpecificFromToTimes = (date) => {
    if (!useSpecificPickupReturnCompany) return null;
    return useSpecificPickupReturnCompany.weeklyPickupReturnTimes[getDay(date)];
  };

  // Make sure  selected start and end times are possible
  useEffect(() => {
    if (!companies.length || enableVehicleStartReturnTimes) return;
    if (fromDate) {
      const specificFromTimes = getSpecificFromToTimes(fromDate);
      // Do special checks for this one Company
      if (specificFromTimes) {
        setStartTime(
          set(startTime, {
            hours: specificFromTimes.pickupAt[0].split(":")[0],
            minutes: specificFromTimes.pickupAt[0].split(":")[1],
          })
        );
      }
      const specificToTimes = getSpecificFromToTimes(toDate);
      if (specificToTimes) {
        const toLength = specificToTimes.returnAt.length - 1;
        setEndTime(
          set(endTime, {
            hours: specificToTimes.returnAt[toLength].split(":")[0],
            minutes: specificToTimes.returnAt[toLength].split(":")[1],
          })
        );
      }
      // Make sure that return is within businessHours, is disableOffHourReturn
      if (
        disableOffHourReturnCompany &&
        businessHourDates &&
        !specificToTimes
      ) {
        setEndTime(
          set(endTime, {
            hours: endDayBusinessHours?.closeAt.split(":")[0],
            minutes: endDayBusinessHours?.closeAt.split(":")[1],
          })
        );
      }
      // set startTime to opening time
      if (!specificFromTimes) {
        setStartTime(
          set(startTime, {
            hours: startDayBusinessHours?.openAt?.split(":")[0],
            minutes: startDayBusinessHours?.openAt?.split(":")[1],
          })
        );
      }
    }
  }, [dateRange]);

  const getMinimumDate = () => {
    let date = new Date();
    if (disableSameDay) {
      // Get tomorrow
      date = addDays(date, 1);
    }
    const objForCalendar = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    return objForCalendar;
  };

  useEffect(() => {
    setDateRange({
      from: startDate
        ? {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate(),
          }
        : null,
      to: endDate
        ? {
            year: endDate.getFullYear(),
            month: endDate.getMonth() + 1,
            day: endDate.getDate(),
          }
        : null,
    });
    setStartTime(
      startDate
        ? set(startDate, {
            year: dayStart.getFullYear(),
            month: dayStart.getMonth(),
            date: dayStart.getDate(),
          })
        : set(dayStart, { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 })
    );
    setEndTime(
      endDate
        ? set(endDate, {
            year: dayStart.getFullYear(),
            month: dayStart.getMonth(),
            date: dayStart.getDate(),
          })
        : set(dayStart, {
            hours: 16,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate && startDate.getTime(), endDate && endDate.getTime()]); // At the moment .? crash linter

  const startSliderValue = useMemo(
    () => differenceInMinutes(startTime, startOfDay(startTime)) / SLIDER_STEP,
    [startTime]
  );

  const endSliderValue = useMemo(
    () => differenceInMinutes(endTime, startOfDay(endTime)) / SLIDER_STEP,
    [endTime]
  );

  const startDateString = useMemo(() => {
    return dateRange.from
      ? format(locale.toNativeDate(dateRange.from), "eeeeee dd.MM.", {
          locale: fi,
        })
      : "-";
  }, [dateRange.from]);

  const endDateString = useMemo(() => {
    return dateRange.to || dateRange.from
      ? format(
          locale.toNativeDate(dateRange.to || dateRange.from),
          "eeeeee dd.MM.",
          {
            locale: fi,
          }
        )
      : "-";
  }, [dateRange.to, dateRange.from]);

  const startDayBusinessHours = useMemo(() => {
    if (dateRange.from) {
      const date = dateRange.from;
      const dateFormatted = new Date(date.year, date.month - 1, date.day);
      const businessHours = companies[0]?.weeklyBusinessHours;
      const hours = businessHours[getDay(dateFormatted)];
      const dateStr = formatISO(dateFormatted, { representation: "date" });
      const businessHoursExceptions =
        companies[0].businessHoursExceptions.items;
      const exception = businessHoursExceptions.find(
        (bhe) => bhe.date === dateStr
      );
      if (exception?.businessHours) return exception.businessHours;
      return businessHours[getDay(dateFormatted)];
    }
  }, [dateRange.from, companies]);

  const endDayBusinessHours = useMemo(() => {
    if (dateRange.from) {
      const date = dateRange.to ? dateRange.to : dateRange.from;
      const dateFormatted = new Date(date.year, date.month - 1, date.day);
      const businessHours = companies[0]?.weeklyBusinessHours;
      return businessHours[getDay(dateFormatted)];
    }
  }, [dateRange.from, dateRange.to, companies]);

  // Dates for easier comparison of times
  const businessHourToDate = (startAt, closeAt) => {
    const openingHours = parseInt(startAt.split(":")[0]);
    const openingMinutes = parseInt(startAt.split(":")[1]);
    const openingTime = set(dayStart, {
      hours: openingHours,
      minutes: openingMinutes,
    });
    const closingHours = parseInt(closeAt.split(":")[0]);
    const closingMinutes = parseInt(closeAt.split(":")[1]);
    const closingTime = set(dayStart, {
      hours: closingHours,
      minutes: closingMinutes,
    });
    return { openingTime: openingTime, closingTime: closingTime };
  };

  // Business hours in date form for start and end days
  const businessHourDates = useMemo(() => {
    const startAt = startDayBusinessHours?.openAt;
    const closeAt = startDayBusinessHours?.closeAt;
    if (startAt && closeAt) {
      // DateTimes based of starting day business hours
      const start = businessHourToDate(startAt, closeAt);
      if (dateRange.to) {
        const endStartAt = endDayBusinessHours?.openAt;
        const endCloseAt = endDayBusinessHours?.closeAt;
        if (endStartAt && endCloseAt) {
          // DateTimes based of ending day business hours
          const end = businessHourToDate(endStartAt, endCloseAt);
          return {
            start: start,
            end: end,
          };
        }
      }
      // start and end are the same
      return {
        start: start,
        end: start,
      };
    }
    // Business hours not set for start or close
    return null;
  }, [startDayBusinessHours, dateRange, endDayBusinessHours]);

  // See if time is in times
  const isAllowedTime = (time, allowedTimes) => {
    const hours = time.getHours();
    // Get minutes in double digit form. Eg. 00 or 30
    const minutes = ("0" + time.getMinutes()).slice(-2);
    const timeStr = hours + ":" + minutes;
    if (!allowedTimes.some((time) => time === timeStr)) {
      return false;
    }
    return true;
  };

  const nextOpeningDatetime = (toDate) => {
    // Find next available return time withing a week
    let nextReturnDate;
    let i = 1;
    const businessHoursExceptions = companies[0].businessHoursExceptions?.items;
    while (i <= 7) {
      const date = add(toDate, { days: i });
      // If Company is closed by exception, move onto next day
      const dateStr = formatISO(date, { representation: "date" });
      const exception = businessHoursExceptions.find(
        (bhe) => bhe.date === dateStr
      );
      if (exception?.businessHours === null) {
        i = i + 1;
        continue;
      }
      if (useSpecificPickupReturnCompany) {
        const allowedTimes =
          useSpecificPickupReturnCompany.weeklyPickupReturnTimes[getDay(date)];
        if (allowedTimes?.returnAt) {
          nextReturnDate = set(date, {
            hours: allowedTimes.returnAt[0].split(":")[0], // times are in hh:mm format
            minutes: allowedTimes.returnAt[0].split(":")[1],
          });
          return nextReturnDate;
        }
      }
      if (disableOffHourReturnCompany) {
        if (exception?.businessHours) {
          return set(date, {
            hours: exception.businessHours.openAt.split(":")[0], // times are in hh:mm format
            minutes: exception.businessHours.openAt.split(":")[1],
          });
        }
        const weeklyBusinessHours =
          disableOffHourReturnCompany?.weeklyBusinessHours;
        const businessHours = weeklyBusinessHours[getDay(date)];
        if (!businessHours) {
          i = i + 1;
          continue;
        }
        return set(date, {
          hours: businessHours.openAt.split(":")[0], // times are in hh:mm format
          minutes: businessHours.openAt.split(":")[1],
        });
      }
      i = i + 1;
    }
  };

  const formatDayTimeString = (date) => {
    return format(new Date(date), "eeeeee HH:mm", {
      locale: fi,
    });
  };

  // See if there are errors related to chosen pickup / return times
  const endTimeOk = useMemo(() => {
    if (!companies.length) return { hasError: false };
    if (dateRange.from) {
      if (!startDayBusinessHours) {
        return {
          hasError: true,
          message: `Olemme suljettu ${startDateString}`,
        };
      }
      if (!endDayBusinessHours) {
        return {
          hasError: true,
          message: `Olemme suljettu ${endDateString}`,
        };
      }
    }
    let specificToTimes;
    if (fromDate) {
      // See if there are specific from/to times set that overrides businessHours
      const specificFromTimes = getSpecificFromToTimes(fromDate);
      if (specificFromTimes) {
        // Pickup and return  times must match exactly the pre-determined times
        if (!isAllowedTime(startTime, specificFromTimes.pickupAt)) {
          return {
            hasError: true,
            message: `Sallitut noutoajat ${startDateString} ${specificFromTimes.pickupAt}`,
          };
        }
      }
      specificToTimes = getSpecificFromToTimes(toDate);
      if (specificToTimes) {
        if (
          specificToTimes &&
          !isAllowedTime(endTime, specificToTimes.returnAt)
        ) {
          const nextReturnDate = nextOpeningDatetime(toDate);
          const nextDateString = formatDayTimeString(nextReturnDate);
          const message = disableMultiday
            ? `Sallitut palautusajat ${endDateString} ${specificToTimes.returnAt}`
            : `Sallitut palautusajat ${endDateString} ${specificToTimes.returnAt}. Voit valita palautuksen myös tulevalle päivälle, seuraava mahdollinen ${nextDateString}`;
          return {
            hasError: true,
            message: message,
          };
        }
      }
      // startTime should not be before opening time
      if (!enableVehicleStartReturnTimes) {
        const startDayOpenTime = businessHourDates.start.openingTime;
        const startDayCloseTime = businessHourDates.start.closingTime;
        if (!specificFromTimes && startTime < startDayOpenTime) {
          return {
            hasError: true,
            message: `Aukeamisaika ${startDateString} on ${startDayBusinessHours.openAt}`,
          };
        }
        if (!specificFromTimes && startTime > startDayCloseTime) {
          return {
            hasError: true,
            message: `Sulkemisaika ${startDateString} on ${startDayBusinessHours.closeAt}`,
          };
        }
      }
      if (disableOffHourReturnCompany && !enableVehicleStartReturnTimes) {
        // endTime can't be outside business hours, if disableOffHourReturn is set to true
        const endDayOpenTime = businessHourDates.end.openingTime;
        const endDayCloseTime = businessHourDates.end.closingTime;
        // See if endTime is outside the businessHours on the given day, unless specificToTimes are set.
        // In that case we always want to show just the allowed times
        if (endTime > endDayCloseTime && !specificToTimes) {
          const nextReturnDate = nextOpeningDatetime(toDate);
          const nextDateString = formatDayTimeString(nextReturnDate);
          const message = disableMultiday
            ? `Suljemme ${endDateString} ${endDayBusinessHours.closeAt}.`
            : `Sulkemisaika ${endDateString} on ${endDayBusinessHours.closeAt}.
            Avaamme taas ${nextDateString}`;
          return {
            hasError: true,
            message: message,
          };
        }
        if (endTime < endDayOpenTime && !specificToTimes) {
          return {
            hasError: true,
            message: `Aukeamisaika ${startDateString} on ${endDayBusinessHours.openAt}`,
          };
        }
      }
    }
    return { hasError: false };
  }, [
    dateRange,
    startTime,
    endTime,
    disableOffHourReturnCompany,
    useSpecificPickupReturnCompany,
    businessHourDates,
    endDayBusinessHours,
    startDayBusinessHours,
  ]);

  const hasError = useMemo(() => {
    // Start time can't be after end time, if start and end on the same day
    if (
      (disableMultiday ||
        (dateRange.from &&
          isSameDay(
            locale.toNativeDate(dateRange.from),
            locale.toNativeDate(dateRange.to || dateRange.from)
          ))) &&
      startTime >= endTime
    ) {
      return true;
    }
    if (endTimeOk.hasError) {
      return true;
    }
    return false;
  }, [startTime, endTime, dateRange, disableMultiday, endTimeOk]);

  useEffect(() => {
    if (!open) {
      if (setDateHasError) {
        setDateHasError(hasError);
      }
    }
  }, [hasError, open]);

  return (
    <>
      <TextField
        className={[inputClassName, classes.inputField].join(" ")}
        variant="outlined"
        onClick={() => {
          // Must have a company selected before choosing time
          if (companies.length === 0) return;
          setOpen(true);
        }}
        fullWidth
        readOnly
        spellCheck={false}
        value={
          startDate && endDate
            ? getRangeText(startDate, endDate)
            : companies.length === 0
            ? DEPENDENCY_MISSING_LABEL
            : "Valitse ajankohta"
        }
        InputProps={
          showIcon
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <Today />
                  </InputAdornment>
                ),
              }
            : null
        }
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll="body"
        maxWidth={"lg"}
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogContent>
          <Grid
            container
            direction={fullScreen ? "column" : "row"}
            alignItems={fullScreen ? "center" : "flex-start"}
            justify="space-evenly"
          >
            <Grid container justify="flex-end" item xs={12}>
              <Grid item>
                <IconButton aria-label="close" onClick={() => setOpen(false)}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container direction="column" justify="center">
                <Typography variant="h6" align="center">
                  1){" "}
                  {disableMultiday
                    ? "Valitse päivämäärä"
                    : "Valitse nouto- ja palautuspäivä"}
                </Typography>
                <Calendar
                  value={dateGetter()}
                  onChange={dateSetter}
                  minimumDate={getMinimumDate()}
                  locale={locale}
                  calendarClassName={classes.calendar}
                  colorPrimary={theme.palette.primary.main}
                  colorPrimaryLight={theme.palette.primary[200]}
                  calendarRangeBetweenClassName={classes.middleRangeDate}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container direction="column">
                <Typography variant="h6" style={{ marginBottom: 25 }}>
                  2) Valitse nouto- ja palautusaika
                </Typography>
                <Grid container direction="row" alignItems="center">
                  <TimePicker
                    style={{ width: 110 }}
                    autoOk
                    ampm={false}
                    value={startTime}
                    onChange={setStartTime}
                    views={["hours", "minutes"]}
                    inputVariant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Schedule />
                        </InputAdornment>
                      ),
                    }}
                    margin="dense"
                    cancelLabel="Peruuta"
                    disableToolbar
                    error={hasError}
                  />
                  <Grid item style={{ marginLeft: 20 }}>
                    <Typography>Noutoaika</Typography>
                    <Typography variant="caption">{startDateString}</Typography>
                  </Grid>
                </Grid>
                <Slider
                  valueLabelDisplay="off"
                  value={startSliderValue}
                  step={1}
                  max={47}
                  onChange={throttle((_, v) => {
                    const minutes = v * SLIDER_STEP;
                    const time = add(dayStart, { minutes });
                    setStartTime(time);
                  }, 100)}
                />
                <Grid container direction="row" alignItems="center">
                  <TimePicker
                    style={{ width: 110 }}
                    autoOk
                    ampm={false}
                    value={endTime}
                    onChange={setEndTime}
                    views={["hours", "minutes"]}
                    inputVariant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Schedule />
                        </InputAdornment>
                      ),
                    }}
                    margin="dense"
                    cancelLabel="Peruuta"
                    disableToolbar
                    error={hasError}
                  />
                  <Grid item style={{ marginLeft: 20 }}>
                    <Typography>Palautusaika</Typography>
                    <Typography variant="caption">{endDateString}</Typography>
                  </Grid>
                </Grid>
                <Slider
                  valueLabelDisplay="off"
                  value={endSliderValue}
                  step={1}
                  max={47}
                  onChange={throttle((_, v) => {
                    const minutes = v * SLIDER_STEP;
                    const time = add(dayStart, { minutes });
                    setEndTime(time);
                  }, 100)}
                />
                {hasError && <TimeErrorText endTimeOk={endTimeOk} />}
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                color="primary"
                variant="contained"
                className={classes.submit}
                onClick={() => {
                  onChange(
                    set(startTime, {
                      year: dateRange.from.year,
                      month: dateRange.from.month - 1,
                      date: dateRange.from.day,
                    }),
                    set(endTime, {
                      year: dateRange.to
                        ? dateRange.to.year
                        : dateRange.from.year,
                      month: dateRange.to
                        ? dateRange.to.month - 1
                        : dateRange.from.month - 1,
                      date: dateRange.to
                        ? dateRange.to.day
                        : dateRange.from.day,
                    })
                  );
                  setOpen(false);
                }}
                style={{ width: 200 }}
                disabled={!dateRange.from || hasError}
              >
                Valmis
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
