export const getReservationOffers = /* GraphQL */ `
  query GetReservationOffers(
    $companyIds: [ID!]!
    $startTime: AWSDateTime!
    $returnTime: AWSDateTime!
    $timeFlexibility: Int!
  ) {
    getReservationOffers(
      companyIds: $companyIds
      startTime: $startTime
      returnTime: $returnTime
      timeFlexibility: $timeFlexibility
    ) {
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        createdAt
        updatedAt
        includedDistance
        additionalFields {
          key
          value
        }
        category {
          id
          name
        }
        image
        orderNumber
        useStartReturnTimes
        startReturnTimes {
          start
          return
        }
      }
      startTime
      returnTime
      vehicleOfferPrice
      additionalServiceOffers {
        key
        offerPrice
      }
      freeTimes {
        firstStartTime
        lastStartTime
        lastReturnTime
      }
      reservedTimes {
        start
        end
      }
      availableInCategory
      useVehicleTimes
    }
  }
`;
export const getReservationsMonthly = /* GraphQL */ `
  query GetReservationsMonthly($companyId: ID!, $dateInMonth: AWSDateTime!) {
    getReservationsMonthly(companyId: $companyId, dateInMonth: $dateInMonth) {
      vehicle {
        id
        group
        companyId
        name
        registrationPlate
        createdAt
        updatedAt
        includedDistance
        additionalFields {
          key
          value
        }
        category {
          id
          name
        }
        image
        orderNumber
        useStartReturnTimes
        startReturnTimes {
          start
          return
        }
      }
      reservedTimes {
        start
        end
      }
      useVehicleTimes
    }
  }
`;

export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        phone
        businessId
        termsUrl
        timezone
        weeklyBusinessHours {
          openAt
          closeAt
        }
        createdAt
        updatedAt
        businessHoursExceptions {
          items {
            date
            businessHours {
              openAt
              closeAt
            }
          }
          nextToken
        }
        vehicles {
          nextToken
        }
        disableOffHourReturn
        disableMultiday
        disableSameDay
        enableCart
        enableMonthlyView
        enableMustSign
        enablePaymentOnlineOnly
        enableVehicleStartReturnTimes
      }
      nextToken
    }
  }
`;

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      termsUrl
      id
      enableMustSign
      enablePaymentOnlineOnly
      enableReservationDeposit
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
      }
    }
  }
`;

export const authPayment = /* GraphQL */ `
  query AuthPayment(
    $reservationId: ID!
    $paymentMethod: PaymentMethodInput
    $origin: String
  ) {
    authPayment(
      reservationId: $reservationId
      paymentMethod: $paymentMethod
      origin: $origin
    ) {
      result
      token
      type
    }
  }
`;

export const getPaymentMethods = /* GraphQL */ `
  query GetPaymentMethods {
    getPaymentMethods {
      name
      selected_value
      group
      min_amount
      max_amount
      img
      img_timestamp
      currency
    }
  }
`;
