import logo from "../uRent_logo.jpg"; // Tell webpack this JS file uses this image

export default function UrentLogo() {
  return (
    <>
      <a
        style={{ fontSize: "13px", textDecoration: "none", color: "black" }}
        href="https://orhi.fi"
        target="_blank"
      >
        <div>
          <img
            style={{
              height: "15px",
              objectFit: "contain",
            }}
            src={logo}
            alt="Powered by uRent"
          />
        </div>
      </a>
    </>
  );
}
