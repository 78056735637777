import React, { useEffect, useMemo, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { graphqlOperation } from "aws-amplify";
import { listCompanys } from "../graphql/client-queries";
import { useEffectAsync, listAll } from "../utilities";
import { enableCustomProviderLabel } from "../features";

const FIXED_GROUPS = window.URENT_APP_GROUPS;

const FEATURE_SELECT_CUSTOM_LABEL = enableCustomProviderLabel();
// Temporarily disabled, see if needs additional changes
const FEATURE_SELECT_ALL_COMPANIES = false;
//const FEATURE_SELECT_ALL_COMPANIES = FIXED_GROUPS?.includes("vuokraapaku")
//? true
//: false;

let cache;

const fetchCompanies = async () => {
  if (!cache) {
    const companys = await listAll(
      graphqlOperation(
        listCompanys,
        FIXED_GROUPS
          ? {
              filter: {
                or: FIXED_GROUPS.map((group) => ({ group: { eq: group } })),
              },
            }
          : undefined
      ),
      "listCompanys"
    );
    cache = companys;
  }
  return cache;
};

export default function ProviderSelector({
  inputClassName,
  initialCompanies,
  initialPageLoad,
  setCompanies,
  showAll,
  setShowAll,
}) {
  const [companyId, setCompanyId] = useState("");
  const [allCompanies, setAllCompanies] = useState([]);
  const [dirty, setDirty] = useState(false);
  useEffectAsync(async () => {
    const allCompanies = await fetchCompanies();
    setAllCompanies(allCompanies);
  }, []);
  const orderedCompanies = useMemo(() => {
    //Only sorts if both of our c1 & c2 orderNumbers are null or numbers (null - null = 0, so the order doesn't change)
    return [...allCompanies].sort((c1, c2) => {
      return (
        (c1.orderNumber === null) - (c2.orderNumber === null) ||
        c1.orderNumber - c2.orderNumber
      );
    });
  }, [allCompanies]);

  useEffect(() => {
    if (!dirty) {
      if (initialCompanies.length && allCompanies.length) {
        if (showAll) {
          setCompanyId("all");
        } else {
          setCompanyId(
            allCompanies.find((c) => c.id === initialCompanies[0].id)?.id || ""
          );
          if (initialPageLoad) {
            setCompanies(
              allCompanies.filter((c) => c.id === initialCompanies[0].id)
            );
          }
        }
      }
      return;
    }
    if (FEATURE_SELECT_ALL_COMPANIES && companyId === "all") {
      setShowAll(true);
      // TODO: test
      setCompanies(allCompanies.map((c) => c));
    } else {
      setCompanies(allCompanies.filter((c) => c.id === companyId));
    }
  }, [allCompanies, companyId, setCompanies, dirty]);

  const chooseCompanyLabel = FEATURE_SELECT_CUSTOM_LABEL
    ? "Valitse tuote"
    : "Valitse toimipiste";

  return (
    <FormControl variant="outlined" className={inputClassName} fullWidth>
      <Select
        placeholder=""
        value={companyId}
        onChange={(e) => {
          setCompanyId(e.target.value);
          setDirty(true);
        }}
        displayEmpty
        variant="outlined"
      >
        <MenuItem value={""} disabled>
          {chooseCompanyLabel}
        </MenuItem>
        {orderedCompanies.map((c, idx) => (
          <MenuItem value={c.id} key={idx}>
            {c.displayName}
          </MenuItem>
        ))}
        {FEATURE_SELECT_ALL_COMPANIES && (
          <MenuItem value="all">Kaikki toimipisteet</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
