import {
  Grid,
  Chip,
  Avatar,
  makeStyles,
  Container,
  Paper,
  Typography,
  Link,
} from "@material-ui/core";
import { useState, useRef, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DateRange from "../components/DateRange";
import ResultCard, { ResultSkeleton } from "../components/ResultCard";
import { FormattedDate, FormattedTime } from "../components/common";
import { API, graphqlOperation } from "aws-amplify";
import { useEffectAsync, captureError } from "../utilities";
import { getReservationOffers } from "../graphql/client-queries";
import ProviderSelector from "../components/ProviderSelector";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { isSameDay } from "date-fns";

const timeFlexibility = 48 * 60 * 60 * 1000; //48h
const fetchGetReservationOffers = async (companyIds, startTime, returnTime) => {
  const response = await API.graphql(
    graphqlOperation(getReservationOffers, {
      companyIds,
      startTime: startTime.toJSON(),
      returnTime: returnTime.toJSON(),
      timeFlexibility,
    })
  );

  const result = response.data.getReservationOffers.map((r) => ({
    ...r,
    startTime: new Date(r.startTime),
    returnTime: new Date(r.returnTime),
    freeTimes: r.freeTimes.map((f) => ({
      firstStartTime: new Date(f.firstStartTime),
      lastStartTime: new Date(f.lastStartTime),
      lastReturnTime: new Date(f.lastReturnTime),
    })),
  }));

  return result;
};

const getVolume = (reservation) =>
  reservation.vehicle.additionalFields.find((field) => field.key === "volume")
    ?.value || -1;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
  },
  scrollContainer: {
    overflowY: "auto",
    flex: 1,
    paddingBottom: "10vh",
  },
}));

const NoWrap = (props) => {
  return <span style={{ whiteSpace: "nowrap" }} {...props} />;
};

export default function Results() {
  const location = useLocation();
  const history = useHistory();
  const historyState = location.state ?? {};

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [companies, setCompanies] = useState(historyState.companies);
  const container = useRef();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    container.current.scrollIntoView();
  }, []);

  const [startTime, setStartTime] = useState(historyState.startTime);
  const [returnTime, setReturnTime] = useState(historyState.returnTime);
  const [showAll, setShowAll] = useState(historyState.showAll);
  const [cart, setCart] = useState(historyState.cart ?? []);
  const [enableCart, setEnableCart] = useState(false);
  const [enableMonthlyView, setEnableMonthlyView] = useState(false);
  const [disableOffHourReturn, setDisableOffHourReturn] = useState(false);
  const [dateHasError, setDateHasError] = useState(false);
  const [results, setResults] = useState();
  const [selectedFilter, setSelectedFilter] = useState(0);

  const getLastDayOfMonth = useMemo(() => {
    const findLastDayOfMonth = new Date(
      startTime.getFullYear(),
      startTime.getMonth() + 1,
      0
    );
    const day = findLastDayOfMonth.getDate();
    return day;
  }, [startTime]);

  function dateChanged(newStartTime, newReturnTime) {
    setStartTime(newStartTime);
    setReturnTime(newReturnTime);
  }

  // Can't use date range if selected Company have disableOffHourReturn
  useEffect(() => {
    if (companies.some((c) => c.disableOffHourReturn)) {
      setDisableOffHourReturn(true);
    } else {
      setDisableOffHourReturn(false);
    }
    if (companies.some((c) => c.disableMultiday)) {
      if (!isSameDay(startTime, returnTime)) {
        // TODO: Figure out how to push history "/" here. Right now crashes if
        // changing from multiday  company with daterange to non-multiday
        setStartTime(null);
        setReturnTime(null);
      }
    }
    if (companies.some((c) => c.enableCart)) {
      setEnableCart(true);
    } else {
      setEnableCart(false);
    }
    if (companies.some((c) => c.enableMonthlyView)) {
      setEnableMonthlyView(true);
    } else {
      setEnableMonthlyView(false);
    }
  }, [companies]);

  useEffectAsync(async () => {
    setLoading(true);
    setCart([]);
    if (!companies.length || !startTime || !returnTime || dateHasError) {
      setResults([]);
    } else {
      try {
        history.push("/results", {
          companies,
          startTime: startTime,
          returnTime: returnTime,
        });
        const results = await fetchGetReservationOffers(
          companies.map((c) => c.id),
          startTime,
          returnTime
        );
        setResults(results);
        setError(false);
      } catch (e) {
        captureError("Get reservation offers failed", "GET_OFFERS_FAILED", e);
        setError(true);
      }
    }
    setLoading(false);
  }, [startTime, returnTime, companies, dateHasError]);

  const getTimeDiff = (resolvedTime) => {
    return Math.abs(resolvedTime.getTime() - startTime.getTime());
  };
  const getOrderNum = (reservation) => {
    return !!reservation.vehicle.orderNumber
      ? reservation.vehicle.orderNumber
      : 999999;
  };

  const FILTERS = [
    {
      label: "Osuvimmat",
      avatar: <ScheduleIcon style={{ borderRadius: "50%" }} />,
      sort: (a, b) => {
        if (getTimeDiff(a.startTime) !== 0 || getTimeDiff(b.startTime) !== 0) {
          return getTimeDiff(a.startTime) - getTimeDiff(b.startTime);
        }
        return getOrderNum(a) - getOrderNum(b);
      },
    },
    {
      label: "Halvimmat",
      avatar: <Avatar>€</Avatar>,
      sort: (a, b) => a.vehicleOfferPrice - b.vehicleOfferPrice,
    },
    {
      label: "Isoimmat",
      avatar: <Avatar>M3</Avatar>,
      sort: (a, b) => getVolume(b) - getVolume(a),
    },
  ];

  const getContent = () => {
    if (loading) {
      return (
        <>
          <Grid item>
            <ResultSkeleton />
          </Grid>
          <Grid item>
            <ResultSkeleton />
          </Grid>
          <Grid item>
            <ResultSkeleton />
          </Grid>
        </>
      );
    }

    if (error) {
      return <EmptyState>Jokin meni vikaan</EmptyState>;
    }

    if (dateHasError) {
      return <EmptyState>Tarkista nouto- ja palautusaika </EmptyState>;
    }

    if (results && !results.length) {
      const minDate = new Date(startTime.getTime() - timeFlexibility);
      const maxDate = new Date(startTime.getTime() + timeFlexibility);
      return (
        <EmptyState>
          Ei vapaita autoja noutoaikoja lähipäivinä{" "}
          <Typography variant="subtitle2" component="span">
            <NoWrap>
              (<FormattedDate date={minDate} /> <FormattedTime date={minDate} />
            </NoWrap>
            {" - "}
            <NoWrap>
              <FormattedDate date={maxDate} /> <FormattedTime date={maxDate} />)
            </NoWrap>
          </Typography>
        </EmptyState>
      );
    }

    return (
      results &&
      results.sort(FILTERS[selectedFilter].sort).map((r) => (
        <Grid item key={r.vehicle.id}>
          <ResultCard
            reservation={r}
            allReservations={results}
            requestedStartTime={startTime}
            cart={cart}
            setCart={setCart}
            disableOffHourReturn={disableOffHourReturn}
            enableCart={enableCart}
            daysInMonth={getLastDayOfMonth}
            enableMonthlyView={enableMonthlyView}
          />
        </Grid>
      ))
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      ref={container}
    >
      <Paper square style={{ padding: 16, zIndex: 1 }} elevation={3}>
        <Container maxWidth="xs">
          <Grid container direction="column" spacing={1}>
            {expanded && (
              <Grid item>
                <ProviderSelector
                  inputClassName={classes.formControl}
                  setCompanies={setCompanies}
                  initialCompanies={companies}
                  showAll={showAll}
                  setShowAll={setShowAll}
                />
              </Grid>
            )}
            <Grid item>
              <DateRange
                startDate={startTime}
                endDate={returnTime}
                onChange={dateChanged}
                showIcon
                companies={companies}
                setDateHasError={setDateHasError}
              />
            </Grid>
            <Grid item container justify="flex-end">
              <Typography variant="caption">
                <Link
                  style={{ cursor: "pointer" }}
                  underline="hover"
                  onClick={() => setExpanded(!expanded)}
                >
                  Näytä kaikki valinnat
                </Link>
              </Typography>
            </Grid>
            <Grid item container direction="row" justify="center" spacing={2}>
              {FILTERS.map((filter, idx) => (
                <Grid item key={idx}>
                  <Chip
                    label={filter.label}
                    color={selectedFilter === idx ? "primary" : undefined}
                    icon={filter.icon}
                    avatar={filter.avatar}
                    onClick={() => setSelectedFilter(idx)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <div className={classes.scrollContainer}>
        <Container maxWidth="sm">
          <Grid
            className={classes.container}
            container
            direction="column"
            spacing={2}
          >
            {getContent()}
          </Grid>
        </Container>
      </div>
    </div>
  );
}

function EmptyState({ children }) {
  return (
    <Grid
      item
      container
      justify="center"
      direction="column"
      alignItems="center"
      style={{ textAlign: "center" }}
    >
      <img
        src="/no-results.jpg"
        alt=""
        style={{ maxWidth: "100%", maxHeight: "60vh" }}
      />

      <Typography variant="h5" style={{ alignSelf: "center" }}>
        {children}
      </Typography>
    </Grid>
  );
}
