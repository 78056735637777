import {
  Container,
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useFormik } from "formik";
import { captureError } from "../utilities";
import { getReservationInfo, authPayment } from "../graphql/queries";
import { setReservationInfo, initVismaSign } from "../graphql/mutations";
import { FormattedDate, FormattedTime } from "../components/common";
import { centsToLocalString } from "../shared/money";
import PaymentMethods from "../components/PaymentMethods";
import ReservationForm from "../components/reservation/ReservationForm";
import ReservationTerms from "../components/reservation/ReservationTerms";
import { disableCreditcheckStatusPage, showPortInstruction } from "../features";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "150%",
    marginBottom: "20px",
  },
  durationContainer: {
    marginBottom: "20px",
    "& p": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
  },
  container: {
    padding: theme.spacing(2),
    minHeight: "400px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      "Sofia Pro",
      "sans-serif",
    ].join(","),
  },
  formPaper: {
    maxWidth: 400,
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    paddingBottom: `${theme.spacing(1)}px`,
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  paragraph: {
    margin: "1em 0px",
  },
}));

const FormikTextField = ({ formik, name, ...props }) => {
  return (
    <TextField
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      name={name}
      variant="outlined"
      error={formik.touched[name] && !!formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      fullWidth
      {...props}
    />
  );
};

function PriceRow({ label, price, variant }) {
  return (
    <Grid container item xs={12} justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant={variant}>{label}</Typography>
      </Grid>
      <Grid item style={{ textAlign: "right" }}>
        <Typography variant={variant} noWrap>
          {centsToLocalString(price)}
          {" €"}
        </Typography>
      </Grid>
    </Grid>
  );
}

function SignInfo({ state }) {
  if (state === "submitting") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          style={{ height: "1em", width: "1em", marginRight: "5px" }}
        />
        <Typography variant="body2" component="p">
          Dokumenttia luodaan, saat pian kutsun sähköpostiisi
        </Typography>
      </div>
    );
  }
  return null;
}

function SignStatus({ id, reservation, setSnackbarMessage, setReservation }) {
  const [state, setState] = useState("idle");
  const title = useMemo(() => {
    if (reservation.mustSign && reservation.paymentStatus === "COMPLETED") {
      return "Muistathan vielä sähköisen allekirjoituksen";
    }
    return "Kiitoksia varauksesta!";
  }, [reservation]);
  const body = useMemo(() => {
    if (reservation.mustSign && reservation.paymentStatus === "COMPLETED") {
      return "Tee vielä sähköinen allekirjoitus varauksen viimeistelemiseksi. Saat varausvahvistuksen sähköpostiisi pian allekirjoituksen jälkeen.";
    }
    return "Muistathan että voit allekirjoittaa halutessasi myös sähköisellä allekirjoituksella!";
  }, [reservation]);

  const doInitVismaSign = async () => {
    try {
      setState("submitting");
      const response = (
        await API.graphql(
          graphqlOperation(initVismaSign, {
            reservationId: id,
          })
        )
      ).data.initVismaSign;
      if (response.success) {
        setReservation((prevReservation) => {
          return { ...prevReservation, signStatus: "PENDING" };
          setState("idle");
        });
      } else {
        if (response.message === "ALREADY_INITIATED") {
          setReservation((prevReservation) => {
            return { ...prevReservation, signStatus: "PENDING" };
          });
          setState("idle");
        } else {
          setSnackbarMessage("Sähköinen allekirjoitus ei onnistunut");
          captureError(
            "Init visma sign",
            "INIT_VISMA_SIGN_FAILED",
            response.message
          );
        }
      }
    } catch (e) {
      captureError("Init visma sign", "INIT_VISMA_SIGN_FAILED", e);
      setState("idle");
      setSnackbarMessage("Sähköinen allekirjoitus ei onnistunut");
    }
  };

  //reservation.signStatus = "COMPLETED";
  //reservation.enableSign = false;
  if (reservation.paymentData.paymentTodo > 0) return null;
  if (!reservation.enableSign) return null;
  if (reservation.signStatus === "COMPLETED") {
    return (
      <Grid item style={{ marginBottom: "10px" }}>
        <Alert severity="success" variant="outlined">
          Sopimus allekirjoitettu
        </Alert>
      </Grid>
    );
  }
  const getSignContent = (reservation) => {
    if (reservation.mustSign || reservation.signStatus === "PENDING") {
      return (
        <Grid item style={{ marginBottom: "10px" }}>
          <Alert severity="info" variant="outlined">
            Odottaa allekirjoitusta. Saat kutsun Visma Sign allekirjoitukseen
            sähköpostilla
          </Alert>
        </Grid>
      );
    }
    return (
      <Grid item>
        <Button
          onClick={() => doInitVismaSign()}
          //color="primary"
          style={{
            textTransform: "none",
            marginTop: "10px",
            background: "#528d36",
            color: "#fff",
          }}
          size="large"
          variant="contained"
          disabled={state === "submitting"}
          fullWidth
        >
          Tee sähköinen allekirjoitus
        </Button>
      </Grid>
    );
  };
  return (
    <Grid container item spacing={2} justify="center">
      <Grid item>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" style={{ textAlign: "center" }}>
          {body}
        </Typography>
      </Grid>
      {getSignContent(reservation)}
      <Grid item>
        <SignInfo state={state} />
      </Grid>
    </Grid>
  );
}
function PaymentStatus({
  paymentFailed,
  reservation,
  showPaymentMethods,
  setShowPaymentMethods,
  paymentMethods,
  setPaymentMethods,
  formik,
  submitting,
  doAuthPayment,
}) {
  //useEffect(() => {
  //console.info("Debug, setting reservation properties");
  //reservation.paymentStatus = "";
  //}, [reservation]);
  const depositPending = useMemo(() => {
    const paymentStatus = reservation.paymentStatus;
    return (
      paymentStatus === "COMPLETED_DEPOSIT" || paymentStatus === "PENDING_FULL"
    );
  }, [reservation.paymentStatus]);

  if (reservation.paymentData.paymentTodo === 0) {
    return (
      <Grid item style={{ marginBottom: "10px" }}>
        <Alert severity="success" variant="outlined">
          Maksu suoritettu
        </Alert>
      </Grid>
    );
  }
  return (
    <>
      {paymentFailed && (
        <Grid item style={{ marginBottom: "10px" }}>
          <Alert severity="warning" variant="outlined">
            Maksu ei onnistunut. Yritä uudelleen varauksen viimeistelemiseksi
          </Alert>
        </Grid>
      )}
      {reservation.paymentData.paymentTodo && !paymentFailed && (
        <Grid item xs={12}>
          <Alert severity="info" variant="outlined">
            Suorita maksu varauksen viimeistelemiseksi
          </Alert>
        </Grid>
      )}
      <Grid container item>
        <PriceRow
          label="Yhteensä"
          price={reservation.paymentData.paymentTotal}
        />
        {reservation.paymentData.deposit && (
          <PriceRow
            label="Varausmaksu"
            price={-reservation.paymentData.deposit}
          />
        )}
        <PriceRow
          label="Maksettavaa"
          price={reservation.paymentData.paymentTodo}
          variant="h6"
        />
      </Grid>
      <Grid container item xs={12}>
        <PaymentMethods
          formik={formik}
          paymentMode="PENDING"
          paymentMethods={paymentMethods}
          setPaymentMethods={setPaymentMethods}
        />
      </Grid>
      <Grid container item justify="flex-end" style={{ marginTop: "5px" }}>
        <Button
          //onClick={() => setShowPaymentMethods(true)}
          //onClick={() => formik.handleSubmit}
          type="submit"
          color="primary"
          size="medium"
          variant="contained"
          disabled={submitting}
        >
          Siirry maksamaan
        </Button>
      </Grid>
      {showPaymentMethods && (
        <Dialog open={true} onClose={() => setShowPaymentMethods(false)}>
          <DialogTitle>Valitse maksutapa (Visma Pay)</DialogTitle>
          <DialogContent>
            <PaymentMethods
              formik={formik}
              paymentMode="PENDING"
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
              confirmationView={true}
              authPaymentMethod={doAuthPayment}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setShowPaymentMethods(false)}
            >
              Peruuta
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

function CustomerInfo({ formik, reservation, askSsn }) {
  const disableFields = useMemo(() => {
    if (reservation.paymentData.paymentTodo === 0) return true;
    if (reservation.signStatus === "COMPLETED") return true;
  }, [reservation]);
  if (reservation.paymentData.paymentTodo === 0) return null;
  if (reservation.signStatus === "COMPLETED") return null;
  return (
    <Grid
      container
      item
      spacing={2}
      xs={12}
      justify="center"
      //alignItems="center"
    >
      <ReservationForm
        formik={formik}
        FEATURE_ENABLE_PAYMENT={true}
        FEATURE_ASK_SSN={askSsn}
      />
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid container item>
        <ReservationTerms
          formik={formik}
          reservation={reservation}
          company={{ termsUrl: reservation.companyTermsUrl }}
          FEATURE_SHOW_PORT_INSTRUCTION={false}
          FEATURE_MUST_SIGN={reservation.mustSign}
        />
      </Grid>
    </Grid>
  );
}

function Status() {
  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const classes = useStyles();
  const location = useLocation();
  let { id } = useParams();

  const doAuthPayment = async (values) => {
    try {
      const paymentMethod = paymentMethods.find((method) => {
        return method.name === values.paymentMethod;
      });
      const response = await API.graphql(
        graphqlOperation(authPayment, {
          reservationId: id,
          paymentMethod: paymentMethod,
          origin: window.location.origin + window.location.pathname,
          originPath: "seuranta",
        })
      );
      return response.data.authPayment;
    } catch (e) {
      console.log(e);
      captureError("Auth payment at status", "AUTH_PAYMENT_FAILED", e);
      setSubmitting(false);
      setSnackbarMessage("Jokin meni vikaan");
    }
  };

  const onSubmit = async (values) => {
    const reservationInfo = {
      id: id,
      name: values.name,
      address: values.address,
      postalCode: values.postalCode,
      city: values.city,
      phone: values.phone,
      email: values.email,
      externalNotes: values.externalNotes,
      differentDriver: values.differentDriver,
      driverName: values.driverName,
      driverAddress: values.driverAddress,
      driverPostalCode: values.driverPostalCode,
      driverCity: values.driverCity,
      driverPhone: values.driverPhone,
      driverEmail: values.driverEmail,
      additionalDriver: values.additionalDriver,
      additionalDriverName: values.additionalDriverName,
      additionalDriverPhone: values.additionalDriverPhone,
      isCompany: values.isCompany,
      companyName: values.companyName,
      companyBusinessId: values.companyBusinessId,
      companyBillingRef: values.companyBillingRef,
      companyBillingAddress: values.companyBillingAddress,
    };
    if (reservation.askSsn) {
      reservationInfo.ssn = values.ssn;
    }

    try {
      setSubmitting(true);
      // Reservation info can't be updated after it is signed.
      if (!(reservation.signStatus === "COMPLETED")) {
        const response = await API.graphql(
          graphqlOperation(setReservationInfo, {
            reservation: reservationInfo,
          })
        );
        setReservation(response.data.setReservationInfo);
      }
      if (reservation.paymentData.paymentTodo > 0) {
        const authPaymentResult = await doAuthPayment(values);
        if (authPaymentResult.result === 0) {
          window.location.replace(
            "https://www.vismapay.com/pbwapi/token/" + authPaymentResult.token
          );
        }
      }
      //setSubmitting(false);
    } catch (e) {
      console.error("Failure at setReservationInfo", e);
      captureError("setReservationInfo", "SET_RESERVATION_INFO", e);
      setSnackbarMessage("Varauksen tallennus epäonnistui");
      setSubmitting(false);
    }
  };

  const FEATURE_ASK_SSN = useMemo(() => {
    return reservation?.askSsn;
  }, [reservation?.askSsn]);
  const FEATURE_SHOW_PORT_INSTRUCTION = useMemo(() => {
    return showPortInstruction(reservation?.companyId);
  }, [reservation?.companyId]);
  const FEATURE_DISABLE_CREDITCHECK = useMemo(() => {
    return disableCreditcheckStatusPage(reservation?.group);
  }, [reservation?.group]);

  const validate = (values) => {
    const errors = {};
    const GENERAL_ERROR_MSG = "Täytä tämä kenttä";

    // If reservation is signed, rest of the information on reservation is not shown
    if (reservation.signStatus === "COMPLETED") {
      if (reservation.paymentData.paymentTodo > 0 && !values.paymentMethod) {
        errors.paymentMethod = "Valitse maksutapa";
      }
      return errors;
    }

    if (!values.name) {
      errors.name = "Täytä tämä kenttä";
    }

    if (!values.address) {
      errors.address = "Täytä tämä kenttä";
    }

    if (!values.postalCode) {
      errors.postalCode = "Täytä tämä kenttä";
    }

    if (!values.city) {
      errors.city = "Täytä tämä kenttä";
    }

    if (!values.phone) {
      errors.phone = "Täytä tämä kenttä";
    }

    if (!values.email) {
      errors.email = "Täytä tämä kenttä";
    }

    if (
      values.email &&
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        values.email
      )
    ) {
      errors.email = "Tarkista sähköposti";
    }

    if (reservation.paymentData.paymentTodo > 0 && !values.paymentMethod) {
      errors.paymentMethod = "Valitse maksutapa";
    }

    if (FEATURE_ASK_SSN && !values.ssn) {
      errors.ssn = "Täytä tämä kenttä";
    }

    if (!values.acceptTerms) {
      errors.acceptTerms = "Hyväksy varausehdot";
    }

    if (FEATURE_SHOW_PORT_INSTRUCTION && !values.acceptPortTerms) {
      errors.acceptPortTerms = "Hyväksy satamaohje";
    }

    if (reservation.mustSign && !values.acceptSignTerms) {
      errors.acceptSignTerms = "Hyväksy allekirjoittamiseen sitoutuminen";
    }

    if (!values.acceptCreditCheck && !FEATURE_DISABLE_CREDITCHECK) {
      errors.acceptCreditCheck = "Hyväksy luottotietojen tarkistus";
    }

    //if (
    //additionalDistanceServiceOffers.filter((s) => values[s.id]).length > 1
    //) {
    //errors.distanceServices = "Valitse korkeintaan yksi";
    //}
    //if (values.exportLicense && !values.exportLicenseDetails) {
    //errors.exportLicenseDetails = GENERAL_ERROR_MSG;
    //}

    if (values.isCompany) {
      if (!values.companyName) {
        errors.companyName = GENERAL_ERROR_MSG;
      }
    }

    if (values.differentDriver) {
      if (!values.driverName) {
        errors.driverName = GENERAL_ERROR_MSG;
      }

      if (!values.driverAddress) {
        errors.driverAddress = GENERAL_ERROR_MSG;
      }

      if (!values.driverPostalCode) {
        errors.driverPostalCode = GENERAL_ERROR_MSG;
      }

      if (!values.driverCity) {
        errors.driverCity = GENERAL_ERROR_MSG;
      }

      if (!values.driverPhone) {
        errors.driverPhone = GENERAL_ERROR_MSG;
      }

      if (!values.driverEmail) {
        errors.driverEmail = GENERAL_ERROR_MSG;
      }

      if (values.additionalDriver) {
        if (!values.additionalDriverName) {
          errors.additionalDriverName = GENERAL_ERROR_MSG;
        }

        if (!values.additionalDriverPhone) {
          errors.additionalDriverPhone = GENERAL_ERROR_MSG;
        }
      }
    }
    return errors;
  };

  const INITIAL_FORM_DATA = {
    name: "",
    address: "",
    postalCode: "",
    city: "",
    phone: "",
    email: "",
    ssn: "",
    externalNotes: "",
    acceptTerms: false,
    acceptPortTerms: false,
    acceptSignTerms: false,
    acceptCreditCheck: false,
    differentDriver: false,
    driverName: "",
    driverAddress: "",
    driverPostalCode: "",
    driverCity: "",
    driverPhone: "",
    driverEmail: "",
    additionalDriver: "",
    additionalDriverName: "",
    additionalDriverPhone: "",
    isCompany: false,
    companyName: "",
    companyBusinessId: "",
    companyBillingRef: "",
    companyBillingAddress: "",
  };

  const formik = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validate,
    onSubmit,
  });

  const fetchReservation = async () => {
    setLoading(true);
    try {
      const response = await API.graphql(
        graphqlOperation(getReservationInfo, {
          reservationId: id,
        })
      );
      const reservation = response.data.getReservationInfo;
      setReservation(response.data.getReservationInfo);
      formik.setValues({
        ...INITIAL_FORM_DATA,
        ...response.data.getReservationInfo,
      });
      if (!response.data.getReservationInfo.ssn) {
        formik.setFieldValue("ssn", "");
      }
      setLoading(false);
    } catch (e) {
      console.error("Failed to get info", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReservation();
    const query = new URLSearchParams(location.search);
    if (query.get("payment_fail")) {
      setPaymentFailed(true);
    }
  }, []);

  if (loading) {
    return (
      <div>
        <Typography className={classes.header} variant="h2" gutterBottom>
          Varauksen tietoja haetaan
        </Typography>
        <CircularProgress style={{ height: "2em", width: "2em" }} />
      </div>
    );
  }
  if (reservation === null) {
    return (
      <div>
        <Typography className={classes.header} variant="h2" gutterBottom>
          Hupsista
        </Typography>
        <Typography component="body2">
          Jokin meni pieleen, eikä varauksen seuraaminen onnistu
        </Typography>
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid
        container
        item
        spacing={2}
        xs={12}
        style={{ width: "100%", maxWidth: "600px" }}
        justify="center"
      >
        <Grid item>
          <Typography className={classes.header} component="div" gutterBottom>
            Varauksen seuranta
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="center"
          className={classes.durationContainer}
        >
          <Grid item style={{ textAlign: "left" }}>
            <Typography>
              Noutoaika
              <br />
              <FormattedDate date={new Date(reservation.startTime)} />
              <br />
              <FormattedTime date={new Date(reservation.startTime)} />
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ alignSelf: "center", margin: 16 }}>
            <Divider />
          </Grid>
          <Grid item style={{ textAlign: "right" }}>
            <Typography>
              Palautusaika
              <br />
              <FormattedDate date={new Date(reservation.returnTime)} />
              <br />
              <FormattedTime date={new Date(reservation.returnTime)} />
            </Typography>
          </Grid>
        </Grid>
        <CustomerInfo
          formik={formik}
          reservation={reservation}
          askSsn={reservation.askSsn}
        />
        <PaymentStatus
          paymentFailed={paymentFailed}
          reservation={reservation}
          showPaymentMethods={showPaymentMethods}
          setShowPaymentMethods={setShowPaymentMethods}
          paymentMethods={paymentMethods}
          setPaymentMethods={setPaymentMethods}
          formik={formik}
          doAuthPayment={doAuthPayment}
          submitting={submitting}
        />
        <SignStatus
          id={id}
          reservation={reservation}
          setSnackbarMessage={setSnackbarMessage}
          setReservation={setReservation}
        />
      </Grid>
      <Snackbar
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
        autoHideDuration={6000}
        message={snackbarMessage}
      />
    </form>
  );
}

export default function StatusContainer() {
  const classes = useStyles();
  return (
    <Container>
      <Grid
        className={classes.container}
        container
        direction="column"
        alignItems="center"
        //justify="center"
      >
        <Status />
      </Grid>
    </Container>
  );
}
