import { useEffect, useMemo, useState, useRef } from "react";
import ProductSummaryTable from "./ProductSummaryTable";
import { useIsOverflow } from "./useIsOverflow";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
export default function ProductsSummary({ results, requestedStartTime }) {
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  // MaxHeight for the table is defined here, useIsOverflow hook has its own value maxHeight
  // If maxHeight is changed, remember to change the hooks value too
  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
        maxHeight: 500,
        padding: 15,
      }}
    >
      <ProductSummaryTable
        requestedStartTime={requestedStartTime}
        results={results}
      />
      <div
        style={{
          display: "flex",
          position: "absolute",
          bottom: 10,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isOverflow && (
          <div
            style={{
              display: "flex",
              width: 200,
              height: 55,

              justifyContent: "center",
            }}
          >
            <ArrowDropDownIcon
              style={{
                fontSize: 100,
                transition: "1s ease-in-out",

                zIndex: 10,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
