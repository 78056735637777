import { Grid, Slider, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    riskSlider: {
      "& .MuiSlider-markLabel": {
        bottom: "38px",
        top: "unset",
      },
      marginTop: "38px",
      marginBottom: theme.spacing(1),
      //Make room for labels
      marginLeft: "69px",
      marginRight: "71px",
      //width: 100% margin fix
      width: "unset",
      display: "block",
    },
    riskLabel: {
      cursor: "pointer",
      color: "white",
      backgroundColor: "#707070",
      textAlign: "center",
      padding: theme.spacing(1) / 2 + "px " + theme.spacing(1) + "px",
      width: "140px",
      borderRadius: "4px",
      position: "relative",
      "&::after": {
        content: "' '",
        position: "absolute",
        top: "-5px",
        left: "calc(50% - 5px)",
        width: "10px",
        height: "10px",
        backgroundColor: "inherit",
        transform: "rotate(45deg)",
      },
    },
  };
});

export default function RiskSlider({
  selected,
  vehicleQuantity,
  onChange,
  values,
}) {
  const classes = useStyles();

  function RiskLabel({ children, onClick }) {
    return (
      <Grid item className={classes.riskLabel} onClick={onClick}>
        <Typography variant="caption">{children}</Typography>
      </Grid>
    );
  }

  const marks = values.map((v) => ({ ...v, value: v.id }));
  const maxValue = Math.max(...marks.map((m) => m.id));

  return (
    <>
      <Slider
        className={classes.riskSlider}
        color="primary"
        step={null}
        marks={marks}
        max={maxValue}
        valueLabelDisplay="on"
        valueLabelFormat={(value) =>
          `${(marks[value].offerPrice * vehicleQuantity) / 100} €`
        }
        ThumbComponent="div"
        value={selected.id}
        onChange={(e, value) => onChange(marks[value])}
      />
      <Grid container justify="space-between" wrap="nowrap" alignItems="center">
        {marks.map((m) => (
          <RiskLabel key={m.key} onClick={() => onChange(m)}>
            {m.description}
          </RiskLabel>
        ))}
      </Grid>
    </>
  );
}
