import {
  Grid,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  Link,
} from "@material-ui/core";
import { useMemo } from "react";
import { disableCreditcheckStatusPage } from "../../features";

export default function ReservationTerms({
  formik,
  reservation,
  company,
  FEATURE_SHOW_PORT_INSTRUCTION,
  FEATURE_MUST_SIGN,
}) {
  const FEATURE_DISABLE_CREDITCHECK = useMemo(() => {
    return disableCreditcheckStatusPage(reservation.group);
  }, [reservation.group]);

  const FEATURE_CREDITCHECK_LABEL = useMemo(() => {
    if (reservation.group === "nestesorsasalo") {
      return "Luottotietoni saa tarkistaa";
    }
    if (reservation.group === "pakunvuokraus") {
      return "Luottotietoni saa tarkistaa. Mikäli luottotietoni eivät ole kunnossa, olen valmis maksamaan 500 € vakuusmaksun joka palautetaan vuokrauksen jälkeen.";
    }
    if (reservation.group === "prgroup") {
      return "Luottotietoni saa tarkistaa. Mikäli luottotietoni eivät ole kunnossa, olen valmis maksamaan 1200 € vakuusmaksun joka palautetaan vuokrauksen jälkeen.";
    }
    return "Luottotietoni saa tarkistaa. Mikäli luottotietoni eivät ole kunnossa, olen valmis maksamaan 600 € vakuusmaksun joka palautetaan vuokrauksen jälkeen.";
  }, [reservation]);
  return (
    <>
      <Grid item>
        <FormControl
          required
          error={formik.touched.acceptTerms && !!formik.errors.acceptTerms}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="acceptTerms"
                color="primary"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
            }
            label={
              company?.termsUrl ? (
                <>
                  Hyväksyn{" "}
                  <Link href={company?.termsUrl} target="_blank" rel="noopener">
                    varausehdot
                  </Link>
                </>
              ) : (
                <>Hyväksyn varausehdot</>
              )
            }
          />
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <FormHelperText>{formik.errors.acceptTerms}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      {FEATURE_SHOW_PORT_INSTRUCTION && (
        <Grid item>
          <FormControl
            required
            error={
              formik.touched.acceptPortTerms && !!formik.errors.acceptPortTerms
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptPortTerms"
                  color="primary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              }
              label={
                <>
                  Hyväksyn{" "}
                  <Link
                    href="https://www.rsteam.fi/vuokraamosta-lahto-paluu-ohjeistus/"
                    target="_blank"
                    rel="noopener"
                  >
                    satamaohjeen
                  </Link>
                </>
              }
            />
            {formik.touched.acceptPortTerms &&
              formik.errors.acceptPortTerms && (
                <FormHelperText>{formik.errors.acceptPortTerms}</FormHelperText>
              )}
          </FormControl>
        </Grid>
      )}
      {FEATURE_MUST_SIGN && (
        <Grid item>
          <FormControl
            required
            error={
              formik.touched.acceptSignTerms && !!formik.errors.acceptSignTerms
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptSignTerms"
                  color="primary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              }
              label={
                <>
                  Suoritan sähköisen allekirjoittamisen maksun jälkeen, jotta
                  varaus hyväksytään.
                </>
              }
            />
            {formik.touched.acceptSignTerms &&
              formik.errors.acceptSignTerms && (
                <FormHelperText>{formik.errors.acceptSignTerms}</FormHelperText>
              )}
          </FormControl>
        </Grid>
      )}
      {!FEATURE_DISABLE_CREDITCHECK && (
        <Grid item>
          <FormControl
            required
            error={
              formik.touched.acceptCreditCheck &&
              !!formik.errors.acceptCreditCheck
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptCreditCheck"
                  color="primary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              }
              style={{ textAlign: "initial" }}
              label={FEATURE_CREDITCHECK_LABEL}
            />
            {formik.touched.acceptCreditCheck &&
              formik.errors.acceptCreditCheck && (
                <FormHelperText>
                  {formik.errors.acceptCreditCheck}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
      )}
    </>
  );
}
