import { useEffect, useState, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { getReservationsMonthly } from "../graphql/client-queries";
import { useEffectAsync, captureError } from "../utilities";
import ProductsSummary from "../components/ProductsSummary";
import DateRange from "../components/DateRange";
import ProviderSelector from "../components/ProviderSelector";

import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Collapse,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { format, addMonths, isSameDay } from "date-fns";
import { fi } from "date-fns/locale";

import UrentLogo from "../components/UrentLogo";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

const INITIAL_COMPANY_ID = window.URENT_APP_INITIAL_COMPANY;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    marginTop: 0,
  },
  formPaper: {
    maxWidth: 400,
    width: "90%",
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
  },
  formControl: {
    "& .MuiInputBase-root": {
      background: "#fff",
    },
    marginBottom: theme.spacing(1),
  },
  poweredByContainer: {
    marginTop: theme.spacing(1),
  },
}));

export default function Search() {
  const classes = useStyles();
  const container = useRef();
  const history = useHistory();
  const [calendarCompany, setCalendarCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [returnTime, setReturnTime] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  // Feature is controlled by company group
  const FEATURE_PRODUCTS_CALENDAR = useMemo(() => {
    return companies[0]?.enableMonthlyView;
  }, [companies]);
  // When companyId changes, its possible to hide the feature from spesific groups
  useEffect(() => {
    if (companies.length && FEATURE_PRODUCTS_CALENDAR) {
      return setCalendarCompany(companies[0].id);
    }
    return setCalendarCompany();
  }, [companies, FEATURE_PRODUCTS_CALENDAR]);

  const [calendarDate, setCalendarDate] = useState(new Date());

  const capitalize = (str) => {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  };
  const currentMonthString = capitalize(
    format(calendarDate, "LLLL", { locale: fi })
  );

  function dateChanged(newStartTime, newReturnTime) {
    setStartTime(newStartTime);
    setReturnTime(newReturnTime);
  }

  const changeMonth = (value) => {
    setCalendarDate(addMonths(calendarDate, value));
  };

  const fetchReservationsMonthly = async (date) => {
    const response = await API.graphql(
      graphqlOperation(getReservationsMonthly, {
        companyId: calendarCompany,
        dateInMonth: date.toJSON(),
      })
    );
    return response.data.getReservationsMonthly;
  };

  useEffectAsync(async () => {
    setLoading(true);
    if (calendarCompany) {
      try {
        const results = await fetchReservationsMonthly(calendarDate);

        setResults(results);
      } catch (e) {
        captureError("Get reservations montlhy failed", "GET_MONTHLY_FAIL", e);
      }

      setLoading(false);
    }
  }, [calendarDate, calendarCompany]);
  // Can't use date range if selected Company have disableOffHourReturn
  useEffect(() => {
    if (companies.some((c) => c.disableOffHourReturn)) {
      if (!isSameDay(startTime, returnTime)) {
        setStartTime(null);
        setReturnTime(null);
      }
    }
  }, [companies]);

  return (
    <Container ref={container}>
      <Grid
        container
        direction="column"
        spacing={2}
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Paper className={classes.formPaper}>
          <Grid container direction="column" spacing={2} alignItems="stretch">
            <Grid item xs={12}>
              <ProviderSelector
                inputClassName={classes.formControl}
                setCompanies={setCompanies}
                setShowAll={setShowAll}
                initialPageLoad={true}
                initialCompanies={[{ id: INITIAL_COMPANY_ID }] ?? []}
              />
            </Grid>
            <Grid item xs={12}>
              <DateRange
                startDate={startTime}
                endDate={returnTime}
                onChange={dateChanged}
                inputClassName={classes.formControl}
                companies={companies}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  if (!companies.length || !startTime) {
                    return;
                  }

                  history.push("/results", {
                    companies,
                    startTime: startTime,
                    returnTime: returnTime,
                    showAll: showAll,
                  });
                }}
                color="primary"
                size="large"
                variant="contained"
                fullWidth
              >
                Hae
              </Button>
            </Grid>
            <Grid
              item
              container
              className={classes.poweredByContainer}
              justify="flex-end"
            >
              <UrentLogo />
            </Grid>
          </Grid>
        </Paper>

        {FEATURE_PRODUCTS_CALENDAR && (
          <Container maxWidth="lg">
            <Paper>
              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="center"
                style={{ position: "relative" }}
              >
                {!infoOpen && (
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      bottom: 5,
                      color: "limegreen",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="close"
                      size="small"
                      onClick={() => {
                        setInfoOpen(true);
                      }}
                    >
                      <InfoIcon
                        fontSize="large"
                        style={{ color: "dodgerblue" }}
                      />
                    </IconButton>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                    height: 100,
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <IconButton size="small" onClick={() => changeMonth(-1)}>
                      <ChevronLeftIcon
                        style={{ fontSize: 40, color: "black" }}
                      />
                    </IconButton>

                    <Typography variant="h4">{currentMonthString}</Typography>
                    <IconButton size="small" onClick={() => changeMonth(1)}>
                      <ChevronRightIcon
                        style={{ fontSize: 40, color: "black" }}
                      />
                    </IconButton>
                  </div>
                </div>
                <Collapse in={infoOpen}>
                  <Alert
                    style={{
                      display: "flex",
                      width: 600,
                      maxWidth: "80vw",
                    }}
                    severity="info"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setInfoOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 35,
                          height: 20,
                          backgroundColor: "green",
                          borderRadius: 7,
                          marginRight: 10,
                        }}
                      ></div>
                      <Typography variant="body2"> = Vapaa</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 35,
                          height: 20,
                          backgroundColor: "darkred",
                          borderRadius: 7,
                          marginRight: 10,
                          marginTop: 5,
                        }}
                      ></div>
                      <Typography variant="body2"> = Varattu</Typography>
                    </div>
                  </Alert>
                </Collapse>
              </Grid>
              {!loading && results.length > 0 && (
                <Grid
                  container
                  style={{ maxWidth: "95vw", overflow: "hidden" }}
                >
                  <ProductsSummary
                    results={results}
                    requestedStartTime={calendarDate}
                  />
                </Grid>
              )}
              {loading && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ marginTop: 50, marginBottom: 50 }}
                >
                  <LinearProgress
                    style={{
                      heigth: 150,
                      width: 150,
                      marginTop: 50,
                      marginBottom: 50,
                    }}
                  />
                </Grid>
              )}
            </Paper>
          </Container>
        )}
      </Grid>
    </Container>
  );
}
