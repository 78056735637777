import Amplify from "aws-amplify";
import { Route, Switch, MemoryRouter, HashRouter } from "react-router-dom";
import Search from "./views/Search";
import Results from "./views/Results";
import Reservation from "./views/Reservation";
import Confirmation from "./views/Confirmation";
import Status from "./views/Status";

{
  //Init Amplify from env variables (these fields are typically in aws-exports.js which is not available in this project)
  const awsconfig = {};
  const envPrefix = "REACT_APP_";
  Object.entries(process.env).forEach(([key, value]) => {
    if (key.startsWith("REACT_APP_aws_")) {
      const awsKey = key.substring(envPrefix.length);
      awsconfig[awsKey] = value;
    } else if (key === "REACT_APP_oauth") {
      awsconfig["oauth"] = JSON.parse(value);
    }
  });

  Amplify.configure(awsconfig);
}

function AppRoutes() {
  return (
    <MemoryRouter>
      <Switch>
        <Route path="/reservation">
          <Reservation />
        </Route>
        <Route path="/results">
          <Results />
        </Route>
        <Route path="/confirmation">
          <Confirmation />
        </Route>
        <Route path="/">
          <Search />
        </Route>
      </Switch>
    </MemoryRouter>
  );
}

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/vahvistus">
          <Confirmation />
        </Route>
        <Route exact path="/seuranta/:id">
          <Status />
        </Route>

        <Route>
          <AppRoutes />
        </Route>
      </Switch>
    </HashRouter>
  );
}

export default App;
