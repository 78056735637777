import {
  Grid,
  makeStyles,
  Paper,
  Radio,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import { getPaymentMethods } from "../getPaymentMethods";

// Also used at Reservation
const PaymentModeEnum = Object.freeze({
  now: "PENDING",
  now_deposit: "PENDING_DEPOSIT",
  onPickup: "",
});

const useStyles = makeStyles((theme) => ({
  paymentMethodContainer: {},
  paymentMethod: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 105,
    cursor: "pointer",
    "& img": {
      maxWidth: 105,
      [theme.breakpoints.down("xs")]: {
        maxWidth: 80,
      },
    },
    "&:hover": {
      borderColor: "#000000de",
    },
  },
}));

// Either separate authPaymentMethod or formik can be used to init the authPayment
const PaymentMethod = ({
  method,
  classes,
  formik,
  authPaymentMethod,
  confirmationView,
}) => {
  if (confirmationView) {
    return (
      <Grid item>
        <Paper
          variant="outlined"
          className={classes.paymentMethod}
          onClick={() => {
            if (authPaymentMethod) {
              authPaymentMethod(method.name);
            } else {
              formik.setFieldValue("paymentMethod", method.name);
              formik.submitForm();
            }
          }}
        >
          <img src={method.img} alt={method.name} />
        </Paper>
      </Grid>
    );
  }
  return (
    <Grid item>
      <Paper
        variant="outlined"
        //justifyContent="center"
        className={classes.paymentMethod}
        onClick={() => formik.setFieldValue("paymentMethod", method.name)}
      >
        <img src={method.img} alt={method.name} />
        <Radio
          size="small"
          checked={formik.values.paymentMethod === method.name}
          onChange={formik.handleChange}
          value={method.name}
          name="paymentMethod"
          inputProps={{ "aria-label": method.name }}
        />
      </Paper>
    </Grid>
  );
};

const PaymentMethodGroup = ({
  header,
  classes,
  formik,
  confirmationView,
  paymentMethods,
  groups,
  authPaymentMethod,
}) => {
  return (
    <>
      <Grid item>
        <Typography variant="h6">{header}</Typography>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        spacing={1}
        className={classes.paymentMethodContainer}
      >
        {paymentMethods &&
          paymentMethods
            .filter((method) => {
              return groups.includes(method.group);
            })
            .map((method) => {
              return (
                <PaymentMethod
                  method={method}
                  classes={classes}
                  formik={formik}
                  authPaymentMethod={authPaymentMethod}
                  confirmationView={confirmationView}
                  key={method.name}
                />
              );
            })}
      </Grid>
    </>
  );
};

export default function PaymentMethods({
  formik,
  paymentMode,
  paymentMethods,
  setPaymentMethods,
  confirmationView,
  authPaymentMethod,
}) {
  const classes = useStyles();

  useEffect(() => {
    setPaymentMethods(getPaymentMethods(process.env.REACT_APP_ENV));
  }, []);

  return (
    <>
      {(paymentMode === PaymentModeEnum.now ||
        paymentMode === PaymentModeEnum.now_deposit) && (
        <>
          <PaymentMethodGroup
            header="Pankkimaksut"
            classes={classes}
            formik={formik}
            confirmationView={confirmationView}
            paymentMethods={paymentMethods}
            groups={["banks"]}
            authPaymentMethod={authPaymentMethod}
          />
          <PaymentMethodGroup
            header="Korttimaksut"
            classes={classes}
            formik={formik}
            confirmationView={confirmationView}
            paymentMethods={paymentMethods}
            groups={["creditcards"]}
            authPaymentMethod={authPaymentMethod}
          />
          <PaymentMethodGroup
            header="Mobiilimaksut"
            classes={classes}
            formik={formik}
            confirmationView={confirmationView}
            paymentMethods={paymentMethods}
            groups={["wallets"]}
            authPaymentMethod={authPaymentMethod}
          />
          <PaymentMethodGroup
            header="Laskut"
            classes={classes}
            formik={formik}
            confirmationView={confirmationView}
            paymentMethods={paymentMethods}
            groups={["creditinvoices"]}
            authPaymentMethod={authPaymentMethod}
          />
          {formik?.errors?.paymentMethod && (
            <Grid item xs={12}>
              <FormHelperText error>
                {formik.errors.paymentMethod}
              </FormHelperText>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
