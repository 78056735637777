import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import red from "@material-ui/core/colors/red";
import { useEffect, useRef, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  setReservationPaymentStatus,
  cancelReservation,
  initVismaSign,
} from "../graphql/mutations";
import * as Sentry from "@sentry/react";
import { captureError } from "../utilities";
import { API, graphqlOperation } from "aws-amplify";
import { authPayment } from "../graphql/client-queries";
import { useFormik } from "formik";
import PaymentMethods from "../components/PaymentMethods";
import { enableVismaSign } from "../features";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    marginBottom: 20,
    "& > *": {
      margin: theme.spacing(1),
    },
    "& button": {
      width: "45%",
    },
    "& .singleBtn": {
      width: "100%",
      margin: 0,
    },
  },
  formPaper: {
    maxWidth: 400,
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    paddingBottom: `${theme.spacing(1)}px`,
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  paragraph: {
    margin: "1em 0px",
  },
}));

function P({ children, classes }) {
  return (
    <Typography className={classes.paragraph} variant="body1" component="p">
      {children}
    </Typography>
  );
}
function P2({ children, classes }) {
  return (
    <Typography className={classes.paragraph} variant="body2" component="p">
      {children}
    </Typography>
  );
}

function PaymentFailure({
  reservationId,
  group,
  classes,
  history,
  setSnackbarMessage,
  setEmailConfirmationSuccess,
  setFailedOrderId,
  paymentOnlineOnly,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [payNow, setPayNow] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const INITIAL_FORM_DATA = {
    paymentMethod: "",
    paymentMode: "",
  };
  const validate = (values) => {
    const errors = {};
    return errors;
  };

  const FEATURE_PAYMENT_ONLINE_ONLY = useMemo(() => {
    return paymentOnlineOnly;
  }, [paymentOnlineOnly]);

  const onSubmit = async (values) => {
    try {
      setSubmitting(true);
      setPayNow(false); // Close the dialog
      const paymentMethod = paymentMethods.find((method) => {
        return method.name === values.paymentMethod;
      });
      const response = await API.graphql(
        graphqlOperation(authPayment, {
          reservationId: reservationId,
          paymentMethod: paymentMethod,
          origin: window.location.origin + window.location.pathname,
          originPath: "vahvistus",
        })
      );
      window.location.href =
        "https://www.vismapay.com/pbwapi/token/" +
        response.data.authPayment.token;
    } catch (e) {
      console.log(e);
      captureError("Auth payment again", "AUTH_PAYMENT_FAILED", e);
      setSubmitting(false);
      setSnackbarMessage("Jokin meni vikaan");
    }
  };

  const formik = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validate,
    onSubmit,
  });

  const setPaymentOnPickup = async () => {
    try {
      setSubmitting(true);
      const result = await API.graphql(
        graphqlOperation(setReservationPaymentStatus, {
          reservationId: reservationId,
        })
      );
      if (!result.data.setReservationPaymentStatus.emailConfirmationSuccess) {
        Sentry.captureMessage("Send confirmation email failed", {
          tags: {
            code: "CONFIRMATION_EMAIL_FAILED",
            destinationEmail: result.destinationEmail,
          },
        });
      }
      setEmailConfirmationSuccess(
        result.data.setReservationPaymentStatus.emailConfirmationSuccess
      );
      setFailedOrderId(false);
      setSubmitting(false);
    } catch (e) {
      captureError("Set payment on pickup", "SET_PAYMENT_ON_PICKUP", e);
      setSubmitting(false);
      setSnackbarMessage("Jokin meni vikaan");
    }
  };

  const doCancelReservation = async () => {
    try {
      setSubmitting(true);
      const response = await API.graphql(
        graphqlOperation(cancelReservation, {
          reservationId: reservationId,
        })
      );
      const success = response.data.cancelReservation;
      if (success) {
        history.push("/");
      } else {
        captureError(
          "Canceling reservation success not true",
          "CANCEL_RESERVATION_FAILED",
          ""
        );
        setSubmitting(false);
        setSnackbarMessage("Jokin meni vikaan");
      }
      setSubmitting(false);
    } catch (e) {
      captureError("Canceling reservation", "CANCEL_RESERVATION_FAILED", e);
      setSubmitting(false);
      setSnackbarMessage("Jokin meni vikaan");
    }
  };

  return (
    <Paper className={classes.formPaper}>
      <Typography variant="h4" component="h1">
        Maksu epäonnistui
      </Typography>

      <P classes={classes}>
        Maksaminen epäonnistui eikä varausta voitu vahvistaa.{" "}
      </P>
      <P classes={classes}>
        {FEATURE_PAYMENT_ONLINE_ONLY
          ? "Vahvistaaksei varauksen yritä maksua uudelleen"
          : "Vahvistaaksesi varauksen voit valita maksa noudettaessa, tai yrittää maksua uudelleen"}
      </P>
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => setPayNow(true)}
          color="primary"
          size="medium"
          variant="contained"
          className={FEATURE_PAYMENT_ONLINE_ONLY ? "singleBtn" : ""}
          fullWidth={FEATURE_PAYMENT_ONLINE_ONLY}
          disabled={submitting}
        >
          Yritä maksua uudelleen
        </Button>
        {!FEATURE_PAYMENT_ONLINE_ONLY && (
          <Button
            onClick={() => setPaymentOnPickup()}
            color="primary"
            size="medium"
            variant="contained"
            disabled={submitting || FEATURE_PAYMENT_ONLINE_ONLY}
          >
            Maksa noudettaessa
          </Button>
        )}
      </div>
      <Button
        onClick={() => doCancelReservation()}
        color="primary"
        size="medium"
        variant="contained"
        disabled={submitting}
        fullWidth
      >
        Peruuta varaus
      </Button>
      {payNow && (
        <Dialog open={true} onClose={() => setPayNow(false)}>
          <DialogTitle>Valitse maksutapa (Visma Pay)</DialogTitle>
          <DialogContent>
            <PaymentMethods
              formik={formik}
              paymentMode="PENDING"
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
              confirmationView={true}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setPayNow(false)}>
              Peruuta
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
}

const SuccessBody = ({ mustSign, emailConfirmationSuccess, classes }) => {
  // on mustSign reservation is only succesful after doing the signing
  if (mustSign) return null;
  if (emailConfirmationSuccess === false) {
    return (
      <Alert severity="warning" className={classes.paragraph}>
        <b>Varaus on onnistuneesti suoritettu</b>, mutta varaus vahvistusta ei
        voitu toimittaa sähköpostiin. Ota yhteys vuokraamoon vahvistaaksesi
        varauksen
      </Alert>
    );
  }
  return (
    <P classes={classes}>
      Varaus on suoritettu ja saat pian vahvistuksen sähköpostiisi.
    </P>
  );
};

function Success({
  classes,
  history,
  emailConfirmationSuccess,
  group,
  reservationId,
  setSnackbarMessage,
  mustSign,
}) {
  const [state, setState] = useState("idle");
  const latestStateRef = useRef(state);
  // TODO: Do not write or read ref.current during rendering. This may break in a future release of React
  // https://beta.reactjs.org/apis/useref
  latestStateRef.current = state;

  const FEATURE_ENABLE_VISMA_SIGN = useMemo(() => {
    return enableVismaSign(process.env.REACT_APP_ENV, group);
  }, [group]);

  useEffect(() => {
    if (mustSign) {
      doInitVismaSign();
    }
  }, [mustSign]);

  const doInitVismaSign = async () => {
    if (latestStateRef.current !== "idle") return;
    try {
      setState("submitting");
      const response = (
        await API.graphql(
          graphqlOperation(initVismaSign, {
            reservationId: reservationId,
          })
        )
      ).data.initVismaSign;
      if (response.success) {
        setState("ready");
      } else {
        if (response.message === "ALREADY_INITIATED") {
          setState("ready");
        } else {
          setSnackbarMessage("Sähköinen allekirjoitus ei onnistunut");
          captureError(
            "Init visma sign",
            "INIT_VISMA_SIGN_FAILED",
            response.message
          );
        }
      }
    } catch (e) {
      captureError("Init visma sign", "INIT_VISMA_SIGN_FAILED", e);
      setState("idle");
      setSnackbarMessage("Sähköinen allekirjoitus ei onnistunut");
    }
  };
  if (FEATURE_ENABLE_VISMA_SIGN) {
    return (
      <Paper className={classes.formPaper}>
        <Typography variant="h4" component="h1">
          Kiitos varauksesta
        </Typography>

        <SuccessBody
          emailConfirmationSuccess={emailConfirmationSuccess}
          classes={classes}
          mustSign={mustSign}
        />
        <P classes={classes}>
          {mustSign
            ? "Saat kutsun sähköiseen allekirjoitukseen sähköpostilla. Varaus hyväksytään kun tämä on tehty "
            : "Muistathan että voit allekirjoittaa halutessasi sopimuksen myös sähköisellä allekirjoituksella!"}
        </P>
        <SignInfo
          submitting={state === "submitting"}
          documentReady={state === "ready"}
          classes={classes}
        />
        <Button
          onClick={() => doInitVismaSign()}
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          style={{ marginBottom: "20px", backgroundColor: "green" }}
          disabled={state !== "idle"}
        >
          {state === "ready"
            ? "Kutsu lähetetty"
            : "Tee sähköinen allekirjoitus"}
        </Button>
        <Button
          onClick={() => history.push("/")}
          color="primary"
          style={{ backgroundColor: red[700] }}
          size="large"
          variant="contained"
          fullWidth
          disabled={state === "submitting"}
        >
          {state === "ready" || mustSign
            ? "Palaa alkuun"
            : "Allekirjoita paikan päällä"}
        </Button>
      </Paper>
    );
  }

  return (
    <Paper className={classes.formPaper}>
      <Typography variant="h4" component="h1">
        Kiitos varauksesta
      </Typography>

      <SuccessBody
        emailConfirmationSuccess={emailConfirmationSuccess}
        classes={classes}
      />
      <Button
        onClick={() => history.push("/")}
        color="primary"
        size="large"
        variant="contained"
        fullWidth
      >
        Palaa alkuun
      </Button>
    </Paper>
  );
}

function SignInfo({ submitting, documentReady, classes }) {
  if (submitting) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          style={{ height: "1em", width: "1em", marginRight: "5px" }}
        />
        <P2 classes={classes}>
          Dokumenttia luodaan, saat pian kutsun sähköpostiisi
        </P2>
      </div>
    );
  } else if (documentReady) {
    return (
      <P classes={classes}>
        Kutsu sähköiseen allekirjoitukseen on lähetetty sähköpostiisi
      </P>
    );
  }
  return null;
}

export default function Confirmation() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const container = useRef();
  const [emailConfirmationSuccess, setEmailConfirmationSuccess] =
    useState(false);
  const [failedOrderId, setFailedOrderId] = useState(false);
  const [reservationId, setReservationId] = useState(null);
  const [paymentOnlineOnly, setPaymentOnlineOnly] = useState(false);
  const [group, setGroup] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    container.current.scrollIntoView();
    if (query.get("group")) {
      setGroup(query.get("group"));
    } else if (location.state?.group) {
      setGroup(location.state?.group);
    }
    // If ORDER_NUMBER query param exists, payment not succesful
    if (query.get("ORDER_NUMBER") || location.state?.ORDER_NUMBER) {
      // Set failedOrderId to trigger PaymentFailure, but also reservationId.
      // It is possible to get to Success from PaymentFailure, so we need reservationId.
      const orderNumber =
        query.get("ORDER_NUMBER") || location.state?.ORDER_NUMBER;
      setFailedOrderId(orderNumber);
      setReservationId(orderNumber);
      const paymentOnlineOnly =
        query.get("paymentOnlineOnly") || location.state?.paymentOnlineOnly;
      setPaymentOnlineOnly(paymentOnlineOnly === "true");
    } else {
      // Reservation successful
      // Get emailSuccess from query param, if doesnt exist get from state
      const emailSuccess = query.get("EMAIL");
      if (!!emailSuccess) {
        setEmailConfirmationSuccess(emailSuccess === "true" ? true : false);
      } else {
        setEmailConfirmationSuccess(location.state?.emailConfirmationSuccess);
      }
      // Get reservation id from query param, if doesnt exist get from state
      const reservationId = query.get("reservationId");
      if (!!reservationId) {
        setReservationId(reservationId);
      } else {
        setReservationId(location.state?.reservationId);
      }
    }
  }, []);

  const query = new URLSearchParams(location.search);
  // reservationId must be set before signing can be done
  const mustSign = useMemo(
    () => query.get("mustSign") === "true" && reservationId,
    [query.get("mustSign"), reservationId]
  );

  return (
    <Container ref={container}>
      <Grid
        className={classes.container}
        container
        direction="column"
        alignItems="center"
      >
        {failedOrderId ? (
          <>
            <PaymentFailure
              reservationId={failedOrderId}
              group={group}
              setFailedOrderId={setFailedOrderId}
              history={history}
              classes={classes}
              setSnackbarMessage={setSnackbarMessage}
              setEmailConfirmationSuccess={setEmailConfirmationSuccess}
              paymentOnlineOnly={paymentOnlineOnly}
            />
          </>
        ) : (
          <Success
            history={history}
            classes={classes}
            emailConfirmationSuccess={emailConfirmationSuccess}
            group={group}
            reservationId={reservationId}
            setSnackbarMessage={setSnackbarMessage}
            mustSign={mustSign}
          />
        )}
      </Grid>
      <Snackbar
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
        autoHideDuration={6000}
        message={snackbarMessage}
      />
    </Container>
  );
}
