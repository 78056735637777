exports.centsToLocalString = (cents, locale = "fi-FI") => {
  const whole = cents / 100;
  return whole.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

exports.parseToCents = (str) => {
  function parseWhole(wholeStr) {
    const value = parseInt(wholeStr);
    //Should be impossible
    if (Number.isNaN(value)) {
      throw new Error("Parsing failed for: " + wholeStr);
    }

    return value;
  }

  function parseCents(centsStr) {
    if (centsStr == null || centsStr === "") {
      return 0;
    }

    //#.9 => 90 cents
    if (centsStr.length === 1) {
      centsStr = centsStr + "0";
    }

    const value = parseInt(centsStr);
    //Should be impossible
    if (Number.isNaN(value)) {
      throw new Error("Parsing failed for: " + centsStr);
    }

    return value;
  }

  // Remove whitespaces from Localized string. (eg. 1 500,00), or there may
  // be problems validating price formatting at ReservationDialog
  if (typeof str === "string") {
    str = str.replace(/\s+/g, "");
  }

  //At least one digit and optional decimal part with maxium 2 digits separated by ',' or'.'
  const re = new RegExp("^(?<whole>[0-9]+)([,.](?<cents>[0-9]?[0-9]?))?$");
  const match = re.exec(str);
  if (match) {
    const whole = parseWhole(match.groups.whole);
    const cents = parseCents(match.groups.cents);

    return whole * 100 + cents;
  } else {
    return null;
  }
};
