import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
} from "@material-ui/core";

export default function ReservationForm({
  formik,
  FEATURE_ENABLE_PAYMENT,
  FEATURE_ASK_SSN,
}) {
  return (
    <>
      <Grid item container xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="isCompany"
              checked={formik.values.isCompany ?? false}
              color="primary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          }
          label={<>Yritys</>}
        />
      </Grid>
      {formik.values.isCompany && (
        <>
          <Grid item container spacing={2}>
            {FEATURE_ENABLE_PAYMENT && (
              <>
                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography variant="body2">
                    Mikäli haluat maksaa varauksesi laskulla, valitse
                    alapuolelta maksutavaksi yrityslasku
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={7}>
              <TextField
                variant="outlined"
                fullWidth
                name="companyName"
                required
                label="Yrityksen nimi"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.companyName && !!formik.errors.companyName
                }
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                variant="outlined"
                fullWidth
                name="companyBusinessId"
                label="Y-tunnus"
                value={formik.values.companyBusinessId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.companyBusinessId &&
                  !!formik.errors.companyBusinessId
                }
                helperText={
                  formik.touched.companyBusinessId &&
                  formik.errors.companyBusinessId
                }
                size="small"
              />
            </Grid>
            {!FEATURE_ENABLE_PAYMENT && (
              <>
                <Grid item xs={12} sm={7}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="companyBillingAddress"
                    label="Laskutusosoite"
                    value={formik.values.companyBillingAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyBillingAddress &&
                      !!formik.errors.companyBillingAddress
                    }
                    helperText={
                      formik.touched.companyBillingAddress &&
                      formik.errors.companyBillingAddress
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="companyBillingRef"
                    label="Viitetieto"
                    value={formik.values.companyBillingRef}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyBillingRef &&
                      !!formik.errors.companyBillingRef
                    }
                    helperText={
                      formik.touched.companyBillingRef &&
                      formik.errors.companyBillingRef
                    }
                    size="small"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          name="name"
          required
          label="Nimi"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          autoComplete="name"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          name="address"
          required
          label="Osoite"
          value={formik.values.address}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.address && !!formik.errors.address}
          helperText={formik.touched.address && formik.errors.address}
          autoComplete="address-line1"
        />
      </Grid>
      <Grid item sm={7} xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          name="city"
          required
          label="Kaupunki"
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.city && !!formik.errors.city}
          helperText={formik.touched.city && formik.errors.city}
          autoComplete="address-level2"
        />
      </Grid>
      <Grid item sm={5} xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          name="postalCode"
          required
          label="Postinumero"
          value={formik.values.postalCode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.postalCode && !!formik.errors.postalCode}
          helperText={formik.touched.postalCode && formik.errors.postalCode}
          autoComplete="postal-code"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="Sähköposti"
          name="email"
          required
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          autoComplete="email"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="Puhelin"
          name="phone"
          required
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && !!formik.errors.phone}
          helperText={formik.touched.phone && formik.errors.phone}
          autoComplete="tel"
        />
      </Grid>
      {FEATURE_ASK_SSN && (
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Henkilötunnus"
            name="ssn"
            required
            value={formik.values.ssn}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.ssn && !!formik.errors.ssn}
            helperText={formik.touched.ssn && formik.errors.ssn}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="Terveisiä vuokraamolle"
          name="externalNotes"
          value={formik.values.externalNotes ?? ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          multiline
        />
      </Grid>
      <Grid item container xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="differentDriver"
              checked={formik.values.differentDriver ?? false}
              color="primary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          }
          label={<>Kuljettaja eri kuin vuokraaja</>}
        />
      </Grid>
      {formik.values.differentDriver && (
        <>
          <Grid item xs={12}>
            <TextField
              value={formik.values.driverName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="driverName"
              label="Kuljettajan nimi"
              variant="outlined"
              error={formik.touched.driverName && !!formik.errors.driverName}
              helperText={formik.touched.driverName && formik.errors.driverName}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={formik.values.driverAddress ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="driverAddress"
              label="Kuljettajan osoite"
              variant="outlined"
              error={
                formik.touched.driverAddress && !!formik.errors.driverAddress
              }
              helperText={
                formik.touched.driverAddress && formik.errors.driverAddress
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item sm={7} xs={12}>
            <TextField
              value={formik.values.driverCity ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="driverCity"
              label="Kuljettajan kaupunki"
              variant="outlined"
              error={formik.touched.driverCity && !!formik.errors.driverName}
              helperText={formik.touched.driverCity && formik.errors.driverName}
              fullWidth
              required
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            <TextField
              value={formik.values.driverPostalCode ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="driverPostalCode"
              label="Kuljettajan postinumero"
              variant="outlined"
              error={
                formik.touched.driverPostalCode &&
                !!formik.errors.driverPostalCode
              }
              helperText={
                formik.touched.driverPostalCode &&
                formik.errors.driverPostalCode
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={formik.values.driverPhone ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="driverPhone"
              label="Kuljettajan puhelin"
              variant="outlined"
              error={formik.touched.driverPhone && !!formik.errors.driverPhone}
              helperText={
                formik.touched.driverPhone && formik.errors.driverPhone
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={formik.values.driverEmail ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="driverEmail"
              label="Kuljettajan sähköposti"
              variant="outlined"
              error={formik.touched.driverEmail && !!formik.errors.driverEmail}
              helperText={
                formik.touched.driverEmail && formik.errors.driverEmail
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="additionalDriver"
                  checked={formik.values.additionalDriver ?? false}
                  color="primary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label={<>Lisäkuljettaja</>}
            />
          </Grid>
          {formik.values.additionalDriver && (
            <>
              <Grid item xs={12}>
                <TextField
                  value={formik.values.additionalDriverName ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="additionalDriverName"
                  label="Nimi"
                  variant="outlined"
                  error={
                    formik.touched.additionalDriverName &&
                    !!formik.errors.additionalDriverName
                  }
                  helperText={
                    formik.touched.additionalDriverName &&
                    formik.errors.additionalDriverName
                  }
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={formik.values.additionalDriverPhone ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="additionalDriverPhone"
                  label="Puhelin"
                  variant="outlined"
                  error={
                    formik.touched.additionalDriverPhone &&
                    !!formik.errors.additionalDriverPhone
                  }
                  helperText={
                    formik.touched.additionalDriverPhone &&
                    formik.errors.additionalDriverPhone
                  }
                  fullWidth
                  required
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
